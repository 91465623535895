import React, { useState, useEffect, useRef } from 'react';
import { Button, Popover } from 'antd';
import { invertColor } from '../../../components/HR/TimeClock/Util';

export const ButtonOverflow = ({ buttons, onClickButton }) => {
  const containerRef = useRef(null);
  const [visibleButtons, setVisibleButtons] = useState(buttons);
  const [overflowButtons, setOverflowButtons] = useState([]);

  useEffect(() => {
    const calculateOverflow = () => {
        debugger
      const container = containerRef?.current;
      const parent = container?.closest('.Button-Wrapper');;
      const containerWidth = parent?.offsetWidth;
      let totalWidth = 0;
      let visible = [];
      let overflow = [];

      for (let i = 0; i < buttons.length; i++) {
        // const buttonWidth = container?.children[i]?.offsetWidth || 0;
        totalWidth+=200;

        if (totalWidth <= containerWidth) {
          visible.push(buttons[i]);
        } else {
          overflow.push(buttons[i]);
        }
      }

      setVisibleButtons(visible);
      setOverflowButtons(overflow);
      console.log("v---",visible);
      console.log("iv---",overflow);
      
    };

    calculateOverflow();
    window.addEventListener('resize', calculateOverflow);

    return () => {
      window.removeEventListener('resize', calculateOverflow);
    };
  }, [buttons]);
  const getStyleObj=(ob)=>{
    // let temp=mainStatusOptions?.find(k=>k.label==status);
    let sty={
      backgroundColor:"white",
      color:"black",
    //   border:"1px solid white"
    }
    if(ob && ob.color){
      sty["backgroundColor"]=ob.color;
    //   sty["border"]= `1px solid ${ob.color}`
      sty["color"]= invertColor(ob.color,true)
    }
    return sty;
  }
  const popoverContent = (
    <div>
      {overflowButtons.map((button) => (
        <Button
          key={button.id}
          style={{ marginBottom: '4px', width: '100%',...getStyleObj(button) }}
          onClick={() => onClickButton(button)}
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
 
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap' }} ref={containerRef}>
      {visibleButtons.map((button) => (
        <Button
          key={button.id}
          style={{ marginRight: '8px',...getStyleObj(button)}}
          onClick={() => onClickButton(button)}
        >
          {button.label}
        </Button>
      ))}
      {overflowButtons.length > 0 && (
        <Popover content={popoverContent} trigger="click">
          <Button>+{overflowButtons.length}</Button>
        </Popover>
      )}
    </div>
  );
};