import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'
import './RenderAllTasks.css';
import { Badge, Checkbox, Input, Select, Table, TimePicker, Tooltip } from 'antd';
import { SelectedUserAvatar, UserPicker } from '../../common/UserPicker/UserPicker';
import { Option } from 'antd/es/mentions';
import { CaretDownFilled, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined, EyeOutlined, FlagFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AppDataRequired } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from "sweetalert";
import { getPriorityValue } from '../HR/TimeClock/Util';
import AddInlineData from './AddInlineData';
import AwsServerService from '../../common/util/AwsServerService';
import { faEye, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import minusCircle from "../../assets/images/svg/minus-circle.svg";
import CustomTimeSelect from '../../common/CustomTimeSelect/CustomTimeSelect';
import Vector from "../../assets/images/vector.png";
import {
  faAlignLeft,
  faCheckCircle,
  faCircle,
  faClock,
  faGripVertical, 
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal as AntModal,
  Button as AntButton,
  Select as AntSelect,  
  Button,   
} from "antd";
import { number } from "mathjs";
import _ from 'lodash';

export const TaskGroupCardForTemplate = ({ header, data , allPipelineData  ,viewTaskFunction,editData,setEditData,
     setPageRefreshData,allDependeniesData,dropdownTasksDataCache, setSelectedIdsForTemp, selectedIdsForTemp
    ,allTaskAndSubtask,setDependencyRefresh}) => {
    const [rowData, setRowData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // State for sorting
    let user = JSON.parse(localStorage.getItem("userinfo"));

    useEffect(e => {
        setRowData(data);
    }, [data])
    const toggleExpandIconOfSubtask=(e)=>{
        e.stopPropagation();
        let arr = e.currentTarget.childNodes;
        let taskId= e.currentTarget.id;
        let arrofsubtasks = document.querySelectorAll(`.${taskId}`);
        arr.forEach(element => {
            if(element.classList.contains("SHOW")){
                element.classList.remove("SHOW")
                element.classList.add("HIDE")
                arrofsubtasks.forEach(subtask=>{
                    // subtask.classList.remove("")
                    subtask.classList.add("HIDEROW")
                })
            }else if(element.classList.contains("HIDE")){
                element.classList.remove("HIDE")
                element.classList.add("SHOW")
                arrofsubtasks.forEach(subtask=>{
                    // subtask.classList.remove("")
                    subtask.classList.remove("HIDEROW")
                })
            }
        });
        console.log(arr);
        
    }

    const [dropdowndata, setdropdownTasksData] = useState();
    const [allDepsData, setAllDepsData] = useState([]);
    useEffect(()=>{
        if(dropdownTasksDataCache && dropdownTasksDataCache.length>0){
            let data=new Map(dropdownTasksDataCache.map((item) => [item.value, item]))
            setdropdownTasksData(data);
        }
    },[dropdownTasksDataCache])
    useEffect(()=>{
        if(allDependeniesData && allDependeniesData.length>0){
            setAllDepsData(allDependeniesData);
        }
    },[allDependeniesData])
    
      const updateTaskData = (data, field, val, type) => {
    
        const data2 = {
          ROWID: type !== "subtask" ? data.TaskROWID : data.SubTaskROWID,
          [field]: val || "",
          MODIFIEDBY: user.email_id,
        };
        
        const updateService = type !== "subtask" 
          ? AwsServerService.updateTaskInTemplate 
          : AwsServerService.updateSubtaskInTemplate;
      
        updateService(data2)
          .then((data) => {
            setPageRefreshData(prev => !prev);
          })
          .catch(er => console.log("Er", er));
      };

      const sortData = (field) => {
        let direction = 'ascending';
        if (sortConfig.key === field) {
            if (sortConfig.direction === 'ascending') {
                direction = 'descending';
            } else if (sortConfig.direction === 'descending') {
                direction = 'none';
            }
        }
    
        if (direction === 'none') {
            setRowData(data); // Reset to original data
            setSortConfig({ key: '', direction: '' });
        } else {
            const sortedData = [...rowData].sort((a, b) => {
                if (a[field] < b[field]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[field] > b[field]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setRowData(sortedData);
            setSortConfig({ key: field, direction });
        }
    };
    
    // Render sorting icons based on the current sort state
    const renderSortIcon = (field) => {
        if (sortConfig.key === field) {
            if (sortConfig.direction === 'ascending') {
                return <CaretUpOutlined />;
            } else if (sortConfig.direction === 'descending') {
                return <CaretDownOutlined />;
            }
        }
        return null; // No icon when not sorted
    };

    const getAllDependenciesForTask = (subtask, data) => {
        let sourceTaskType = subtask ? "SubTasks" : "Tasks";
        let ROWID = sourceTaskType == "Tasks" ? data.TaskROWID : data.SubTaskROWID;
        const result = allDepsData?.filter(
          (e) => e.SourceTaskId == ROWID || e.TargetTaskId == ROWID
        );
        // setResultFromGetDependency(result);
        if (result && result.length > 0) {
          // let dropdowndata = [...dropdownTasksDataCache];
          let obj = {
            WaitingOn: new Set(),
            Blocking: new Set(),
          };
          const dropdownMap = dropdowndata;
    
          allPipelineData?.forEach((pipeline) => {
            pipeline.Tasks?.forEach((task) => {
              const tDataValidate = result.find(
                (e) =>
                  e.TargetTaskId == ROWID &&
                  e.SourceTaskId == task.TaskROWID &&
                  e.STaskType == "Tasks"
              );
              let tData = undefined;
              if (tDataValidate) {
                tData = tDataValidate;
              } else {
                tData = result.find(
                  (e) => e.TargetTaskId == task.TaskROWID && e.TaskType == "Tasks"
                );
              }
              let flag = true;
              if (tData?.SourceTaskId == ROWID) {
                flag = tData.STaskType == sourceTaskType;
              }
              // const tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
              if (tData && flag) {
                const type =
                  tData.TargetTaskId == ROWID && tData.DependancyType == "WaitingOn"
                    ? "Blocking"
                    : tData.TargetTaskId == ROWID &&
                      tData.DependancyType == "Blocking"
                    ? "WaitingOn"
                    : tData.DependancyType;
                if (tData.TargetTaskId == ROWID) {
                  const tmp = dropdownMap.get(
                    `${tData.STaskType}_${tData.SourceTaskId}`
                  );
                  if (tmp && sourceTaskType == tData.TaskType) {
                    obj[type].add(JSON.stringify({ ...tmp }));
                  }
                } else {
                  obj[type].add(
                    JSON.stringify({
                      label: task.TaskName,
                      value: `Tasks_${task.TaskROWID}`,
                    })
                  );
                }
              }
              task.SubTasks?.forEach((subtask) => {
                const tDataValidate = result.find(
                  (e) =>
                    e.TargetTaskId == ROWID &&
                    e.SourceTaskId == subtask.SubTaskROWID &&
                    e.STaskType == "SubTasks"
                );
                let tData2 = undefined;
                if (tDataValidate) {
                  tData2 = tDataValidate;
                } else {
                  tData2 = result.find(
                    (e) =>
                      e.TargetTaskId == subtask.SubTaskROWID &&
                      e.TaskType == "SubTasks"
                  );
                }
                //   const tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
                if (tData2) {
                  const type2 =
                    tData2.TargetTaskId == ROWID &&
                    tData2.DependancyType == "WaitingOn"
                      ? "Blocking"
                      : tData2.TargetTaskId == ROWID &&
                        tData2.DependancyType == "Blocking"
                      ? "WaitingOn"
                      : tData2.DependancyType;
                  if (tData2.TargetTaskId == ROWID) {
                    const tmp2 = dropdownMap.get(
                      `${tData2.STaskType}_${tData2.SourceTaskId}`
                    );
                    if (tmp2 && sourceTaskType == tData2.TaskType) {
                      obj[type2].add(JSON.stringify({ ...tmp2 }));
                    }
                  } else {
                    obj[type2].add(
                      JSON.stringify({
                        label: subtask.TaskName,
                        value: `SubTasks_${subtask.SubTaskROWID}`,
                      })
                    );
                  }
                }
              });
            });
          });
          let obj2 = {
            WaitingOn: Array.from(obj.WaitingOn).map((item) => JSON.parse(item)),
            Blocking: Array.from(obj.Blocking).map((item) => JSON.parse(item)),
          };
          return obj2;
        } else {
          return { WaitingOn: [], Blocking: [] };
        }
      };
      const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]}) 
      const [dropdowncount,setDropdownCount]=useState({WaitingOn:0, Blocking:0}) 
      const [isDependencyModalOpen,setIsDependencyModalOpen]=useState(false)  
      const viewTaskData=useRef({});
      const [selectedDependencies,setSelectedDependencies]=useState({
        WaitingOn:[],
        Blocking:[]
      });
      useEffect(()=>{ 
        if(selectedDependenciesCache.WaitingOn.length>0 || selectedDependenciesCache.Blocking.length>0){
          let data={...selectedDependenciesCache}
          // setIsDependencyModalOpen(true);
          setSelectedDependencies(data); 
        }
      },[selectedDependenciesCache])  
        function getOptionsForDropdown(data,selected,viewTaskData){
          let options=[];
          let selectedData={...selected}
          let type=viewTaskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
          let id=viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID;
          const valuesToExclude = new Set([
            ...selectedData.Blocking.map(item => item.value),
            ...selectedData.WaitingOn.map(item => item.value),
            `${type}_${id}`
          ]) 
          data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
          return options;
        }
        function openTaskFromDependencies(data){ 
          const [type,id]=data?.value?.split('_');  
          let tasksData={...allTaskAndSubtask}; 
            const newData=tasksData[type].get(number(id));
            if(newData){
              viewTaskFunction(newData,type);
            } 
           setIsDependencyModalOpen(false);
        }
        const handleSaveDependencies=async (data)=>{ 
          try { 
            let sourceTaskType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
            let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
            if(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0){ 
              let d={...selectedDependenciesCache};
              let blocking = new Set(d.Blocking.filter(e=>e.value).map(e => e.value));
              let waiting = new Set(d.WaitingOn.filter(e=>e.value).map(e => e.value));
              let deps={
                Blocking:data.Blocking.filter(e=> !blocking.has(e.value)),
                WaitingOn:data.WaitingOn.filter(e=> !waiting.has(e.value))
              }
              const promises = Object.keys(deps).map(async (e) => { 
              return Promise.all(deps[e].map(async (a) => { 
                const [type,id]=a.value.split('_');
              const payload={
                TargetTaskId:id,
                STaskType:sourceTaskType,
                SourceTaskId:sourceId,
                TaskType:type,
                DependancyType:e,
                PipelineROWID:viewTaskData.current.PipelineROWID,
                CREATEDBY:user.email_id
              } 
              return AwsServerService.createTempDependency(payload).then(e=>{
                // setDependencyRefresh(prev => !prev)
              });
            }));
          });
          await Promise.all(promises).then(r=>{
            let data={...selectedDependencies}
            setSelectedDependenciesCache(data)
            setIsDependencyModalOpen(false);
          })}else{
            const promises = Object.keys(data).map(async (e) => { 
              return Promise.all(data[e].map(async (a) => { 
                const [type,id]=a.value.split('_');
                const payload={
                 TargetTaskId:id,
                 STaskType:sourceTaskType,
                 SourceTaskId:sourceId,
                 TaskType:type,
                 DependancyType:e,
                 PipelineROWID:viewTaskData.current.PipelineROWID,
                 CREATEDBY:user.email_id
               } 
               return AwsServerService.createTempDependency(payload).then(e=>{ 
              });
             }));
           }); 
           await Promise.all(promises).then(r=>{
             let data={...selectedDependencies}
             setSelectedDependenciesCache(data)
             setIsDependencyModalOpen(false); 
           }); 
          }
          setDependencyRefresh(prev=>!prev);
          } catch (error) {
            // swal('Failed','Error saving dependencies','Alert');
          }
        } 
        function handleDeleteDependency(e,isWaiting){
          let [type,id]=e.value.split('_'); 
          let sourceType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
          let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
          AwsServerService.deleteTempDependency(sourceId,sourceType,id,type).then(r=>{
            if(isWaiting=='waiting'){
              let selections=[...selectedDependencies.WaitingOn];
              let data=selections?.filter(t=>t.label!==e.label);
              if(data.length==0){
                setDropdownCount({...dropdowncount,'WaitingOn':0});
              } 
              setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
            }else{
              let selections=[...selectedDependencies.Blocking];
              let data=selections?.filter(t=>t.label!==e.label);
              if(data.length==0){
                setDropdownCount({...dropdowncount,'Blocking':0});;
              }  
              setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
            } 
            })
        }
      const DependecyModal = () => {
        return ( 
            <AntModal
              open={isDependencyModalOpen} 
              wrapClassName={"Add-dependecny-Wrapper"}
              rootClassName="Add-dependecny-Root-Wrapper"
              draggable
              title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
              maskClosable={false}
              width={300}
              className=""
              style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
              onCancel={() => {
                setIsDependencyModalOpen(false); 
                viewTaskData.current={};
                setSelectedDependenciesCache({WaitingOn:[], Blocking:[]});
                // let data={...selectedDependenciesCache};
                // setSelectedDependencies(data); 
                setDependencyRefresh(prev=>!prev);
                setDropdownCount({WaitingOn:0,Blocking:0})
              }}
              footer={[<>
              <Button type="primary" onClick={()=>{
                handleSaveDependencies(selectedDependencies) 
              }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
              </>]}
            >
              <div className="add-dependency-wrapper-div">
                <div className="dependency-header"></div>
                <div className="dependency-modal-body-wrapper">
                      <Row>
                      <Col sm={2}>
                       <label htmlFor="status"><strong>Waiting on:</strong></label>
                      </Col>
                        <Col sm={10}>
                        <div className="waitingOndiv">
                        <div className="renderTasksDiv">
                          {
                            selectedDependencies.WaitingOn.map((e,index)=>{
                            let type=e.value.split('_');
                            let taskData=allTaskAndSubtask[type[0]].get(Number(type[1]));
                              return <div key={index} className="innerRendertaskdiv">
                            <Row style={{padding: "3px"}}>
                              <Col sm={1}>  
                                {taskData?.TaskStatus=='Not Started'?<span>
                                <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                                </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                                <span>
                                <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                                </span>:<span>
                                <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                                </span>}
                              </Col>
                              <Col sm={9}>
                                <div style={{cursor: 'pointer'}} onClick={()=>{
                                  openTaskFromDependencies(e);
                                }}>
                                {e.label}
                              </div>
                                </Col>
                                <Col sm={2} style={{textAlign:'right'}}> 
                                <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
                                  let [type,id]=e.value.split('_');
                                  console.log(type,id);
                                }}>
                               <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                               onClick={()=>{
                                handleDeleteDependency(e,'waiting');
                               }}
                               />
                                </span> 
                                </Col>
                                </Row>
                              </div>
                              }
                             )
                          }
                         </div>
                         {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
                        { dropdowncount.WaitingOn==1 && 
                        <div className="dropdownForWaiting">
                        <AntSelect
                        showSearch
                        size={"small"}
                        className="form-control"
                        popupClassName={"dependncy-list-dropdowns"}
                        placeholder={'Select Waiting On'}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onSelect={(e)=>{
                          let data=[...dropdownTasksDataCache]
                          let selections=[...selectedDependencies.WaitingOn];
                          selections.push(data?.filter(t=>t.value==e)[0]);
                          setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
                        }} 
                        value={null}
                        options={getOptionsForDropdown(dropdownTasksDataCache,selectedDependencies,viewTaskData.current)}
                        />
                        </div>
                      }
                      </div>
                        </Col>
                        </Row>
                      <Row style={{ marginTop: '20px' }}>
                      <Col sm={2}>
                       <label htmlFor="status"><strong>Blocking :</strong></label>
                      </Col>
                        <Col sm={10}>
                        <div className="waitingOndiv">
                        <div className="renderTasksDiv">
                          {
                            selectedDependencies.Blocking.map((e,index)=>{
                              let type=e.value.split('_');
                              let taskData=allTaskAndSubtask[type[0]].get(Number(type[1]));
                              return <div key={index} className="innerRendertaskdiv">
                            <Row style={{padding: "3px"}}>
                              <Col sm={1}>
                              {taskData?.TaskStatus=='Not Started'?<span>
                                <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                                </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                                <span>
                                <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                                </span>:<span>
                                <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                                </span>}
                              </Col>
                              <Col sm={9}>
                              <div style={{cursor: 'pointer'}} onClick={()=>{
                              openTaskFromDependencies(e);
                             }}>
                                {e.label}
                            </div>
                                </Col>
                                <Col sm={2} style={{textAlign:'right'}}> 
                                <span style={{cursor:'pointer', marginRight:"10px"}}>
                               <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                               onClick={()=>{ 
                                handleDeleteDependency(e); 
                               }}
                               />
                                </span> 
                                </Col>
                                </Row>
                              </div>}
                             )
                          }
                         </div>
                         {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
                        { dropdowncount.Blocking==1 && 
                        <div className="dropdownForWaiting">
                        <AntSelect
                        showSearch
                        size={"small"}
                        className="form-control"
                        popupClassName={"dependncy-list-dropdowns"}
                        placeholder={'Select Blocking'}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onSelect={(e)=>{
                          let data=[...dropdownTasksDataCache]
                          let selections=[...selectedDependencies.Blocking];
                          selections.push(data?.filter(t=>t.value==e)[0]);
                          setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
                          // setDropdownTasksData(data?.filter(t=>t.value != e))
                        }} 
                        value={null}
                        options={getOptionsForDropdown(dropdownTasksDataCache,selectedDependencies,viewTaskData.current)}
                        />
                        </div>
                      }
                      </div>
                        </Col>
                        </Row>
                </div>
              </div>
            </AntModal>
        );
      };
    const taskHeaders = [
        {
            label: "Task Name",
            field: "TaskName",
            render: (data, field) => {
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
                <Row style={{
                    display: "flex",
                    height: "41px",
                    alignItems:"center",
                    cursor: "pointer"
                }} sm={12}
                onClick={e=>{
                    viewTaskFunction(data,"TASK")
                }}
                ><Col id={`TaskId-${data.TaskId}`} className='Expand-Icon-Section' onClick={toggleExpandIconOfSubtask} sm={1}>
                    {data?.SubTasks?.length>0 && <><CaretRightOutlined className='LEFT SHOW'/><CaretDownOutlined className='RIGHT HIDE' /></>}
                    </Col>
                    <Col 
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        "align-items": "center",
                        gap: "10px",
                      }}>
                    <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}><span className='Elipsis-Class' style={{width:"150px"}}>{data[field]}</span></Tooltip>
                    <Tooltip color="rgb(54 54 54)" placement="top" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 
                    <div
                className="dependencyicon"
                onClick={(e) => {
                  e.stopPropagation(); 
                  viewTaskData.current=data; 
                  setIsDependencyModalOpen(true);
                  setSelectedDependenciesCache(data.deps);
                }}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {}}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
                  </div>
                  </Tooltip>
              {data?.SubTasks?.length > 0 ? (
                <div 
                onClick={(e)=>{
                  e.stopPropagation();
                  let classes = e.currentTarget.id;
                  if(classes){
                    document.getElementById(classes).click();
                  }                    
                }} 
                style={{width: "1rem"}} 
                id={`TaskId-${data.TaskId}`}
                >
                <Badge
                  color="#7a7a7a"
                  count={data?.SubTasks?.length}
                  className="subtask-badge"
                  size="small"
                  offset={[2, 0]}
                >
                  <img src={Vector} alt="subtask-icon" className="subtask-icon" />
                </Badge>
                </div>
              ) : (
                <></>
              )}
                    </Col>
                </Row>
                :
                <Input variant='borderless' value={editData ? editData?.TaskName : ""} style={{maxWidth:"280px", background:"#fff", marginTop:"2px"}} onChange={(e)=>{setEditData({...editData, TaskName : (e.target.value)?.trimStart()})}}/>
            );
            }
        },
        {
            label: "Assignee",
            field: "Assignee",
            render: (data, field) => {
                let assignee = data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
                <UserPicker 
                  rootClassName={"table-dropdown"} 
                  className={"No-Border"} 
                  values={assignee} 
                  mode="multiple" 
                  onChange={e => {
                    updateTaskData(data,"Assignee",e)
                }} 
                />
            :
                <UserPicker
                  rootClassName={"table-dropdown"}
                  className={"No-Border"} 
                  values={editData["Assignee"] ? editData["Assignee"] : ""} 
                  mode="multiple" 
                  onChange={e => {
                    let temp={...editData}
                        temp["Assignee"]=e;
                        // console.log(temp);
                        setEditData(temp);
                    }} 
                  placeholder={"Select Option"}
                  onClear={()=> setEditData({ ...editData, Assignee: "" })}
                />
            )
            }
        },
        {
            label: "Supervisor",
            field: "Supervisor",
            render: (data, field) => {
                let Supervisor = data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
                <UserPicker 
                  rootClassName={"table-dropdown"}
                  className={"No-Border"} 
                  values={Supervisor} 
                  mode="" 
                  onChange={(e) => {
                    updateTaskData(data,"Supervisor",e)
                  }} 
                  placeholder={"Select Option"}
                />
            :
                <UserPicker 
                  rootClassName={"table-dropdown"} 
                  className={"No-Border"} 
                  values={editData["Supervisor"] ? editData["Supervisor"] : ""} 
                  mode="" 
                  onChange={(e, data) => {
                      let temp={...editData}
                          temp["Supervisor"]=e;
                          // console.log(temp);
                          setEditData(temp);
                  }} 
                  placeholder={"Select Option"}
                  onClear={()=> { 
                    setEditData({ ...editData, Supervisor: "" })
                  }}
                />
            )
            }
        },
        // {
        //     label: "Description",
        //     field: "TaskDescription",
        //     render: (data, field) => {
        //         let description = data[field];
        //         return <span title={description} className='Elipsis-Class' style={{width:"400px"}}>{description}</span>;
        //     }
        // },
        {
            label: "Priority",
            field: "Priority",
            render: (data, field) => {
                // return data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
                  <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
                    <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                    value={data[field] ? data[field] : "Not Set"}
                    onChange={(val) => {
                      updateTaskData(data,"Priority",val)
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    rootClassName="table-dropdown"
                    dropdownRender={(menu) => (
                        <>
                          {menu}
                        </>
                      )}
                      optionLabelProp="icon"
                      title={data[field]}
                      onClear={()=> updateTaskData(data,"Priority","")}
                >
                    <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                    </Option>
                    <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                    </Option>
                    <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                    </Option>
                    <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                    </Option>
                </Select></Tooltip>
                :
                <Tooltip color="rgb(54 54 54)" placement="top" title={editData[field]}>
                  <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                    value={editData[field] ? editData[field] : "Not Set"}
                    onChange={(val) => {
                        let temp={...editData}
                        temp["Priority"]=val;
                        // console.log(temp);
                        setEditData(temp)
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    rootClassName="table-dropdown"
                    dropdownRender={(menu) => (
                        <>
                          {menu}
                        </>
                      )}
                      optionLabelProp="icon"
                >
                    <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                    </Option>
                    <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                    </Option>
                    <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                    </Option>
                    <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                    </Option>
                </Select>
                </Tooltip>
            )
            }
        },
        {
            label: "Time Estimate",
            field: "TimeEstimate",
            render: (data, field) => {
                return (
                    editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
             
              <CustomTimeSelect 
                rootClassName={"Task-Table-TimePicker"}
                className={(data[field] != "null" && data[field]) ? "Task-Table-TimePicker-Class" : "Task-Table-TimePicker-Class1"}
                placement={"top"}
                taskData={data}
                setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field])}}
              />
              :
              <CustomTimeSelect 
                rootClassName={"Task-Table-TimePicker"}
                className={(data[field] != "null" && data[field]) ? "Task-Table-TimePicker-Class" : "Task-Table-TimePicker-Class1"}
                placement={"top"}
                taskData={editData}
                setTaskData={(dt)=>{setEditData({...editData, "TimeEstimate" : dt?.TimeEstimate})}}
              />
            );
            }
        },
        {
            label: "Status",
            field: "Status",
            render: (data, field) => {
                return data[field];
            }
        },
        {
            label: "Pipeline",
            field: "PipelineROWID",
            render: (data, field) => {
              let pipelineROWID = editData?.PipelineROWID || data.PipelineROWID;
              let pipeline = allPipelineData.find(e => e.PipelineROWID == pipelineROWID);
              return pipeline ? pipeline.PipelineName: "N/A";
            }
        },
    ]
    const subTaskHeaders = [
        {
            label: "Task Name",
            field: "TaskName",
            render: (data, field) => {
                return <Row style={{
                    display: "flex",
                    height: "41px",
                    alignItems:"center",
                    cursor:"pointer"
                }} sm={12}
                onClick={e=>{
                    viewTaskFunction(data,"SUBTASK")
                }}
                ><Col sm={1}></Col><Col sm={1}></Col>
                <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
              }}
            >
            <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
             <span className="Elipsis-Class" style={{ width: "130px" }}>{data[field]}</span>
            </Tooltip>
            <Tooltip color="rgb(54 54 54)" placement="top" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 
                <div
                className="dependencyicon"
                onClick={(e) => {
                  e.stopPropagation();
                  let obj=data; 
                  let data2=allTaskAndSubtask['Tasks']?.get(data?.TaskRowId);
                  obj['PipelineROWID']=data2?.PipelineROWID;
                  viewTaskData.current=obj;
                  setIsDependencyModalOpen(true);
                  setSelectedDependenciesCache(data.deps); 
                }}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {}}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
              </div>
              </Tooltip>
                </Col></Row>;
            }
        },
        {
            label: "Assignee",
            field: "Assignee",
            render: (data, field) => {
                let assignee = data[field];
                return <UserPicker 
                          rootClassName={"table-dropdown"} 
                          className={"No-Border"} 
                          values={assignee} 
                          mode="multiple" 
                          onChange={e => {
                            updateTaskData(data,"Assignee",e,"subtask")
                          }}
                          placeholder={"Select Option"} 
                          onClear={()=> updateTaskData(data,"Assignee","","subtask")}
                        />;
            }
        },
        {
            label: "Supervisor",
            field: "Supervisor",
            render: (data, field) => {                
                let Supervisor = data[field];
                return <UserPicker 
                          rootClassName={"table-dropdown"} 
                          className={"No-Border"} 
                          values={Supervisor} 
                          mode="" 
                          onChange={e => {
                            updateTaskData(data,"Supervisor",e,"subtask")   
                          }} 
                          placeholder={"Select Option"}
                          onClear={()=> updateTaskData(data,"Supervisor","","subtask")}
                      />;
            }
        },
        {
            label: "Priority",
            field: "Priority",
            render: (data, field) => {
                // return data[field];
                return <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
                  <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                      value={data[field] ? data[field] : "Not Set"}
                      onChange={(val) => {
                        updateTaskData(data,"Priority",val,"subtask")
                      }}
                      // suffixIcon={<CaretDownFilled />}
                      rootClassName="table-dropdown"
                      optionLabelProp="icon"
                      onClear={()=> updateTaskData(data,"Priority","","subtask")}
                    >
                    <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") , height:"100px"}} />}>
                        <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                    </Option>
                    <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                    </Option>
                    <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                    </Option>
                    <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                    </Option>
                </Select>
            </Tooltip>;
            }
        },
        {
            label: "Time Estimate",
            field: "TimeEstimate",
            render: (data, field) => {
                return (
                  <CustomTimeSelect 
                    rootClassName={"Task-Table-TimePicker"}
                    className={(data[field] != "null" && data[field]) ? "Task-Table-TimePicker-Class" : "Task-Table-TimePicker-Class1"}
                    placement={"top"}
                    taskData={data}
                    setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field])}}
                  />
                )
            }
        },
        {
            label: "Status",
            field: "Status",
            render: (data, field) => {
                return data[field];
            }
        },
        {
            label: "Pipeline",
            field: "PipelineROWID",
            render: (data, field,task) => {
                let pipeline = allPipelineData.find(e=>e.PipelineROWID==task.PipelineROWID);
                return pipeline?.PipelineName? pipeline.PipelineName: "N/A";
            }
        },
       
    ]
    const getTabCard = () => {
        // if(groupFilter.group2.name=="Pipeline"){
        //     let pipeline = allPipelineData.find(e=>e.PipelineROWID==header);
        //     return <div className='Group-Titel-Card'>
        //         <span>{pipeline?.PipelineName? pipeline.PipelineName: "N/A"}</span>
        //     </div>
        // }else if(groupFilter.group2.name=="Assignee" || groupFilter.group2.name=="Supervisor"){
        //     let user = AllUsers.find(e=>e.zuid==header);
        //     return <div className='Group-Titel-Card'>
        //         <span>{SelectedUserAvatar({label:user?.full_name,value:user?.zuid})}</span>
        //     </div>
        // }else{
        //     return <div className='Group-Titel-Card'>
        //         <span>{header}</span>
        //     </div>
        // }
        return "Task Name"
    }
    const getTaskHeaders = () => {
        return taskHeaders.map((col, index) => {
            return <th onClick={() => sortData(col.field)}
            className={`${
              index === 0
                ? "min-width-250 task_table_header task-card-title"
                : col.label === "Assignee" ||
                  col.label === "Supervisor" ||
                  col.label === "Priority"                 
                ? "column-header min-width-100" :
                col.label === "Time Estimate" ?
                "column-header min-width-120"
                : "column-header"
            }`}
            style={index == 0 ? {justifyContent:"center"} : {}}
             >{index == 0 ? getTabCard() : col.label}
            {renderSortIcon(col.field)}
            </th>
        })
    }
    const getTaskRow = (data) => {
      return taskHeaders.map((col, index) => {            
          return <td className={`${col.label=="Action" && "custom-sticky"} ${data?.SubTasks?.length>0 && index==0?"connecting-task-1":""}`}>
            <div style={{width:'2.5em'}} 
              className={data?.hasOwnProperty("SubTaskROWID") ? selectedIdsForTemp?.Subtask?.includes(data.SubTaskROWID) ? "" : "checkBoxClass" : selectedIdsForTemp?.Task?.includes(data.TaskROWID)?'':"checkBoxClass" || selectedIdsForTemp?.Task?.includes(data?.id) ? "" : "checkBoxClass"} 
              onClick={(e)=>e.stopPropagation()}>
              {index==0 ? 
              data?.hasOwnProperty("SubTaskROWID") ? 
              <Checkbox 
                    checked={selectedIdsForTemp?.Subtask?.includes(data.SubTaskROWID)} 
                    className='Individual-Checkbox'
                    // style={{opacity: '0.7'}}
                    onClick={()=>{
                        setSelectedIdsForTemp((prev) => ({
                          ...prev,
                          Subtask: prev?.Subtask?.includes(data.SubTaskROWID)
                            ? prev.Subtask.filter(id => id !== data.SubTaskROWID)  
                            : [...prev.Subtask, data.SubTaskROWID],  
                        }))
                      }}
                  />
              :
              <Checkbox 
                    checked={selectedIdsForTemp?.Task?.includes(data.TaskROWID) || selectedIdsForTemp?.Task?.includes(data?.id)} 
                    // style={{opacity: '0.7'}}
                    className='Individual-Checkbox'
                    onClick={()=>{
                        setSelectedIdsForTemp((prev) => ({
                          ...prev,
                          Task: prev?.Task?.includes(data.TaskROWID || data?.id)
                            ? prev.Task.filter(id => id !== data.TaskROWID && id !== data?.id)  
                            : [...prev.Task, data?.id ? data?.id : data.TaskROWID],  
                        }))}}
                  />
                  :
                  ''}
              </div>
            {col.render(data, col.field)}
            </td>
      })
  }
  const getSubTaskRow = (data,task) => {
    return subTaskHeaders.map((col, index) => {
        return <td className={`${col.label=="Action" && "custom-sticky"} ${index==0?"connecting-task-2":""}`}>
          <div style={{width:'2.5em'}} 
      className={selectedIdsForTemp?.Subtask?.includes(data.SubTaskROWID)?'':"checkBoxClass"} 
      onClick={(e)=>e.stopPropagation()}>
      {index==0 ? 
      <Checkbox 
          checked={selectedIdsForTemp?.Subtask?.includes(data.SubTaskROWID)} 
          className='Individual-Checkbox'
          // style={{opacity: '0.7'}}
          onClick={()=>setSelectedIdsForTemp((prev) => ({
          ...prev,
          Subtask: prev?.Subtask?.includes(data.SubTaskROWID)
            ? prev.Subtask.filter(id => id !== data.SubTaskROWID) 
            : [...prev.Subtask, data.SubTaskROWID], 
        }))}
      />
       :
       ''}
      </div>
          {col.render(data, col.field,task)}
          </td>
    })
}
    const getSubtaskCollapse = (subtasks, task) => {
        return (subtasks.map((sub, i) => {
            let deps = getAllDependenciesForTask(true, sub);
            sub = { ...sub, deps: deps };
            return <tr className={`showCheckBox TaskId-${task.TaskId} HIDEROW`}>{getSubTaskRow(sub,task)}</tr>;
        }))
    }
    return (
        <Col sm={12} className='TaskGroup2Card'>
          {DependecyModal()}
            <Row sm={12}>
                <table className='table task-table task-content-table table-hover'>
                    <thead className=''>
                        {getTaskHeaders(header)}
                    </thead>
                    <tbody>
                        {rowData?.map((row, index) => {
                          console.log("row", row)  
                             let deps = getAllDependenciesForTask(
                                row.hasOwnProperty("SubTaskROWID"),
                                row
                              );
                              row = { ...row, deps: deps };                               
                            return <>
                                <tr key={index} className='showCheckBox'>
                                    {getTaskRow(row)}
                                </tr>
                                {row?.SubTasks?.length > 0 && getSubtaskCollapse(row.SubTasks,row)}
                            </>
                        })}
                    </tbody>
                </table>
            </Row>
        </Col>
    )
}
