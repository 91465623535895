import React, { useContext, useEffect, useState } from 'react';
import './CreateBlueprint.css';
import { Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faProjectDiagram, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ArrowLeftOutlined, CloseOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Tabs, Divider, Switch, Typography, Radio, Select, Popover, Modal} from 'antd';
import { Flow } from './Flow';
import TextArea from 'antd/es/input/TextArea';
import { CompactPicker } from 'react-color';
import { HexColorRenges, invertColor } from '../HR/TimeClock/Util';
import { CustomColorPicker } from '../CustomColorPicker/CustomColorPicker';
import AwsServerService from '../../common/util/AwsServerService';
import { AllDeals, Socket } from '../../Context/AppActions';
import AppContext from '../../Context/AppContext';
import CustomMultiSelect from '../../common/custom_multiselect/custom_multiselect';
import { AppDataRequired } from '../../App';
import { CriteriaModal } from './HelperComponents/CriteriaModal';
// import Select from 'react-select';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;

export const CreateBlueprint = ({ close, save ,editFlow,setEditFlow,view, allUsers, allUserRoles}) => {
    const AppData = useContext(AppContext);
    const { dispatch ,state} = AppData || {};
    const { DealFields } = useContext(AppDataRequired);
    const [addNodeState, setAddNodeState] = useState();
    const [rowData,setRowData] = useState([]);
    const [cacheRowData,setCacheRowData] = useState([]);
    const [dealHeaders,setDealHeaders] = useState([]);
    const [criteriaFieldGroups,setCriteriaFieldGroups]=useState({});
    console.log("headers---",DealFields);
    // const [DealFields,setDealFields] = useState();
    useEffect(e=>{
      setRowData(state.AllDeals?state.AllDeals:[])
      setCacheRowData(state.AllDeals?state.AllDeals:[])
      console.log("rrrrrrrrr-------",state.AllDeals);
    },[state.AllDeals])
    const getCriteriaFields=async()=>{
      await AwsServerService.getCriteriaFields().then(res=>{
        debugger
        let obj={};
        if(res){
          res.forEach(one=>{
            if(obj[one.module]){
              obj[one.module]=[...obj[one.module],one];
            }else{
              obj[one.module]=[one];
            }
          })
        }
        setCriteriaFieldGroups(obj);
      })
    }
    useEffect(e=>{
      getCriteriaFields()
    },[])
    let nodeTemplate = {
        data: { label: 'Step 1' },
        id: "",
        position: { x: 703.7616611847143, y: 167.5149118380199 },
        positionAbsolute: { x: 703.7616611847143, y: 167.5149118380199 },
        description: "",
        type: "custom",
    }
    const [optionsForDropdown, setOptionsForDropdown] = useState({users:[],roles:[]});
    const { Option, OptGroup } = Select;
    const [selectedData, onSelectedData] = useState({
        type: "",
        data: null,
        action: "edit"
    });
    const [bluePrintData, setBluePrintData] = useState({
        name: "",
        description: ""
    });
    const [error,setError]=useState([]);
    /////  new changes Nagesh Altekar /////
    const [selectedOption, setSelectedOption] = useState('Field');
    const [fieldValue, setFieldValue] = useState('');
    const [messageValue, setMessageValue] = useState('');
    const [radioValue, setRadioValue] = useState('fields');
    const [criteriaModal, setCriteriaModal] = useState(false);
    const [rows, setRows] = useState([{ id: Date.now() }]);


  const handleRadioChange = e => {
    setRadioValue(e.target.value);
  };
  
  // useEffect(()=>{
  //   const rejectSourceIds = new Set(editFlow?.Edges.filter(edge => edge.sourceHandle === 'reject').map(edge => edge.source)); 
  //   const filteredNodes = editFlow?.Nodes.filter(node => rejectSourceIds.has(node.id));
  //   setFiltedNode(filteredNodes)
  // },[editFlow])
  // useEffect(()=>{

  // },[DealFields])
  useEffect(()=>{
    if(selectedData?.type=="edge"){
      //  
      console.log(selectedData?.data);
      if(editFlow?.Edges){
        const edgesForCriteria = editFlow?.Edges.filter(edge => edge.target == selectedData.data?.source).map(edge => ({
          ROWID:edge.id,
          label:edge.label,
          field:edge.label,
          module:"Transition",
          type:"transition",
        })); 
        // const filteredNodes = editFlow?.Nodes.filter(node => rejectSourceIds.has(node.id));
        // setFiltedNode(filteredNodes);
        if(edgesForCriteria?.length>0){
          setCriteriaFieldGroups({...criteriaFieldGroups,Transition:edgesForCriteria})
        }
        // setEdgeOptionsForCriteria(edgesForCriteria)
      }
      
    }
  },[selectedData])
  function handleChangeCriteria(option,type,index) {
    let obj=[...rows]; 
    if(obj[index]){
      obj[index][type]=option; 
    }else{
      obj[index]={[type]:option}
    }
    setRows(obj)
  }
  const handleSaveCriteria=()=>{
    
  } 

  useEffect(()=>{
    if(allUsers && allUsers.length>0){
     let userArray = allUsers.map(e=>{ 
        return {value:e.full_name,label:e.full_name,zuid:e.zuid,status:e.status,role:e.role.name}
      })
      setOptionsForDropdown(user => ({...user,users: userArray}));
    }
    if(allUserRoles && allUserRoles.length>0){
      let rolesArray = allUserRoles.map(e=>{ 
        return {value:e.name,label:e.name,id:e.id}
      })
      setOptionsForDropdown(role => ({...role,roles: rolesArray}));
    }
  },[allUsers,allUserRoles])
  const handleChange = (value) => {
    console.log('Selected values: ', value);
  };

  const getDescriptionPopover = (data) => {
    
    // Content for the popover, showing the form based on selected option
    const content = (
      <div>
        <Radio.Group 
          value={selectedOption} 
          onChange={(e) => setSelectedOption(e.target.value)}
          style={{ marginBottom: '10px' }}
        >
          <Radio value="Field">Field</Radio>
          <Radio value="Message">Message</Radio>
        </Radio.Group>
  
        {selectedOption === 'Field' ? (
          <Select
            placeholder="Select"
            value={fieldValue}
            onChange={(value) => setFieldValue(value)}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="field1">Field 1</Option>
            <Option value="field2">Field 2</Option>
          </Select>
        ) : (
          <TextArea
            placeholder="Enter message"
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            maxLength={100}
            rows={1}
            style={{ marginBottom: '10px' }}
          />
        )}
  
        <Button type="primary" onClick={() => console.log('Add button clicked')}>Add</Button>
        <Button style={{ marginLeft: '10px' }} onClick={() => console.log('Cancel button clicked')}>Cancel</Button>
      </div>
    );
  
    return (
      <Popover content={content} title="" trigger="click" rootClassName="Blueprint-Field-Popover">
        <Button type="link" style={{ marginLeft: '10px' }}>+ Add</Button>
      </Popover>
    );
  };

  const handleAddRow = () => {
    setRows([...rows, { id: Date.now() }]); // Add new row with unique id
  };

  const handleRemoveRow = (id,keyToRemove) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    // setCriteriaDetails((prevDetails) => { 
    //   const newDetails = Object.keys(prevDetails)
    //     .filter((key) => key !== String(keyToRemove))
    //     .reduce((acc, key, index) => { 
    //       acc[index] = prevDetails[key];
    //       return acc;
    //     }, {}); 
    //   return newDetails;
    // });
  
  };
  
  // const setActiveFilterAndGetActiveFilters = async (
  //   filters,
  //   action,
  //   mainFilters
  // ) => {
  //   let api = gridApi;
  //   if (action == "SAVE") {
  //     if (api) {
  //       let filtersModal = await api.getFilterModel();
  //       return filtersModal;
  //     }
  //   } else if (action == "SET") {
  //     let filterMain = mainFilters;
  //     if (!_.isArray(mainFilters) && !_.isEmpty(mainFilters)) {
  //       do {
  //         filterMain = JSON.parse(filterMain);
  //       } while (!_.isArray(filterMain));
  //     }
  //     let filter = filters;
  //     if (!_.isObject(filters) && !_.isEmpty(filters)) {
  //       do {
  //         filter = JSON.parse(filter);
  //       } while (!_.isObject(filter));
  //     }
  //     setPreAppliedFiltersForView(filterMain);
  //     setTimeout(() => {
  //       setActiveFiltersOnColumnLevel(filter);
  //     }, 1000);
  //   }
  // };
  const [filtersmode, setfiltersmode] = useState([]);
  const [AllDealsClicked, setAllDealsClicked] = useState(false);
  const [dealStatus, setDealStatus] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [filterItemsDeal, setFilterItemsDeal] = useState([
    { name: "Deal Name", type: "string" },
    { name: "Client", type: "string" },
    { name: "Status", type: "string" },
    { name: "Borough", type: "string" },
    { name: "Closing Date", type: "date" },
    { name: "Created By", type: "string" },
  ]);
  useEffect(() => {
    if (DealFields.length > 0) {
      let arr = [];
      const items = [...DealFields];

      items.forEach((one) => {
        // if (!hideArray.includes(one.field) && !(
        //   pvSystemTableHeaders.find(e => e.value == one.field)
        //   || wwwViolationHeaders.find(e => e.value == one.field)
        //   || previousSolarAppHeaders.find(e => e.value == one.field)
        // )) {
          if (one.type == "text") {
            one.field == "Occupancy_Classification_Building_2014_2008_Code" ?
              arr.push({ name: one.name, type: "boolean", api_name: one.field }) :
              arr.push({ name: one.name, type: "string", api_name: one.field });
          } else if (one.type == "integer") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else if (one.type == "date") {
            arr.push({ name: one.name, type: "date", api_name: one.field });
          } else if (one.type == "picklist") {
            one.field == "Owner_Type" || one.field == "Active_Work_Without_Permit_Violations"
              || one.field == "Occupancy_Classification_of_Building" ?
              arr.push({ name: one.name, type: "string", api_name: one.field }) :
              arr.push({ name: one.name, type: "boolean", api_name: one.field });
          } else if (one.type == "formula") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else if (one.type == "currency") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else {
            arr.push({ name: one.name, type: "string", api_name: one.field });
          }
        // }
      });
      setDealHeaders(arr);
    }
  }, [DealFields]);

    /////  new changes Nagesh Altekar /////

    const validation=(data)=>{
        let temp=[]
        Object.keys(data).forEach(key=>{
          if(!data[key]){
            temp.push(key)
          }
        })
        setError(temp);
        return temp.length==0;
    }
    useEffect(e=>{
        if(editFlow){
            //  
            let {Name,Description} = {...editFlow};
            setBluePrintData({
                name:Name,
                description:Description
            })
            onSelectedData({
                type:"edit flow",
                action:"",
                data:{...editFlow}
            })

            // setEditFlow(null);
        }
    },[editFlow])
    console.log("selectedData?.type", selectedData);
    
    return (
      <div className="Create-Blueprint-Wrapper">
        {<CriteriaModal mainFields={criteriaFieldGroups} open={criteriaModal} setOpen={setCriteriaModal}  />}
        <div className="Blueprint-Header">
          <Row className="">
            <Col className="d-flex align-items-center" sm={4}>
              {/* <FontAwesomeIcon className='logo' icon={faProjectDiagram} /> */}
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  marginLeft: "10px",
                  color: "#5b6c97",
                }}
              >
                {view == "create"
                  ? "Create Task flow"
                  : view == "edit"
                  ? "Edit Task Flow"
                  : ""}
              </span>
            </Col>
            <Col sm={4}></Col>
            <Col sm={3}></Col>
            <Col
              sm={1}
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "end",
              }}
            >
              <Button
                icon={<FontAwesomeIcon className="opacity-75" icon={faTimes} />}
                onClick={close}
              ></Button>
            </Col>
          </Row>
        </div>
        <Row className="Create-Blueprint-Body m-0">
          <Col style={{ position: "relative" }} sm={8}>
            <Button
              type="primary"
              className="Add-Node-Button"
              icon={<FontAwesomeIcon className="opacity-75" icon={faPlus} />}
              onClick={() => {
                setAddNodeState("add");
              }}
            >
              Add Node
            </Button>
            <Flow
              view={view}
              editFlow={editFlow}
              addNodeButton={addNodeState}
              resetAddNodeButton={setAddNodeState}
              selectedData={selectedData}
              onSelectedData={onSelectedData}
              onNodeSelect={(node) => {
                onSelectedData({
                  type: "node",
                  data: node,
                  action: "edit",
                });
              }}
              onEdgeSelected={(edge) => {
                onSelectedData({
                  type: "edge",
                  data: edge,
                  action: "edit",
                });
              }}
              saveCallback={(res) => {
                //  
                dispatch(
                  Socket({ trigger: "Refresh_Module", data: "Task Flow" })
                );
                setBluePrintData({
                  name: "",
                  description: "",
                });
                close();
              }}
            />
          </Col>
          <Col className="right-section" sm={4}>
            <Row
              style={{
                // "padding": "10px 0px",
                height: "3rem",
                border: "1px solid #e8e8e8",
                margin: "0px",
                "box-shadow": "0px 0px 5px -1px grey",
              }}
              className="mt-2 mb-2 d-flex align-items-center"
              sm={12}
            >
              <Col sm={10}>
                <span
                  style={{
                    "font-size": "1rem",
                    "font-weight": "600",
                    "margin-left": "10px",
                    color: "rgb(91, 108, 151)",
                  }}
                  className="fs-3"
                >
                  {!selectedData?.type ? (
                    "Task Flow"
                  ) : selectedData?.type == "node" ? (
                    "Edit Node"
                  ) : selectedData?.type == "edge" ? (
                    "Edit Edge"
                  ) : (
                    <></>
                  )}
                </span>
              </Col>
              <Col className="d-flex justify-content-end" sm={2}>
                {!selectedData?.type && (
                  <>
                    <Button
                      className="mr-1"
                      type="primary"
                      // icon={<FontAwesomeIcon className='opacity-75' color='red' icon={faTrashAlt} />}
                      onClick={() => {
                        if (validation({ ...bluePrintData })) {
                          onSelectedData({
                            type: "flow",
                            data: { ...bluePrintData },
                            action: view,
                          });
                        }
                      }}
                    >
                      {view == "edit" ? "Update" : "Save"}
                    </Button>
                    {/* <Button
                                    className='mr-1'
                                    // type=''
                                    // icon={<FontAwesomeIcon className='opacity-75' color='red' icon={faRel} />}
                                    onClick={() => {
                                        
                                    }}
                                >Clear</Button> */}
                  </>
                )}
                {selectedData?.type && (
                  <>
                    <Button
                      className="mr-1"
                      icon={
                        <FontAwesomeIcon
                          className="opacity-75"
                          color="red"
                          icon={faTrashAlt}
                        />
                      }
                      onClick={() => {
                        let temp = { ...selectedData };
                        temp["action"] = "delete";
                        onSelectedData(temp);
                      }}
                    ></Button>
                    <Button
                      icon={
                        <FontAwesomeIcon
                          className="opacity-75"
                          icon={faArrowLeft}
                        />
                      }
                      onClick={() => {
                        onSelectedData({
                          type: "",
                          data: "",
                          action: "",
                        });
                      }}
                    ></Button>
                  </>
                )}
              </Col>
            </Row>
            {selectedData?.type == "node" ? (
              <>
                <Row className="Input-Group">
                  <Col className="d-flex p-0 m-0 align-items-center" sm={1}>
                    <CustomColorPicker
                      value={selectedData?.data?.data?.color}
                      onChange={(color) => {
                        let obj = { ...selectedData };
                        obj["data"]["data"]["color"] = color;
                        onSelectedData(obj);
                      }}
                    />
                  </Col>
                  <Col sm={11}>
                    <span className="labeltext">
                      <Label>Name</Label>
                    </span>
                    <Input
                      className="input"
                      value={selectedData?.data?.data?.label}
                      onChange={(e) => {
                        let obj = { ...selectedData };
                        obj["data"]["data"]["label"] = e.currentTarget.value;
                        onSelectedData(obj);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="Input-Group">
                  <Col>
                    <span className="labeltext">
                      <Label>Description</Label>
                    </span>
                    <TextArea
                      value={selectedData?.data?.description}
                      style={{
                        padding: "5px 13px",
                        "min-height": "5rem",
                        "max-height": "5rem",
                      }}
                      onChange={(e) => {
                        let obj = { ...selectedData };
                        obj["data"]["description"] = e.currentTarget.value;
                        onSelectedData(obj);
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : selectedData?.type == "edge" ? (
              <>
                <Row className="Input-Group">
                  <Col className="d-flex p-0 m-0 align-items-center" sm={1}>
                    <CustomColorPicker
                      value={selectedData?.data?.data?.color}
                      onChange={(color) => {
                        let obj = { ...selectedData };
                        if(obj["data"]["data"]){
                          obj["data"]["data"]["color"] = color;
                        }else{
                          obj["data"]["data"]={color:color};
                        }
                        onSelectedData(obj);
                      }}
                    />
                  </Col>
                  <Col sm={11}>
                    <span className="labeltext">
                      <Label>Label</Label>
                    </span>
                    <Input
                      className="input"
                      value={selectedData?.data?.label}
                      onChange={(e) => {
                        let obj = { ...selectedData };
                        obj["data"]["label"] = e.currentTarget.value;
                        onSelectedData(obj);
                        
                      }}
                    />
                  </Col>
                </Row>
                <Row className="Trasition-Tabs">
                  <Col>
                    <div style={{ padding: "20px" }}>
                    {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                          <Title style={!selectedData?.data?.rework && {opacity: 0.5}} level={4}>Rework</Title>
                        <Switch value={selectedData?.data?.rework} onChange={(e)=>{
                           
                          let obj = { ...selectedData };
                          obj["data"]["rework"] = e;
                          onSelectedData(obj);
                        }}/>
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Title level={4}>Common Transition</Title>
                          <Text>{editFlow?.Nodes?.filter(e=>e.id==selectedData?.data?.source)[0]?.data?.label} → {editFlow?.Nodes?.filter(e=>e.id==selectedData?.data?.target)[0]?.data?.label}</Text>                        </div>
                        <Switch />
                      </div>
                     

                      <Divider />

                      <Tabs defaultActiveKey="1" centered>
                        <TabPane tab="Before" key="1">
                          <div>
                            <h5>Users</h5>
                            <p style={{marginBottom: "10px"}}>
                               Select individual users or roles to view and perform this transition.
                            </p>
                            <Select 
                             mode="multiple" 
                             style={{width: '25.5rem',height: 'auto'}}
                             placeholder="Select"
                             onChange={handleChange}
                           >
                            <OptGroup label="Roles">
                            {optionsForDropdown.roles?.map(option => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </OptGroup>
                          <OptGroup label="Users">
                            {optionsForDropdown.users?.map(user => (
                              <Option key={user.value} value={user.value}>
                                {user.label}
                              </Option>
                            ))}
                          </OptGroup>
                          </Select>
                          </div>
                          <div style={{marginTop: "1rem"}}>
                            <h5>Criteria</h5>
                            <p style={{marginBottom: "10px"}}>
                              Add criteria based on which the transition will be triggered.
                            </p>
                            <Button onClick={e=>{setCriteriaModal(true)}} icon={<FontAwesomeIcon icon={faPlus}/>}>Add Criteria</Button>
                          </div>
                        </TabPane>
                        <TabPane tab="During" key="2">
                        <Radio.Group onChange={handleRadioChange} value={radioValue}>
                          <div>
                            <Radio value="fields">
                              <Text strong>Fields and Messages</Text>
                              {getDescriptionPopover()}
                            </Radio>
                            {radioValue === 'fields' && (
                              <>
                                <div style={{ marginTop: '10px' }}>
                                  <Text>Configure fields and messages that will be displayed while performing a transition in the Task details page.</Text>
                                </div>
                              </>
                            )}
                          </div>

                          <Divider />

                          {/* <div>
                            <Radio value="extensions">
                              <Text strong>Extensions</Text> 
                              <RightOutlined />
                            </Radio>
                            {radioValue === 'extensions' && (
                              <div style={{ marginTop: '10px'}}>
                              </div>
                            )}
                          </div> */}
                         </Radio.Group>
                        </TabPane>
                        <TabPane tab="After" key="3">
                          <Text>
                            Configure actions to be automated after a transition
                            is performed.
                          </Text>

                          <div style={{ marginTop: "20px" , display:"flex", flexDirection:"column", width:"10rem"}}>
                            <Button type="link" icon={<PlusOutlined />} iconPosition='end' style={{ fontSize: "15px" }}>
                              Email Alerts
                            </Button>
                            <Button type="link" icon={<PlusOutlined />} iconPosition='end' style={{ fontSize: "15px" }}>
                              Field Update
                            </Button>
                            <Button type="link" icon={<PlusOutlined />} iconPosition='end' style={{ fontSize: "15px" }}>
                              Custom Function
                            </Button>
                            <Button type="link" icon={<PlusOutlined />} iconPosition='end' style={{ fontSize: "15px" }}>
                              Webhooks
                            </Button>
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="Input-Group">
                  <Col>
                    <span className="labeltext">
                      <Label>Name</Label>
                    </span>
                    <Input
                      className="input"
                      value={bluePrintData?.name}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          let err = error?.filter((e) => e != "name");
                          setError(err);
                        }
                        e.currentTarget.value = (e.currentTarget.value)?.trimStart();
                        let obj = { ...bluePrintData };
                        obj["name"] = e.currentTarget.value;
                        setBluePrintData(obj);
                      }}
                    />
                    {error.includes("name") && (
                      <span style={{ color: "red" }}>
                        Please fill the required field
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="Input-Group">
                  <Col>
                    <span className="labeltext">
                      <Label>Description</Label>
                    </span>
                    <TextArea
                      value={bluePrintData?.description}
                      style={{
                        padding: "5px 13px",
                        "min-height": "5rem",
                        "max-height": "5rem",
                      }}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          let err = error?.filter((e) => e != "description");
                          setError(err);
                        }
                        e.currentTarget.value = (e.currentTarget.value)?.trimStart();
                        let obj = { ...bluePrintData };
                        obj["description"] = e.currentTarget.value;
                        setBluePrintData(obj);
                      }}
                    />
                    {error.includes("description") && (
                      <span style={{ color: "red" }}>
                        Please fill the required field
                      </span>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
}