import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { AppDataRequired } from '../../../App';
import _ from "lodash";
import AppContext from '../../../Context/AppContext';
const { Option, OptGroup } = Select;
export const CriteriaModal = ({ open, setOpen, mainFields }) => {
    const AppDataReq = useContext(AppDataRequired);
    const AppData = useContext(AppContext);
    const { dispatch ,state} = AppData || {};
    // const { dispatch ,state} = AppData || {};
    const [rows, setRows] = useState([{ id: Date.now() }]);
    const [criteriaFieldGroups, setCriteriaFieldGroups] = useState({});
    const [lastSelectOptions,setLastSelectOptions] = useState([
        { value: 'Required', label: 'Required' },
        { value: 'Not Required', label: 'Not Required' },
        { value: 'Approved', label: 'Approved' }
    ]);
    const defaultOptions = [
        { value: 'Required', label: 'Required' },
        { value: 'Not Required', label: 'Not Required' },
        { value: 'Approved', label: 'Approved' }
    ]
    useEffect(e => {
        setCriteriaFieldGroups(mainFields);
    }, [mainFields])
    function handleChangeCriteria(option, type, index,group) {
        let obj = [...rows];
        if (obj[index]) {
            obj[index][type] = option;
            if(group){
                obj[index]["group"] = group;
            }
        } else {
            obj[index] = { [type]: option }
        }
        setRows(obj)
    }
    const handleAddRow = () => {
        setRows([...rows, { id: Date.now() }]); // Add new row with unique id
    };
    const handleRemoveRow = (id, keyToRemove) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };
    const getOptions=(row,index)=>{
        debugger
        if(row?.group=="Project"){
            if(row &&  row.initial && row.middel && state.AllDeals){
                return _.uniqBy(state.AllDeals, row.initial)?.map(deal=>({label:deal[row.initial],value:deal[row.initial]}))
            }
        }else if(row?.group=="Task"){
            if(row &&  row.initial && row.middel && state.AllDeals){
                return _.uniqBy(AppDataReq.AllTasks, row.initial)?.map(deal=>({label:deal[row.initial],value:deal[row.initial]}))
            }
        }
        return defaultOptions;
    }
    return (
        <div>
            <Modal
                open={open}
                // open={false}
                wrapClassName={"Add-criteria-Wrapper"}
                rootClassName="Add-criteria-Root-Wrapper"
                draggable
                title={"Transition Criteria"}
                maskClosable={false}
                width={600}
                style={{ bottom: '2rem', right: '9rem', zIndex: "1000", minHeight: "" }}
                onCancel={() => setOpen(false)}
                footer={[
                    <>
                        <Button type="primary" onClick={() => {
                            // Handle save logic here
                            // handleSaveCriteria();
                        }}>Save</Button>
                    </>
                ]}
            >

                {rows.map((row, index) => (<>

                    <Row key={row.id} style={{ marginBottom: '10px' }} align="middle">
                        <Col sm={1}>
                            {index > 0 && <div className='Criteria-ToggleButton'>
                                <Switch
                                    checked={row?.condition == "AND" ? true : false}
                                    checkedChildren={"AND"}
                                    unCheckedChildren={"OR"}
                                    onChange={(checked, event) => {
                                        const condition = checked ? "AND" : "OR";
                                        handleChangeCriteria(condition, 'condition', index)
                                    }}
                                />
                            </div>}
                        </Col>
                        <Col sm={3}>
                            <Select
                                // mode="multiple"
                                style={{ width: '10rem', height: 'auto' }}
                                placeholder="Select"
                                value={row.initial}
                                id='initial'
                                onChange={(e, option) => handleChangeCriteria(option.value, 'initial', index,option.group)}
                            >
                                {Object.keys(criteriaFieldGroups)?.map(group => {
                                    return <OptGroup label={group}>
                                        {criteriaFieldGroups[group]?.map(e => (
                                            <Option key={e.ROWID} group={group} value={e?.field}>{e?.label}</Option>
                                        ))}
                                    </OptGroup>
                                })}

                            </Select>
                        </Col>
                        <Col sm={3}>
                            <Select
                                // mode="multiple"
                                value={row.middel}
                                style={{ width: '10rem', height: 'auto' }}
                                placeholder="Select"
                                id='middel'
                                onChange={(e, option) => handleChangeCriteria(option.value, 'middel', index)}
                                options={[{ value: 'Is', label: 'Is' }, { value: 'Is Not', label: 'Is Not' }, { value: 'Contains', label: 'Contains' }]}
                            />
                        </Col>
                        <Col sm={3}>
                            {row.middel == "Contains" ?
                                <><Input
                                    style={{ width: '10rem', height: 'auto' }}
                                    value={row.last}
                                    placeholder="Type.."
                                    id='last'
                                    onChange={(e) => handleChangeCriteria(e.currentTarget.value, 'last', index)}
                                /></>
                                : <Select
                                    // mode="multiple"
                                    style={{ width: '10rem', height: 'auto' }}
                                    value={row.last}
                                    placeholder="Select"
                                    id='last'
                                    onChange={(e, option) => handleChangeCriteria(option.value, 'last', index)}
                                    // options={getOptions(row,index)}
                                >
                                    {getOptions(row,index)?.map(opt=>{
                                        return (<Option key={opt.value} value={opt.value}>
                                        {opt.label}
                                      </Option>)
                                    })}    
                                </Select>}
                        </Col>
                        <Col sm={2}>
                            <Button
                                icon={<MinusCircleOutlined />}
                                ghost
                                onClick={() => handleRemoveRow(row.id, index)}
                                style={index == 0 ? { visibility: "hidden" } : { border: "none" }}
                                danger
                            />
                            <Button
                                onClick={handleAddRow}
                                icon={<PlusCircleOutlined width={10} height={10} />}
                                style={{ border: "none" }}
                            />
                        </Col>
                    </Row></>
                ))}
            </Modal>
        </div>
    )
}
