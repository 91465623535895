import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import "./RenderAllTasks.css";
import { Badge, Checkbox, Popover, Select, Tooltip } from "antd";
import {
  SelectedUserAvatar,
  UserPicker,
} from "../../common/UserPicker/UserPicker";
import { Option } from "antd/es/mentions";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  FlagFilled,
} from "@ant-design/icons";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCheckCircle,
  faCircle,
  faClock,
  faGripVertical,
  faMinusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { convertToUSDate, getPriorityValue, invertColor } from "../HR/TimeClock/Util";
import AwsServerService from "../../common/util/AwsServerService";
import minusCircle from "../../assets/images/svg/minus-circle.svg";
import AppContext from "../../Context/AppContext";
import Vector from "../../assets/images/vector.png";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import { useHistory } from "react-router-dom";
import TimeTrackerForList from "./TimeTrackerForList";
import {
  Modal as AntModal,
  Button as AntButton,
  Select as AntSelect,  
  Button,   
} from "antd";
import { number } from "mathjs";
import _ from 'lodash';


export const TaskGroupCard = ({
  header,
  data,
  allPipelineData,
  groupFilter,
  viewTaskFunction,
  setTaskHeadersData,
  taskHeadersData,
  setIsSubTaskView,
  taskFilterSetting,
  setPageRefreshData,
  allDependeniesData,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  allTimeRecords,
  selectedIds,
  setSelectedIds,
  setDummyID,
  key1,
  dummyId,
  setDependencyRefresh
}) => {
  const [rowData, setRowData] = useState(data);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // State for sorting
  const { AllUsers } = useContext(AppDataRequired);
  const AppData = useContext(AppContext);
  const { dispatch, state } = AppData || {};
  const [dropdowndata, setdropdownTasksData] = useState(
    new Map(dropdownTasksDataCache.map((item) => [item.value, item]))
  );
  const [updated,setUpdated]=useState(false);
  const anotherSessionActiveFlag = useRef({Active:false,Record:{}});
  let user = JSON.parse(localStorage.getItem("userinfo"));
  let history = useHistory(); 

  useEffect((e) => {
      setRowData(data);
    },
    [data]
  );
  const toggleExpandIconOfSubtask = (e) => {
    e.stopPropagation();
    let arr = e.currentTarget.childNodes;
    let taskId = e.currentTarget.id;
    let arrofsubtasks = document.querySelectorAll(`.${taskId}`);
    arr.forEach((element) => {
      if (element.classList.contains("SHOW")) {
        element.classList.remove("SHOW");
        element.classList.add("HIDE");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.add("HIDEROW");
        });
      } else if (element.classList.contains("HIDE")) {
        element.classList.remove("HIDE");
        element.classList.add("SHOW");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.remove("HIDEROW");
        });
      }
    });
    console.log(arr);
  };

  const getRelatedTimeRecords = (data,type) =>{ 
    let records=allTimeRecords.filter(k => k.TaskROWID == data.TaskROWID && k.TaskType==type);
    return records;
  }

  const navigateToDeal = (deal) => {
    deal = { ...deal }
    deal.Created_By = deal[0].Created_By.name;
    deal.Owner = deal[0].Owner.name;
    deal.Account_Name = deal[0].Account_Name ? deal[0].Account_Name.name : "";
    history.push({
      pathname: '/d/Deals/DetailView/' + deal[0].id,
      state: { data: deal[0], action: 'DetailView', module: 'Deals' }
    });
  }
  
  const showTaskLocationNames = (data) => {
    let allDeals = [...state?.AllDeals];
    let filteredDeal = allPipelineData?.filter(item => item?.PipelineROWID==data.PipelineROWID)[0];
    let accountName=allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName ? e?.Account_Name:'');
    let filterDealData = allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName);
    
    return (
      <>
        <span
          className="cont-box2"
        >
          <span
            onClick={() => {
              history.push({
                pathname: '/c/Accounts/DetailView/' + accountName[0].Account_Name.id,
                state: { data: accountName[0].Account_Name, action: 'Update', module: 'Accounts' }
              });
            }}
            // style={clientColorObj}
            className="cont-box3"
          >{accountName[0].Account_Name.name}

          </span>
          <span class="custom-slash">{"/"}</span>
          <span  onClick={() => { navigateToDeal(filterDealData)}

            } className="cont-box4 cursor-pointer">
            {filteredDeal?.DealName}
          </span>
          {/* <span className="cont-box5">
            {filterJob.Project_Address}
          </span> */}
        </span>


      </>
    )
  }

  const showSubtaskParentNames = (tasks) => {

    console.log("Tasks :", tasks);
    console.log("allPipelineData :", allPipelineData);
    

    let filteredTasks = allPipelineData?.flatMap(item =>
      item?.Tasks?.filter(task => task?.TaskROWID === tasks?.TaskRowId) || []
    );
    
    
    console.log("filteredTask", filteredTasks);
    
     if (filteredTasks && filteredTasks?.length != 0) {
      return (
        <span style={{
          background: 'rgb(8, 174, 234)',
          color: '#fff'
        }}>{filteredTasks ? filteredTasks[0].TaskName : ""}</span>
      )
    }
  }

  const getDescriptionPopover = (data) => {
    const content = (
      <p style={{ overflowWrap: "break-word", fontWeight:"500" }}>
        {data?.TaskDescription}
      </p>
    );
    return (
      <Popover content={content} title="" trigger="hover" rootClassName="Description-Popover">
        <FontAwesomeIcon icon={faAlignLeft} style={data?.TaskDescription != "null" && data?.TaskDescription ? {visibility: "visible"} : {visibility: "hidden"}}/>
      </Popover>
    );
  };
  const sortData = (field) => {
    let direction = "ascending";
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = "none";
      }
    }

    if (direction === "none") {
      setRowData(data); // Reset to original data
      setSortConfig({ key: "", direction: "" });
    } else {
      const sortedData = [...rowData].sort((a, b) => {
        if (a[field] < b[field]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setRowData(sortedData);
      setSortConfig({ key: field, direction });
    }
  };

  // Render sorting icons based on the current sort state
  const renderSortIcon = (field) => {
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        return <CaretUpOutlined className="sort-icon"/>;
      } else if (sortConfig.direction === "descending") {
        return <CaretDownOutlined className="sort-icon"/>;
      }
    }
    return null; // No icon when not sorted
  };
  const updateTaskData = (data, field, val, type) => {
    
    const data2 = {
      ROWID: type !== "subtask" ? data.TaskROWID : data.SubTaskROWID,
      [field]: val || "",
      MODIFIEDBY: user.email_id,
    };
    
    const updateService = type !== "subtask" 
      ? AwsServerService.updateTask 
      : AwsServerService.updateSubtask;
  
    updateService(data2)
      .then((data) => {
        setPageRefreshData(prev => !prev);
      })
      .catch(er => console.log("Er", er));
  };

  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]}) 
  const [dealSelected,setSelectedDeal]=useState('') 
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0, Blocking:0}) 
  const [isDependencyModalOpen,setIsDependencyModalOpen]=useState(false) 
  const [filteredDropdownTasksData,setFilteredDropdownTasksData]=useState([]) 
  const viewTaskData=useRef({});
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  });
  useEffect(()=>{ 
    if(selectedDependenciesCache.WaitingOn.length>0 || selectedDependenciesCache.Blocking.length>0){
      let data={...selectedDependenciesCache}
      // setIsDependencyModalOpen(true);
      setSelectedDependencies(data); 
    }
  },[selectedDependenciesCache]) 
  useEffect(()=>{ 
    if(dealSelected){
      let pipelines=allPipelineData.filter(e=>e.DealName==dealSelected);  
      const availableTaskIds = new Set(); 
      pipelines.forEach(pipeline => {
          pipeline.Tasks.forEach(pipelineTask => { 
              availableTaskIds.add(`Tasks_${pipelineTask.TaskROWID}`); 
              if (pipelineTask.SubTasks) {
                  pipelineTask.SubTasks.forEach(subtask => {
                      availableTaskIds.add(`SubTasks_${subtask.SubTaskROWID}`);
                  });
              }
          });
      }); 
     let filteredTasks= dropdownTasksDataCache.filter(task => availableTaskIds.has(task.value));
     setFilteredDropdownTasksData(filteredTasks);
    }
    },[dealSelected])
    function getOptionsForDropdown(data,selected,viewTaskData){
      let options=[];
      let selectedData={...selected}
      let type=viewTaskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let id=viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID;
      const valuesToExclude = new Set([
        ...selectedData.Blocking.map(item => item.value),
        ...selectedData.WaitingOn.map(item => item.value),
        `${type}_${id}`
      ]) 
      data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
      return options;
    }
    function openTaskFromDependencies(data){ 
      const [type,id]=data?.value?.split('_');  
      let tasksData={...allTasksAndSubtasks}; 
        const newData=tasksData[type].get(number(id));
        if(newData){
          viewTaskFunction(newData,type);
        } 
       setIsDependencyModalOpen(false);
    }
    const handleSaveDependencies=async (data)=>{ 
      try { 
        let sourceTaskType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
        let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
        if(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0){ 
          let d={...selectedDependenciesCache};
          let blocking = new Set(d.Blocking.filter(e=>e.value).map(e => e.value));
          let waiting = new Set(d.WaitingOn.filter(e=>e.value).map(e => e.value));
          let deps={
            Blocking:data.Blocking.filter(e=> !blocking.has(e.value)),
            WaitingOn:data.WaitingOn.filter(e=> !waiting.has(e.value))
          }
          const promises = Object.keys(deps).map(async (e) => { 
          return Promise.all(deps[e].map(async (a) => { 
            const [type,id]=a.value.split('_');
          const payload={
            TargetTaskId:id,
            STaskType:sourceTaskType,
            SourceTaskId:sourceId,
            TaskType:type,
            DependancyType:e,
            CREATEDBY:user.email_id
          } 
          return AwsServerService.createDependency(payload).then(e=>{
            // setDependencyRefresh(prev => !prev)
          })
        }));
      });
      await Promise.all(promises).then(r=>{
        let data={...selectedDependencies}
        setSelectedDependenciesCache(data)
        setIsDependencyModalOpen(false);
      })
        }else{
         const promises = Object.keys(data).map(async (e) => { 
           return Promise.all(data[e].map(async (a) => { 
             const [type,id]=a.value.split('_');
             const payload={
              TargetTaskId:id,
              STaskType:sourceTaskType,
              SourceTaskId:sourceId,
              TaskType:type,
              DependancyType:e,
              CREATEDBY:user.email_id
            } 
            return AwsServerService.createDependency(payload).then(e=>{
              // setDependencyRefresh(prev => !prev)
            });
          }));
        }); 
        await Promise.all(promises).then(r=>{
          let data={...selectedDependencies}
          setSelectedDependenciesCache(data)
          setIsDependencyModalOpen(false);
          // swal('Success','All dependencies saved successfully','success');
        });
      } 
      setDependencyRefresh(prev=>!prev);
    } catch (error) {
        // swal('Failed','Error saving dependencies','Alert');
      }
    } 
    function handleDeleteDependency(e,isWaiting){
      let [type,id]=e.value.split('_'); 
      let sourceType=viewTaskData.current.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let sourceId=viewTaskData.current.hasOwnProperty('SubTaskROWID')?viewTaskData.current.SubTaskROWID:viewTaskData.current.TaskROWID;
        AwsServerService.deleteDependency(sourceId,sourceType,id,type).then(r=>{
        if(isWaiting=='waiting'){
          let selections=[...selectedDependencies.WaitingOn];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'WaitingOn':0});
          }
          setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
        }else{
          let selections=[...selectedDependencies.Blocking];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'Blocking':0});;
          }  
          setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
        }
        }) 
    }
  const DependecyModal = () => {
    return ( 
        <AntModal
          open={isDependencyModalOpen} 
          wrapClassName={"Add-dependecny-Wrapper"}
          rootClassName="Add-dependecny-Root-Wrapper"
          draggable
          title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
          maskClosable={false}
          width={300}
          className=""
          style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
          onCancel={() => {
            setIsDependencyModalOpen(false); 
            viewTaskData.current={};
            setSelectedDependenciesCache({WaitingOn:[], Blocking:[]});
            // let data={...selectedDependenciesCache};
            // setSelectedDependencies(data); 
            setDependencyRefresh(prev=>!prev);
            setDropdownCount({WaitingOn:0,Blocking:0})
          }}
          footer={[<>
          <Button type="primary" onClick={()=>{
            handleSaveDependencies(selectedDependencies) 
          }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
          </>]}
        >
          <div className="add-dependency-wrapper-div">
            <div className="dependency-header"></div>
            <div className="dependency-modal-body-wrapper">
                  <Row>
                  <Col sm={2}>
                   <label htmlFor="status"><strong>Waiting on:</strong></label>
                  </Col>
                    <Col sm={10}>
                    <div className="waitingOndiv">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.WaitingOn.map((e,index)=>{
                        let type=e.value.split('_');
                        let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRendertaskdiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>  
                            {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                            <div style={{cursor: 'pointer'}} onClick={()=>{
                              openTaskFromDependencies(e);
                            }}>
                            {e.label}
                          </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
                              let [type,id]=e.value.split('_');
                              console.log(type,id);
                            }}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{
                            handleDeleteDependency(e,'waiting');
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>
                          }
                         )
                      }
                     </div>
                     {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
                    { dropdowncount.WaitingOn==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Waiting On'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.WaitingOn];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies,viewTaskData.current)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
                  <Row style={{ marginTop: '20px' }}>
                  <Col sm={2}>
                   <label htmlFor="status"><strong>Blocking :</strong></label>
                  </Col>
                    <Col sm={10}>
                    <div className="waitingOndiv">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.Blocking.map((e,index)=>{
                          let type=e.value.split('_');
                          let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRendertaskdiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>
                          {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                          <div style={{cursor: 'pointer'}} onClick={()=>{
                          openTaskFromDependencies(e);
                         }}>
                            {e.label}
                        </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            handleDeleteDependency(e); 
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>}
                         )
                      }
                     </div>
                     {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
                    { dropdowncount.Blocking==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Blocking'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.Blocking];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
                      // setDropdownTasksData(data?.filter(t=>t.value != e))
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies,viewTaskData.current)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
            </div>
          </div>
        </AntModal>
    );
  };
  
  const taskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              width:'100%',
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              viewTaskFunction(data, "TASK");
            }}
          >
            <Col
              id={`TaskId-${data.TaskId}`}
              className="Expand-Icon-Section"
              onClick={(e) => toggleExpandIconOfSubtask(e)}
              sm={1}
            >
              {data?.SubTasks?.length > 0 && (
                <>
                  <CaretRightOutlined className="LEFT SHOW" />
                  <CaretDownOutlined className="RIGHT HIDE" />
                </>
              )}
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
                gap: "10px",
              }}
            >
               <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
                <span
                  className="Elipsis-Class"
                  style={{ width: "150px" }}
                >
                  {data[field]}
                </span>
              </Tooltip>
              <Tooltip color="rgb(54 54 54)" placement="top" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 

                <div
                  className="dependencyicon"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    viewTaskData.current=data;
                    let pipeline=allPipelineData.filter(pid=>pid.PipelineROWID==data?.PipelineROWID)[0];
                    setSelectedDeal(pipeline?.DealName);
                    setIsDependencyModalOpen(true)
                    setSelectedDependenciesCache(data.deps);
                    }}
                >
                  {data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                    <img
                      className="minus-circle-v"
                      src={minusCircle}
                    />
                  ) : data?.deps.Blocking.length > 0 &&
                    data?.deps.WaitingOn.length == 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      color={"#f40600"}
                    />
                  ) : data?.deps.Blocking.length == 0 &&
                    data?.deps.WaitingOn.length > 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      color={"#FFA500"}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Tooltip>
              {getDescriptionPopover(data)}
              {data?.SubTasks?.length > 0 ? (
                <div 
                onClick={(e)=>{
                  e.stopPropagation();
                  let classes = e.currentTarget.id;
                  if(classes){
                    document.getElementById(classes).click();
                  }                    
                }} 
                style={{width: "1rem"}} 
                id={`TaskId-${data.TaskId}`}
                >
                  <Badge
                  color="#7a7a7a"
                  count={data?.SubTasks?.length}
                  className="subtask-badge"
                  size="small"
                  offset={[3, 0]}
                >
                  <img src={Vector} alt="subtask-icon" className="subtask-icon"/>
                </Badge>
                </div>) : (
                <></>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={assignee ? assignee : ""}
              mode="multiple"
              onChange={(e) => {
                updateTaskData(data,"Assignee",e)
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Assignee","")}
            />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={Supervisor ? Supervisor : ""}
              mode=""
              onChange={(e) => {
                updateTaskData(data,"Supervisor",e)
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Supervisor","")}
            />
       );
      },
    },
    {
      label: "Time Tracking",
      field: "TimeTracking",
      render: (data, field) => {
        let records = getRelatedTimeRecords(data,"Tasks");       
        return ( 
          <div className="TaskGroupCard-Time-Track" style={{position:"relative"}}>
            <TimeTrackerForList 
              updated={updated}
              setUpdated={setUpdated}
              anotherSessionActiveFlag={anotherSessionActiveFlag}
              records={records} 
              viewTaskData={data}  
              allTasksAndSubtasks={allTasksAndSubtasks}
              viewTaskFunction={viewTaskFunction}
              handleHistoryAdd={()=>{}}
              />  
          </div>
        )
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <Tooltip color="rgb(54 54 54)" placement="top" title={description}>
            <span
              className="Elipsis-Class"
              style={{ width: "400px" }}
            >
              {description != "null" && description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (<>
        <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
          <Select
            className="No-Border priority-icon"
            name="Priority"
            placeholder="Select an option"
            style={{ width: 200 }}
            value={data[field] ? data[field] : "Not Set"}
            onChange={(val) => {
              updateTaskData(data,"Priority",val)
            }}
            rootClassName="table-dropdown"
            dropdownRender={(menu) => <>{menu}</>}
            optionLabelProp="icon"
            onClear={()=> updateTaskData(data,"Priority","")}
          >
            <Option
              value="Not Set"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
              Set
            </Option>
            <Option
              value="Low"
              icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
            </Option>
            <Option
              value="Medium"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
              Medium
            </Option>
            <Option
              value="High"
              icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("High") }} /> High
            </Option>
          </Select>
        </Tooltip>
          </>);
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          <CustomTimeSelect 
            rootClassName={"Task-Table-TimePicker"}
            className={(data[field] != "null" && data[field]) ? "Task-Table-TimePicker-Class" : "Task-Table-TimePicker-Class1"}
            placement={"top"}
            taskData={data}
            setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field])}}
          />
        );
      },
    },
    {
      label: "Status",
      field: "Status",
      render: (data, field) => {
        return data[field];
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == data.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    },
    // {
    //     label: "Is Milestone",
    //     field: "IsMilestone",
    //     render: (data, field) => {
    //         return data[field]=="false"?"No":"Yes";
    //     }
    // },
    // {
    //   label: "Action",
    //   field: "Action",
    //   render: (data, field) => {
    //     return actionListCellRenderer(data, "task");
    //   },
    // },
  ];
  const subTaskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              viewTaskFunction(data, "SUBTASK");
              setIsSubTaskView(true);
            }}
          >
            <Col sm={1}></Col>
            <Col sm={1}></Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
              }}
            >
            <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
             <span className="Elipsis-Class" style={{ width: "130px" }}>{data[field]}</span>
            </Tooltip>
            <Tooltip color="rgb(54 54 54)" placement="top" title={`
                  ${data.deps.Blocking.length > 0 ? `Blocking: ${data.deps.Blocking.length}` : ''} 
                  ${data.deps.WaitingOn.length > 0 ? `Waiting: ${data.deps.WaitingOn.length}` : ''}
                `}> 
              <div
                className="dependencyicon"
                onClick={(e) => {
                  e.stopPropagation();
                  viewTaskData.current=data;
                  let data2=allTasksAndSubtasks['Tasks']?.get(data?.TaskRowId); 
                  let pipeline=allPipelineData.filter(pid=>pid.PipelineROWID==data2?.PipelineROWID)[0];
                  setSelectedDeal(pipeline?.DealName);
                  setIsDependencyModalOpen(true);
                  setSelectedDependenciesCache(data.deps);
                }}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {}}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {}}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={assignee ? assignee : ""}
              mode="multiple"
              onChange={(e) => {
                updateTaskData(data,"Assignee",e,"subtask")
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Assignee","","subtask")}
            />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
            <UserPicker
              rootClassName={"table-dropdown"}
              className={"No-Border"}
              values={Supervisor ? Supervisor : ""}
              mode=""
              onChange={(e) => {
                updateTaskData(data,"Supervisor",e,"subtask")
              }}
              placeholder={"Select Option"}
              onClear={()=> updateTaskData(data,"Supervisor","","subtask")}
            />
        );
      },
    },
    {
      label: "Time Tracking",
      field: "TimeTracking",
      render: (data, field) => {
        let records = getRelatedTimeRecords(data,"SubTasks");
        return ( 
            <TimeTrackerForList 
              updated={updated}
              setUpdated={setUpdated}
              anotherSessionActiveFlag={anotherSessionActiveFlag}
              records={records} 
              viewTaskData={data}  
              allTasksAndSubtasks={allTasksAndSubtasks}
              viewTaskFunction={viewTaskFunction}
              handleHistoryAdd={()=>{}}
              />  
          )
        // let description = data[field];
        // return (
        //   <span
        //     title={description}
        //     className="Elipsis-Class"
        //     style={{ width: "400px" }}
        //   >
        //     {description}
        //   </span>
        // );
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <Tooltip color="rgb(54 54 54)" placement="top" title={description}>
            <span
              title={description}
              className="Elipsis-Class"
              style={{ width: "400px" }}
            >
              {description != "null" && description}
            </span>
          </Tooltip>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (
        <Tooltip color="rgb(54 54 54)" placement="top" title={data[field]}>
            <Select
              className="No-Border priority-icon"
              name="Priority"
              placeholder="Select an option"
              style={{ width: 200 }}
              value={data[field] ? data[field] : "Not Set"}
              onChange={(val) => {
                updateTaskData(data,"Priority",val,"subtask")
              }}
              // suffixIcon={<CaretDownFilled />}
              dropdownRender={(menu) => <>{menu}</>}
              optionLabelProp="icon"
              rootClassName={"table-dropdown"}
              onClear={()=> updateTaskData(data,"Priority","","subtask")}
            >
              <Option
                value="Not Set"
                icon={
                  <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
                }
              >
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
                Set
              </Option>
              <Option
                value="Low"
                icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
              >
                <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
              </Option>
              <Option
                value="Medium"
                icon={
                  <FlagFilled style={{ color: getPriorityValue("Medium") }} />
                }
              >
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
                Medium
              </Option>
              <Option
                value="High"
                icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
              >
                <FlagFilled style={{ color: getPriorityValue("High") }} /> High
              </Option>
            </Select>
          </Tooltip>
        );
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          <CustomTimeSelect 
            rootClassName={"Task-Table-TimePicker"}
            className={"Task-Table-TimePicker-Class"}
            placement={"top"}
            taskData={data}
            setTaskData={(dt)=>{updateTaskData(dt,"TimeEstimate", dt[field],"subtask")}}
          />
        );
      },
    },
    {
      label: "Status",
      field: "Status",
      render: (data, field) => {
        return data[field];
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field, task) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == task.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    },
    // {
    //     label: "Is Milestone",
    //     field: "IsMilestone",
    //     render: (data, field) => {
    //         return <></>;
    //     }
    // },
    // {
    //   label: "Action",
    //   field: "Action",
    //   render: (data, field) => {
    //     // console.log("data SubTaskROWID", data);
    //     return actionListCellRenderer(data, "subtask");
    //   },
    // },
  ];
  
  const [taskHeaders, setTaskHeaders] = useState(taskHeadr);
  const [subTaskHeaders, setSubTaskHeaders] = useState(subTaskHeadr);
  useEffect(() => {
    if (taskHeadersData.length > 0) {
      setTaskHeaders(taskHeadersData);
      let d = taskHeadersData.filter((e, index) => index != 0);
      d.splice(0, 0, subTaskHeadr[0]);
      setSubTaskHeaders(d);
    }
  }, [taskHeadersData]);
  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if(dropIndex==0 || draggedIndex==0){
      return
    }
    const newHeaders = [...taskHeaders];
    const [movedHeader] = newHeaders.splice(draggedIndex, 1);
    newHeaders.splice(dropIndex, 0, movedHeader);
    setTaskHeadersData(newHeaders);
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());  // Returns true if it's a valid date
  };

  const getTabCard = () => {
    if (groupFilter.group2.name == "Pipeline") {
      let pipeline = allPipelineData.find((e) => e.PipelineROWID == header);
      return (<div className="Group-Titel-Card">
        
          <span style={{ fontSize: "11px" }}>
            {pipeline?.PipelineName ? pipeline.PipelineName : "N/A"}
          </span>
        </div>);
    } else if (
      groupFilter.group2.name == "Assignee" ||
      groupFilter.group2.name == "Supervisor"
    ) {
      let user = AllUsers.find((e) => e.zuid == header);
      return (<div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>
            {SelectedUserAvatar({ label: user?.full_name, value: user?.zuid })}
          </span>
        </div>);
    } else if (groupFilter.group2.name == "Priority") {
      let color = getPriorityValue(header);
      return (
        header && (
          <div
            style={{ backgroundColor: color, color: invertColor(color, true) }}
            className="Group-Titel-Card"
          >
            <span style={{ fontSize: "11px" }}>{header}</span>
          </div>)
      );
    } else {
      return (
        header != "undefined" && header ? <div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>{isValidDate(header) ? convertToUSDate(header) : header}</span>
        </div> : 
        <span style={{ fontSize: "11px", marginLeft: "7rem" }}>Task Name</span>
        )
    }
  };
  const getTaskHeaders = () => {
    return taskHeaders.map((col, index) => {
      return (
        <th
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, index)}
          className={`${
            index === 0
              ? "min-width-250 task_table_header task-card-title"
              : col.label === "Assignee" ||
                col.label === "Supervisor" ||
                col.label === "Priority"                 
              ? "column-header min-width-100" :
              col.label === "Time Estimate" ?
              "column-header min-width-120"
              : "column-header"
          }`}
          onClick={() => sortData(col.field)} // Add onClick for sorting
        >
          {index === 0 ? getTabCard() : col.label}
          {renderSortIcon(col.field)}
          {index > 0 && <FontAwesomeIcon icon={faGripVertical} className="dragOptionIcon" />}
        </th>
      );
    });
  };
  const getTaskRow = (data) => {
    return taskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            data?.SubTasks?.length > 0 && index == 0 ? "connecting-task-1" : ""
          }`}
        >
          <div style={{width:'2.5em'}} 
          className={selectedIds.Task.includes(data.TaskROWID)?'':"checkBoxClass"} 
          onClick={(e)=>e.stopPropagation()}>
          {index==0 ? 
            <Checkbox 
                checked={selectedIds.Task.includes(data.TaskROWID)} 
                className='Individual-Checkbox'
                // style={{opacity: '0.7'}}
                onClick={(e)=>{
                  setSelectedIds((prev) => ({
                  ...prev,
                  Task: prev.Task.includes(data.TaskROWID)
                    ? prev.Task.filter(id => id !== data.TaskROWID)  
                    : [...prev.Task, data.TaskROWID],  
                })); 

                const dynamicKey = `${key1}_${header}`;  // Construct the dynamic key

              // Safeguard: Initialize relevantArray as an empty array if undefined
              const relevantArray = Array.isArray(dummyId[dynamicKey]) ? dummyId[dynamicKey] : []; 

              // Update dummyId state dynamically
              if (relevantArray.includes(data.TaskROWID)) {
                setDummyID(prev => ({
                  ...prev,
                  [dynamicKey]: relevantArray.filter(id => id !== data.TaskROWID), // Remove ID from dummyId
                }));
              } else {
                setDummyID(prev => ({
                  ...prev,
                  [dynamicKey]: [...relevantArray, data.TaskROWID], // Add ID to dummyId
                }));
              }
              }}
          />
           :
           ''}
          </div>
          {col.render(data, col.field)}
        </td>
      );
    });
  };
  const getSubTaskRow = (data, task) => {
    return subTaskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            index == 0 ? "connecting-task-2" : ""
          }`}
        >
          <div style={{width:'2.5em'}} 
          className={selectedIds.Subtask.includes(data.SubTaskROWID)?'':"checkBoxClass"} 
          onClick={(e)=>e.stopPropagation()}>
          {index==0 ? 
          <Checkbox 
              checked={selectedIds.Subtask.includes(data.SubTaskROWID)} 
              className='Individual-Checkbox'
              // style={{opacity: '0.7'}}
              onClick={()=>setSelectedIds((prev) => ({
              ...prev,
              Subtask: prev.Subtask.includes(data.SubTaskROWID)
                ? prev.Subtask.filter(id => id !== data.SubTaskROWID) 
                : [...prev.Subtask, data.SubTaskROWID], 
            }))}
          />
           :
           ''}
          </div>
          {col.render(data, col.field, task)}
        </td>
      );
    });
  };
  const getSubtaskCollapse = (subtasks, task) => {
    return subtasks.map((sub, i) => {
      let deps = getAllDependenciesForTask(true, sub);
      sub = { ...sub, deps: deps };
      return (<>
      {/* <div className="subtask-parent-name">{(taskFilterSetting.SubtaskParntNames) ? showSubtaskParentNames(sub) : ""}</div> */}
        <tr
          className={`showCheckBox TaskId-${task.TaskId} ${
            taskFilterSetting.ExpandTasks ? "" : "HIDEROW"
          } `}
        >
          {getSubTaskRow(sub, task)}
        </tr>
        </>);
    });
  };
  const getAllDependenciesForTask = (subtask, data) => {
    let sourceTaskType = subtask ? "SubTasks" : "Tasks";
    let ROWID = sourceTaskType == "Tasks" ? data.TaskROWID : data.SubTaskROWID;
    const result = allDependeniesData?.filter(
      (e) => e.SourceTaskId == ROWID || e.TargetTaskId == ROWID
    );
    // setResultFromGetDependency(result);
    if (result.length > 0) {
      // let dropdowndata = [...dropdownTasksDataCache];
      let obj = {
        WaitingOn: new Set(),
        Blocking: new Set(),
      };
      const dropdownMap = dropdowndata;

      allPipelineData?.forEach((pipeline) => {
        pipeline.Tasks?.forEach((task) => {
          const tDataValidate = result.find(
            (e) =>
              e.TargetTaskId == ROWID &&
              e.SourceTaskId == task.TaskROWID &&
              e.STaskType == "Tasks"
          );
          let tData = undefined;
          if (tDataValidate) {
            tData = tDataValidate;
          } else {
            tData = result.find(
              (e) => e.TargetTaskId == task.TaskROWID && e.TaskType == "Tasks"
            );
          }
          let flag = true;
          if (tData?.SourceTaskId == ROWID) {
            flag = tData.STaskType == sourceTaskType;
          }
          // const tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
          if (tData && flag) {
            const type =
              tData.TargetTaskId == ROWID && tData.DependancyType == "WaitingOn"
                ? "Blocking"
                : tData.TargetTaskId == ROWID &&
                  tData.DependancyType == "Blocking"
                ? "WaitingOn"
                : tData.DependancyType;
            if (tData.TargetTaskId == ROWID) {
              const tmp = dropdownMap.get(
                `${tData.STaskType}_${tData.SourceTaskId}`
              );
              if (tmp && sourceTaskType == tData.TaskType) {
                obj[type].add(JSON.stringify({ ...tmp }));
              }
            } else {
              obj[type].add(
                JSON.stringify({
                  label: task.TaskName,
                  value: `Tasks_${task.TaskROWID}`,
                })
              );
            }
          }
          task.SubTasks?.forEach((subtask) => {
            const tDataValidate = result.find(
              (e) =>
                e.TargetTaskId == ROWID &&
                e.SourceTaskId == subtask.SubTaskROWID &&
                e.STaskType == "SubTasks"
            );
            let tData2 = undefined;
            if (tDataValidate) {
              tData2 = tDataValidate;
            } else {
              tData2 = result.find(
                (e) =>
                  e.TargetTaskId == subtask.SubTaskROWID &&
                  e.TaskType == "SubTasks"
              );
            }
            //   const tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
            if (tData2) {
              const type2 =
                tData2.TargetTaskId == ROWID &&
                tData2.DependancyType == "WaitingOn"
                  ? "Blocking"
                  : tData2.TargetTaskId == ROWID &&
                    tData2.DependancyType == "Blocking"
                  ? "WaitingOn"
                  : tData2.DependancyType;
              if (tData2.TargetTaskId == ROWID) {
                const tmp2 = dropdownMap.get(
                  `${tData2.STaskType}_${tData2.SourceTaskId}`
                );
                if (tmp2 && sourceTaskType == tData2.TaskType) {
                  obj[type2].add(JSON.stringify({ ...tmp2 }));
                }
              } else {
                obj[type2].add(
                  JSON.stringify({
                    label: subtask.TaskName,
                    value: `SubTasks_${subtask.SubTaskROWID}`,
                  })
                );
              }
            }
          });
        });
      });
      let obj2 = {
        WaitingOn: Array.from(obj.WaitingOn).map((item) => JSON.parse(item)),
        Blocking: Array.from(obj.Blocking).map((item) => JSON.parse(item)),
      };
      return obj2;
    } else {
      return { WaitingOn: [], Blocking: [] };
    }
  };
  return (
    <Col sm={12} className="TaskGroup2Card">
      {DependecyModal()}
      <Row sm={12}>
        <table className="table task-table task-content-table table-hover">
          <thead className="showCheckBox">{getTaskHeaders(header)}</thead>
          <tbody>
            {rowData?.map((row, index) => {
              let deps = getAllDependenciesForTask(
                row.hasOwnProperty("SubTaskROWID"),
                row
              );
              row = { ...row, deps: deps };
              return (
                <>
                  {taskFilterSetting &&
                  taskFilterSetting.TaskLocation
                    ? showTaskLocationNames(row)
                    : ""}
                  <tr className="showCheckBox">{getTaskRow(row)}</tr>
                  {row?.SubTasks?.length > 0 &&
                    getSubtaskCollapse(row.SubTasks, row)}
                </>
              );
            })}
          </tbody>
        </table>
      </Row>
    </Col>
  );
};
