import React, { useRef } from 'react'
import { Col, Row } from 'reactstrap'
import { invertColor } from '../../../components/HR/TimeClock/Util';
import { Button, Popover } from 'antd';
import './TaskFlowStatus.css'
import { ButtonOverflow } from './ButtonOverflow';
export const TaskFlowStatus = ({
    statusOptions,
    onChangeStatus,
    taskData,
    transitions,
}) => {
    const containerRef = useRef(null);
    const getStyleObj = (status) => {
        let temp = statusOptions?.find(k => k.label == status);
        let sty = {
            backgroundColor: "white",
            color: "black",
            border: "1px solid white"
        }
        if (temp && temp.color) {
            sty["backgroundColor"] = temp.color;
            sty["border"] = `1px solid ${temp.color}`
            sty["color"] = invertColor(temp.color, true)
        }
        return sty;
    }
    const getStatusDropdown = (data, listtype) => {

        // let arr=[];
        // if(listtype=="reject"){
        //   arr=statusOptions?.filter(one=>one.color=="red")
        // }else{
        //   arr=statusOptions?.filter(one=>one.color!="red")
        // }
        const content = (
            <div className="Task-Status-Div">
                {statusOptions?.map((one) => {
                    return (
                        <p
                            style={getStyleObj(one.label)}
                            className="Task-Status-P"
                            onClick={() => {
                                // onSelect(one);
                                // onChangeTransion(one.label)
                                onChangeStatus(one);
                                // setSelected(!selected);
                            }}
                        // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
                        >
                            {one.label}
                        </p>
                    );
                })}
            </div>
        );
        if (statusOptions.length == 0) {
            return (<Button
                type="primary"
                disabled={true}
                style={{
                    opacity: 0.5,
                    backgroundColor: "#1677ff",
                    borderColor: "#1677ff",
                    color: "white",
                }}
                // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
                size="middle"
            >No Task Flow</Button>)
        }
        return (
            <Popover
                style={{ zIndex: 9999 }}
                // open={true}
                onOpenChange={(e) => {
                    // setIsOpen(!isOpen);
                }}
                trigger="click"
                // overlayClassName={"Prject-Status-Container" }
                placement="bottomLeft"
                content={content}
                arrow={false}
            >
                <Button
                    type="primary"
                    style={getStyleObj(taskData.TaskStatus)}
                    // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
                    size="middle"
                >{taskData.TaskStatus}</Button>
            </Popover>
        )

    }
    
    return (
        <div className='Status-Wrapper'>
            <div className='Main-Status-Button' style={{ padding: "0px",display:"flex" ,width:"max-content" }}>
                {getStatusDropdown()}
            </div>
            <Col className='Button-Wrapper' style={{ padding: "0px" }} sm={11}>
                <ButtonOverflow
                    // wrapperRef={containerRef}
                    buttons={transitions}
                    onClickButton={(data)=>{onChangeStatus(data)}}
                />
            </Col>
        </div>
    )
}