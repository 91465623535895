import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TaskGroupCardForTemplate } from "../../../components/ProjectTasks/TaskGroupCardForTemplate";
import AddInlineData from "../../../components/ProjectTasks/AddInlineData";
import { GlobalSearch } from "../../../components/GlobalSearch/GlobalSearch";
import swal from "sweetalert";
import AppContext from "../../../Context/AppContext";
import BottomBar from "../../../components/ProjectTasks/BottomBar";


const TaskListTab = ({
  viewTaskFunction,
  groupFilter,
  setOpenTaskModal,
  pipelineData,
  isSubTaskView,
  setIsSubTaskView,
  setPipelineData,
  refreshData, 
  setPageRefreshData,
  allPipelineData,
  selectedTable
}) => {
  let user = JSON.parse(localStorage.getItem("userinfo"));
  const [isAdding, setIsAdding] = useState(false);
  const [searchTaskList, setSearchTaskList] = useState('')
  const [selectedIdsForTemp, setSelectedIdsForTemp] = useState({Task:[],Subtask:[]});
  const [subEditData, setSubEditData] = useState({
    id: Date.now(),
    Assignee: "",
    Priority: "",
    CREATEDBY: user.email_id,
    Supervisor: "",
    TaskDescription: "",
    TaskName: "",
    PipelineROWID: "",
    TimeEstimate: "",
  });
  const AppData = useContext(AppContext);
  const { state } = AppData || {};
  const { isPipelineModalClosed } = state;

  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    if (pipelineData) {
      if(searchTaskList){
        const filtered = pipelineData.Tasks.filter(task =>
          task.TaskName.toLowerCase().includes(searchTaskList.toLowerCase())
        );
        setFilteredTasks(filtered);
      }else{
        setFilteredTasks(pipelineData?.Tasks ? pipelineData?.Tasks : []);
      }
    }
  }, [searchTaskList, pipelineData?.Tasks, pipelineData]);

  useEffect(
    (e) => {
      if (pipelineData) {
        setSubEditData({
          ...subEditData,
          PipelineROWID: pipelineData?.PipelineROWID || pipelineData?.ROWID,
        });
      }
    },
    [pipelineData]
  );

  useEffect((e) => {
    setIsAdding(false);
    if(isPipelineModalClosed){
      setSelectedIdsForTemp({Task:[],Subtask:[]})
    }
  },
  [isPipelineModalClosed]
);

  const handleAddRow = () => {
    setIsAdding(true);
    let updatedTasks = [...(pipelineData?.Tasks || []), subEditData];
    setPipelineData({ ...pipelineData, Tasks: updatedTasks });
  };

  // Save the data (simulate API call)
  const handleSave = () => {
    // Set a flag to true for subEditData
    if(!subEditData?.TaskName){
      swal('Alert','Task Name can not be empty!',"warning")
      return
    }

    subEditData["flag"] = true;

    // Find the index of the object with the same id in pipelineData.Tasks
    const existingIndex = pipelineData?.Tasks.findIndex(
      (item) => item.id === subEditData.id
    );

    if (existingIndex !== -1) {
      // Update the existing object with subEditData
      const updatedTasks = [...pipelineData.Tasks];
      updatedTasks[existingIndex] = {
        ...updatedTasks[existingIndex],
        ...subEditData,
      };

      // Update pipelineData with the new Tasks array
      setPipelineData({
        ...pipelineData,
        Tasks: updatedTasks,
      });
    } else {
      // Add new object if no matching id is found
      setPipelineData({
        ...pipelineData,
        Tasks: [...pipelineData.Tasks, subEditData],
      });
    }

    // Reset subEditData to default values
    setSubEditData({
      id: Date.now(),
      Assignee: "",
      Priority: "",
      CREATEDBY: user.email_id,
      Supervisor: "",
      TaskDescription: "",
      TaskName: "",
      TimeEstimate: "",
    });
    setIsAdding(false);
  };

  return (
    <>
      <div className="add-row-btn">
        <Button
          disabled={!pipelineData?.PipelineName}
          onClick={() => {
            setOpenTaskModal(true);
          }}
        >
          Add Task
        </Button>
      </div>
      <div className="TasksList-Table">
          <div style={{display : "flex", marginLeft: "5px", position:"sticky", left:"5px"}}>
                <GlobalSearch
                  text={searchTaskList}
                  onSearchFilterTexts={(text) =>
                  setSearchTaskList(text)
                }
              />
              <AddInlineData
                onAdd={(e) => {
                  if(searchTaskList){
                    return
                  }
                  handleAddRow();
                }}
                onSave={(e) => {
                  handleSave();
                }}
                isAdding={isAdding}
                name={"Add Task"}
              />
          </div>
        <TaskGroupCardForTemplate
          module={""}
          viewTaskFunction={viewTaskFunction}
          groupFilter={groupFilter}
          allPipelineData={allPipelineData}
          data={filteredTasks}
          header={"Task Name"}
          isSubTaskView={isSubTaskView}
          setIsSubTaskView={setIsSubTaskView}
          editData={subEditData}
          setEditData={setSubEditData}
          refreshData={refreshData}
          setPageRefreshData={setPageRefreshData}
          selectedIdsForTemp={selectedIdsForTemp}
          setSelectedIdsForTemp={setSelectedIdsForTemp}
        />
        {(selectedIdsForTemp?.Task?.length > 0 || selectedIdsForTemp?.Subtask?.length > 0) && 
          <BottomBar 
            selectedIds={selectedIdsForTemp}
            setSelectedIds={setSelectedIdsForTemp} 
            setPageRefreshData={setPageRefreshData} 
            refreshData={refreshData}
            selectedTable={selectedTable}
            subTaskData={filteredTasks}
            setSubTaskData={setFilteredTasks}
            setIsAdding={setIsAdding}
            />
        }
      </div>
    </>
  );
};

export default TaskListTab;
