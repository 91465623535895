import React, { useContext, useEffect, useState } from "react";
import {
  Modal as AntModal,
  Button as AntButton,
  Select as AntSelect,
  Input,
  Popover,
  Tabs,
  Button,
  Badge,
  theme,
  Select,
  Tooltip,
  Checkbox,
} from "antd";
import "./CreateTaskModal.css";
import { Row, Col } from "reactstrap";
// import { Col } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faUser,
  faClock,
  faMinusCircle,
  faFolder,
  faCircle,
  faTrash,
  faCaretUp,
  faCaretDown,
  faPaperclip,
  faCodeBranch,
  faArrowLeft,
  faTimes,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "react-date-range";
import { CaretDownFilled, CheckCircleOutlined, CloseOutlined, FlagFilled, MinusCircleFilled, } from "@ant-design/icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { UserPicker } from "../../common/UserPicker/UserPicker";
import { AppDataRequired } from "../../App";
import SubTaskTab from "./AddTaskTabs/SubTaskTab";
import { Checklist } from "./AddTaskTabs/Checklist";
import { ActivitySection } from "./AddTaskTabs/ActivitySection";
import TextArea from "antd/es/input/TextArea";
import { CustomeCollapse } from "./AddTaskTabs/CustomeCollapse";
import AppContext from "../../Context/AppContext";
import AwsServerService from "../../common/util/AwsServerService";
import _ from 'lodash';
import swal from "sweetalert";
import {convertToUSTime,getPriorityValue, invertColor,} from "../../components/HR/TimeClock/Util";
import { Option } from "antd/es/mentions";
import { number } from "mathjs";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import TimeTracker from "../../common/TimeTracker/TimeTracker";
import {getNextStepOfFlow} from '../../components/ProjectTasks/Util'
import AttachmentModal from "./attachmentModal";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import axios from "axios";
import { findCircularConnection } from "./TaskUtil";
import SettingIconDark from '../../assets/images/svg/SettingIconDark.svg'
import { TaskFlowStatus } from "./TaskStatus/TaskFlowStatus";
import DependencyModal from "./ViewPipelinesTabs/DependencyModal";

const getItems = (panelStyle, text) => [
  {
    key: "1",
    label: "Description",
    children: <TextArea value={text} placeholder="Description.." />,
    style: panelStyle,
  },
];
const CustomeTaskField = ({ name, icon, children }) => {
  return (
    <Row className="Custom-Field-Row" style={{ opacity: "0.85" }} sm={12}>
      <Col style={{ padding: "0px" }} sm={4}>
        <Row sm={12}>
          <Col style={{ padding: "0px" }} sm={2}>
            {icon}
          </Col>
          <Col style={{ padding: "0px 3px", fontWeight: "500" }} sm={10}>
            {name}
          </Col>
        </Row>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

const CreateTaskModal = ({
  open,
  setOpen,
  viewTaskData,
  taskModalState,
  viewPipelineData,
  refreshData,
  setPageRefreshData,
  createPiplineModalState,
  selectedTable,
  isSubTaskView,
  setIsSubTaskView,
  allPipelineData,
  viewTaskFunction,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  allBlueprintsData,
  dependencyRefresh,
  setDependencyRefresh
}) => {
  const backendUrl = "https://catly-795086611.catalystserverless.com/server/apiservice/";
  const { token } = theme.useToken();
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  // const [isSubTaskView,setIsSubTaskView] = useState(false);
  const [accountSelected, setAccountSelected] = useState();
  const [dealSelected, setDealSelected] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [blueprintData, setBlueprintData] = useState({});
  const [taskHistory, setTaskHistory] = useState([]); 
  const [statusOptions, setStatusOptions] = useState([]);
  const [mainStatusOptions, setMainStatusOptions] = useState([]);
  const [currentKey, setCurrentKey] = useState();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [relativePathId, setRelativePathId] = useState(null);
  const [filteredDropdownTasksData,setFilteredDropdownTasksData]=useState([]);
  const [isSubTaskViews, setIsSubTaskViews] = useState(false);
  // const [dependencyRefresh, setDependencyRefresh] = useState(false)
    ////// Attachment Functions /////

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleAttachementRemove = (one) => {
      let tempFileCollection = [...fileNames];
      tempFileCollection = tempFileCollection.filter((file) => file.fileName !== one.fileName);
      setFileNames(tempFileCollection);
    }
      
    const uploadFiles = (arr) => {
      let doArr = []
      let fileUploadArr = []
      let names = []
      let tempFileCollection = [...fileNames];
      tempFileCollection.forEach((obj) => {
        obj.Status = "Pending"
      })
      arr.forEach((one) => {
        names.push(one.fileName);
        // doArr.push(addZohoTaskAttachements(taskData.project_id, taskData.id_string, one))
        fileUploadArr.push(handleUploadFileWorkDrive(one))
      })
  
      // setProgress({ active: true, percent: 1 })
      // let startInterval = setInterval(() => {
      //   setProgress((prev) => {
      //     if (prev && prev.percent < 100) {
      //       prev.percent = prev.percent + 1;
      //       setProgress(prev);
      //     }
      //   })
      // }, 1)
      Promise.all(doArr)
        .then((result) => {
  
          console.log(result);
  
          return Promise.all(fileUploadArr)
        })
        .then((result) => {
          arr.forEach((one) => {
            one.Status = "Success"
          })
  
          // setProgress({ active: true, percent: 100 });
          let filterTemp = tempFileCollection.filter(e => e.Status != "Success")
          // setLoader(false);
          setFileNames(filterTemp);
          // clearInterval(startInterval);
          // setProgress({ active: false, percent: 0 });
          alert("file upload successfully");
          // setAttachmentModal(false)
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        })
  
      // let text = `You upload ${tempFileCollection.length} files `
      // let text = `You upload one file `
      // let filename = names.join(' , ')
      // swal({
      //   title: "Are you sure?",
      //   text: text + filename,
      //   icon: "warning",
      //   buttons: [
      //     'No',
      //     'Yes'
      //   ],
      //   dangerMode: true,
      // }).then(function (isConfirm) {
      //   if (isConfirm) {
  
      //   }
      // })
  
    }

    const handleUploadFiles = () => {
      // let link = document.createElement('input');
      // link.setAttribute('type', 'file');
      // link.setAttribute('name', 'uploadFiles');
      // link.setAttribute('multiple', "multiple");
      // document.body.appendChild(link);
      // link.click();
      // link.onchange = function (e) {
      //   // let formData = new FormData();
      //   // formData.append('uploaddoc', e.currentTarget.files[0])
      //   // updateZohoTask(formData)
      //   handleFileAttachments(e.currentTarget.files);
      // }
  
      // document.body.removeChild(link);
    }
   
  const handleUploadFileWorkDrive = (fileObj) => {
    return new Promise((resolve, reject) => {
      if (relativePathId) {
        var config = {
          Type: "Upload",
          data: {
            filename: fileObj.fileName,
            content: fileObj.file,
            parent_id: relativePathId,
          },
          url: "https://www.zohoapis.com/workdrive/api/v1/upload",
          method: "POST",
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        };
        axios.post(backendUrl + "workdrive", { config: config })
          .then(async function (response) {
            // ActivitieService.AddActivityLocal('Workdrive', { DealId: props.dealObj.Project_ID }, { action_type: 'Upload', data_type: 'File', data: { name: fileObj.fileName, path: relativeFolderId, pathId: relativePathId } })
            // resolve(response)
            // console.log("-------------------------files data-----------------------------------")

          })
          .catch(function (error) {
            reject(error);
          });
      }


    })

  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...fileNames];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    setFileNames(tempFileCollection);
    // let doArr = []
    // let fileUploadArr = []
    // let names = []
    // tempFileCollection.forEach((one) => {
    //   names.push(one.fileName);
    //   doArr.push(addZohoTaskAttachements(props.dealObj.Project_ID, taskData.id_string, one))
    //   fileUploadArr.push(handleUploadFileWorkDrive(one))
    // })

    // let text = `You upload ${tempFileCollection.length} files `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     setLoader(true);
    //     Promise.all(doArr)
    //       .then((result) => {
    //         console.log(result);
    //         return Promise.all(fileUploadArr)
    //       })
    //       .then((result) => {
    //         setLoader(false);
    //         alert("file upload successfully");
    //         setAttachmentModal(false)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //   }
    // })


  };
    ////// Attachment Functions /////

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const taskEmptyTemplate = {
    TaskName: "",
    TaskDescription: "",
    Priority: "Not Set",
    Assignee: "",
    Supervisor: "",
    PipelineROWID: "",
    DueDate: new Date(),
    StartDate:new Date(),
    Account: "",
    Project: "",
    TaskTemplateId: "",
    TaskStatus:"Not Started",
    // TaskFlowId:'1',
    TimeEstimate: "",
    SubTasks: [],
    CheckList: [],
    TaskFlowObject:null
  };
  
  const [taskData, setTaskData] = useState({ ...taskEmptyTemplate });
  const { AllUsers } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [allDealsData, setAllDealsData] = useState([]);
  const [allPipelinesData, setAllPipelinesData] = useState([]);
  const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
  const [subTaskData, setSubTaskData] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  let allDealsAndUsers = state.AllDeals || [];
  let allAccounts = state.AllAccounts || [];
  const [isHovered, setIsHovered] = useState(false);
  const [statusColor, setStatusColor] = useState('#c9c2c2');
  const [comment, setComment] = useState("") 
  const [resultFromGetDependency,setResultFromGetDependency]=useState([]);
  const [dropdownTasksData,setDropdownTasksData]=useState([]);
  // const [dropdownTasksDataCache,setDropdownTasksDataCache]=useState([]);
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0,Blocking:0}); 
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]})
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  }); 
  async function handleSaveTask(data) {
    let user = JSON.parse(localStorage.getItem("userinfo"));
    const {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
    } = data;
    const payload = {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
    };
    payload["PipelineROWID"] = data.PipelineROWID;
    if(!payload?.TaskName){
      swal('Alert','Task Name can not be empty!','warning');
      return
    }
    if (taskModalState.action == "create") {
      payload["CREATEDBY"] = user.email_id;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.saveTask(payload)
            .then(async (res) => {
              setOpen(false);
              const promises = Object.keys(selectedDependencies).map(async (e) => { 
                return Promise.all(selectedDependencies[e].map(async (a) => { 
                  const [type,id]=a.value.split('_');
                  const payload={
                   TargetTaskId:id,
                   STaskType:'Tasks',
                   SourceTaskId:res[0]?.insertId,
                   TaskType:type,
                   DependancyType:e,
                   CREATEDBY:userinfo.email_id
                 } 
                 return AwsServerService.createDependency(payload);
               }));
             }); 
             await Promise.all(promises).then(r=>{
               let data={...selectedDependencies}
               setSelectedDependenciesCache(data)
               setIsDependencyModalOpen(false); 
             });
             
             setTaskData({ ...taskEmptyTemplate });
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              if(comment){
                let commentPayload = {
                  "CREATEDBY" : user.email_id,
                  "Message" : comment,
                  "TaskId" : res[0]?.insertId,
                  "TaskType" : "Task"
                }

                await AwsServerService.addComment(commentPayload).then(r=>{
                  console.log("r", r);
                }).catch(e=>{
                  console.log("error in comment", e);                  
                })

              }
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.createSubtask(payload)
            .then(async (res) => {
              // setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.saveTaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              
              // setPageRefreshData(!refreshData);
              setTaskData({ ...taskEmptyTemplate });
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.createSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    } else if (taskModalState.action == "edit") {
      payload["MODIFIEDBY"] = user.email_id;
      payload["ROWID"] = isSubTaskView
        ? taskData?.SubTaskROWID
        : taskData?.TaskROWID;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.updateTask(payload)
            .then(async (res) => {
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              // setPageRefreshData(!refreshData);
             
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtask(payload)
            .then(async (res) => {
              // setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.updateTaskInTemplate(payload)
            .then(async (res) => {
              setOpen(false);
              console.log("res", res);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              // setPageRefreshData(!refreshData);
              
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              // setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    }
    setPageRefreshData(prev => !prev);
    setDependencyRefresh(prev => !prev);
    setTaskHistory([]);
    setIsSubTaskView(false);
  } 

  useEffect(e=>{
    if(taskModalState?.action == "create"){
      setSubTaskData([])
    }
  }, [taskModalState?.action])
  
  useEffect(
    (e) => {
      if (isSubTaskView) {
        setActiveTab("2");
      } else {
        setActiveTab(activeTab);
      }
    },
    [isSubTaskView]
  );
  const openLookupFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  useEffect((e) => {
      if (viewTaskData) {
        // if(viewTaskData.hasOwnProperty('deps')){
        //   setSelectedDependenciesCache({ WaitingOn: viewTaskData.deps.WaitingOn, Blocking: viewTaskData.deps.Blocking});
        // }else{
          getAllDependenciesForTask(viewTaskData?.hasOwnProperty('SubTaskROWID'),viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID);
        // }
        if(selectedTable=='tasks'){
        //   AwsServerService.getTaskFlowById(viewTaskData.TaskFlowId).then(r=>{
        //   let data=r.data[0];
        //   setBlueprintData(data[0])
        //   getBlueprintDataOptions(data[0],viewTaskData?.TaskStatus);
        //  })
          // getBlueprintDataOptions(viewTaskData?.TaskFlowObject,viewTaskData?.TaskStatus);
        }

        let id = '';
        let param = '';
        id = isSubTaskView ? viewTaskData?.SubTaskROWID : viewTaskData?.TaskROWID
        param = isSubTaskView ? "SubTask" : "Task"

       AwsServerService.getCommentsByTaskIdAndType(id, param).then(res=>{
          // console.log("Comments res", res);
          setComment(res[0]?.Message)
        }).catch(e=>{
          console.log("error", e);
          
        })
        let subtaskPipelineId={};
        let account; 
        let pipeline = allPipelineData.find(e=>e.PipelineROWID==viewTaskData?.PipelineROWID);
        if(isSubTaskView){
          subtaskPipelineId = allPipelineData.filter(e=>e.PipelineROWID==e.Tasks?.filter(q=>q.TaskROWID==viewTaskData.TaskRowId)[0]?.PipelineROWID)[0];
          account = allDealsAndUsers?.filter(e=>e.Deal_Name==subtaskPipelineId?.DealName ? e?.Account_Name:'')
          viewTaskData = { ...viewTaskData, PipelineROWID:  subtaskPipelineId?.PipelineROWID}
        }else{
          account = allDealsAndUsers?.filter(e=>e.Deal_Name==pipeline?.DealName ? e?.Account_Name:'')
        }
        let dealName=isSubTaskView?subtaskPipelineId?.DealName:pipeline?.DealName;
          setDealSelected(dealName);
          setIsSubTaskViews(isSubTaskView)
          setAccountSelected(account[0]?.Account_Name?.name)
          
          let array = [];
          array.push({
            value: pipeline?.PipelineROWID,
            label: pipeline?.PipelineName,
            key: pipeline?.PipelineROWID,
          });
          setAllPipelinesData(array);
          setTaskData({ ...viewTaskData });
        (viewTaskData?.SubTasks && viewTaskData.SubTasks.length>0)?setSubTaskData(viewTaskData?.SubTasks):setSubTaskData([]); 
      } else {
        setTaskData({ ...taskEmptyTemplate });
        setAllPipelinesData([]);
        setSubTaskData([])
        setDealSelected();
        setAccountSelected();
        setSelectedDependenciesCache({WaitingOn:[], Blocking:[]})
        setDropdownCount({WaitingOn:0,Blocking:0})
      }
    },
    [viewTaskData,dependencyRefresh]
  );

  useEffect(()=>{ 
    if(dealSelected){
      let pipelines=allPipelineData.filter(e=>e.DealName==dealSelected);  
      const availableTaskIds = new Set(); 
      pipelines.forEach(pipeline => {
          pipeline.Tasks.forEach(pipelineTask => { 
              availableTaskIds.add(`Tasks_${pipelineTask.TaskROWID}`); 
              if (pipelineTask.SubTasks) {
                  pipelineTask.SubTasks.forEach(subtask => {
                      availableTaskIds.add(`SubTasks_${subtask.SubTaskROWID}`);
                  });
              }
          });
      }); 
     let filteredTasks= dropdownTasksDataCache.filter(task => availableTaskIds.has(task.value));
     setFilteredDropdownTasksData(filteredTasks);
    }
    },[dealSelected,isSubTaskViews])
    useEffect(()=>{ 
    if(selectedTable=='pipelines'){ 
     setFilteredDropdownTasksData(dropdownTasksDataCache);
    }
    },[selectedTable,dropdownTasksDataCache])

  useEffect(
    (e) => {
      if (viewPipelineData && viewPipelineData?.length > 0) {
        // console.log("viewPipelineData", viewPipelineData);
        let temp = { ...taskData };
        temp["PipelineROWID"] = viewPipelineData[0]?.PipelineROWID || viewPipelineData[0]?.ROWID;
        setTaskData(temp);
      }
    },
    [viewPipelineData]
  );
  function getOptionsForDropdown(data,selected){
    let options=[];
    let selectedData={...selected}
    let type=viewTaskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let id=viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID;
    const valuesToExclude = new Set([
      ...selectedData.Blocking.map(item => item.value),
      ...selectedData.WaitingOn.map(item => item.value),
      `${type}_${id}`
    ]) 
    data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
    return options;
  }
  const getBlueprintDataOptions = async (data,status)=>{
    if(data){
      let currentStatus=status; 
    let node=  data?.Nodes?.filter(e=>e.data.label==currentStatus)[0];
    let Edges=data?.Edges?.filter(e=>!e.visited && e.source==node?.id); 
    let options = [];
    let optionsStatus = [];
    Edges?.map((item) => {
      if(item.label){
        let temp={
          value: item?.label,
          label: item?.label,
          key: item?.id,
        }
        if(item?.data?.color){
          temp["color"]=item?.data?.color
        }
        options.push(temp);
      }
      }) 
      data?.Nodes?.map((item) => {
      if(item.data?.label){
        let temp={
          value: item?.data?.label,
          label: item?.data?.label,
          key: item?.id,
        }
        if(item.data?.color){
          temp["color"]=item.data.color
        }
        optionsStatus.push(temp);
      }
      }) 
      setMainStatusOptions(optionsStatus);
      setStatusOptions(options) 
  } 
    }
  const getAllDependenciesForTask = (subtask,ROWID) => { 
    if(ROWID==undefined||ROWID==null){
      return
    }
    let dropdowndata = [...dropdownTasksDataCache]; 
    let sourceTaskType=subtask?'SubTasks':'Tasks';
    if(selectedTable=='pipelines'){
      AwsServerService.getTempDependecyByTaskId(ROWID).then(r => {
        const result = r.data[0];
        // setResultFromGetDependency(result);
        if (result.length > 0) { 
          let obj = {
            WaitingOn: new Set(),
            Blocking: new Set()
          }; 
          const dropdownMap = new Map(dropdowndata.map(item => [item.value, item]));
          allPipelineData?.forEach(pipeline => {
            pipeline.Tasks?.forEach(task => {
              const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==task.TaskROWID && e.STaskType == 'Tasks'));
              let tData=undefined;
              if(tDataValidate){
              tData=tDataValidate;
              }else{
                tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
              }
              let flag=true;
              if(tData?.SourceTaskId==ROWID){
                flag=tData.STaskType==sourceTaskType
              }
              if (tData && flag) {
                const type = (tData.TargetTaskId == ROWID && tData.DependancyType == 'WaitingOn') ? 'Blocking' :
                             (tData.TargetTaskId == ROWID && tData.DependancyType == 'Blocking') ? 'WaitingOn' :
                             tData.DependancyType; 
                if (tData.TargetTaskId == ROWID) {
                  const tmp = dropdownMap.get(`${tData.STaskType}_${tData.SourceTaskId}`);
                  if (tmp && sourceTaskType==tData.TaskType) {
                    obj[type].add(JSON.stringify({...tmp}));
                  }
                } else{
                  obj[type].add(JSON.stringify({ label: task.TaskName, value: `Tasks_${task.TaskROWID}` }));
                }
              } 
              task.SubTasks?.forEach(subtask => {
                const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==subtask.SubTaskROWID && e.STaskType == 'SubTasks'));
              let tData2=undefined;
              if(tDataValidate){
                tData2=tDataValidate;
              }else{
                tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
              } 
                if (tData2) {
                  const type2 = (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'WaitingOn') ? 'Blocking' :
                                (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'Blocking') ? 'WaitingOn' :
                                tData2.DependancyType; 
                  if (tData2.TargetTaskId == ROWID) {
                    const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                    if (tmp2 && sourceTaskType==tData2.TaskType) {
                      obj[type2].add(JSON.stringify({...tmp2}));
                    }
                  } else {
                    obj[type2].add(JSON.stringify({ label: subtask.TaskName, value: `SubTasks_${subtask.SubTaskROWID}` }));
                  }
                }
              });
            });
          }); 
          setSelectedDependenciesCache({
            WaitingOn: Array.from(obj.WaitingOn).map(item => JSON.parse(item)),
            Blocking: Array.from(obj.Blocking).map(item => JSON.parse(item))
          });
        } else {
          setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
        }
      });
    }else{
    AwsServerService.getDependecyByTaskId(ROWID).then(r => {
      const result = r.data[0];
      if (result.length > 0) { 
        let obj = {
          WaitingOn: new Set(),
          Blocking: new Set()
        }; 
        const dropdownMap = new Map(dropdowndata.map(item => [item.value, item]));
        allPipelineData?.forEach(pipeline => {
          pipeline.Tasks?.forEach(task => {
            const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==task.TaskROWID && e.STaskType == 'Tasks'));
            let tData=undefined;
            if(tDataValidate){
            tData=tDataValidate;
            }else{
              tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
            }
            let flag=true;
            if(tData?.SourceTaskId==ROWID){
              flag=tData.STaskType==sourceTaskType
            }
            if (tData && flag) {
              const type = (tData.TargetTaskId == ROWID && tData.DependancyType == 'WaitingOn') ? 'Blocking' :
                           (tData.TargetTaskId == ROWID && tData.DependancyType == 'Blocking') ? 'WaitingOn' :
                           tData.DependancyType; 
              if (tData.TargetTaskId == ROWID) {
                const tmp = dropdownMap.get(`${tData.STaskType}_${tData.SourceTaskId}`);
                if (tmp && sourceTaskType==tData.TaskType) {
                  obj[type].add(JSON.stringify({...tmp}));
                }
              } else{
                obj[type].add(JSON.stringify({ label: task.TaskName, value: `Tasks_${task.TaskROWID}` }));
              }
            } 
            task.SubTasks?.forEach(subtask => {
              const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==subtask.SubTaskROWID && e.STaskType == 'SubTasks'));
            let tData2=undefined;
            if(tDataValidate){
              tData2=tDataValidate;
            }else{
              tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
            } 
              if (tData2) {
                const type2 = (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'WaitingOn') ? 'Blocking' :
                              (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'Blocking') ? 'WaitingOn' :
                              tData2.DependancyType; 
                if (tData2.TargetTaskId == ROWID) {
                  const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                  if (tmp2 && sourceTaskType==tData2.TaskType) {
                    obj[type2].add(JSON.stringify({...tmp2}));
                  }
                } else {
                  obj[type2].add(JSON.stringify({ label: subtask.TaskName, value: `SubTasks_${subtask.SubTaskROWID}` }));
                }
              }
            });
          });
        }); 
        setSelectedDependenciesCache({
          WaitingOn: Array.from(obj.WaitingOn).map(item => JSON.parse(item)),
          Blocking: Array.from(obj.Blocking).map(item => JSON.parse(item))
        });
      } else {
        setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
      }
    }); 
  }
  };
  
  useEffect(()=>{
      let data={...selectedDependenciesCache}
      setSelectedDependencies(data);
  },[selectedDependenciesCache])

  useEffect(
    (e) => {
      let array = [];
      allAccounts = allAccounts?.filter((item) => item?.Account_Name != "All");
      allAccounts?.map((item) => {
        array.push({
          value: item?.Account_Name,
          label: item?.Account_Name,
          key: item?.id,
        });
      });
      array.unshift({
        value: "Miscellaneous",
        label: "Miscellaneous",
        key: "Miscellaneous",
      });
      setAllAccountsData(array);
    },
    [allAccounts]
  );

  useEffect(
    (e) => {
      let array = [];
      allDealsAndUsers = allDealsAndUsers?.filter(
        (item) =>
          item.hasOwnProperty("Created_Time") &&
          item?.Account_Name?.name == accountSelected
      );
      allDealsAndUsers?.map((item) => {
        array.push({
          value: item?.Deal_Name,
          label: item?.Deal_Name,
          key: item?.id,
        });
      });
      setAllDealsData(array);
    },
    [accountSelected]
  );

  useEffect(() => {
    if (dealSelected) {
      AwsServerService.getPipelineDataByDeal(dealSelected)
        .then((res) => {
          let array = [];
          console.log("data ---- ", res);
          res[0]?.map((item) => {
            array.push({
              value: item?.ROWID,
              label: item?.PipelineName,
              key: item?.ROWID,
            });
          });
          setAllPipelinesData(array);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAllPipelinesData([]);
    }
  }, [dealSelected]);

  useEffect(() => {
    if (viewPipelineData && viewPipelineData?.length > 0) {
      let array = [];
      array.push({
        value: viewPipelineData[0]?.PipelineROWID,
        label: viewPipelineData[0]?.PipelineName,
        key: viewPipelineData[0]?.PipelineROWID,
      });
      setAllPipelinesData(array);
    } else {
      setAllPipelinesData([]);
    }
  }, [viewPipelineData]);

  const activitySections = [
    {
      key: "Activity",
      open: true,
      icon: <FontAwesomeIcon icon={faCommentAlt} />,
      component: (taskId, flag) => {
        return (
          <ActivitySection taskId={taskId} module={flag ? "SUBTASK" : "TASK"} refreshData={refreshData}/>
        );
      },
    },
    {
      key: "Settings",
      open: false,
      icon: <img src={SettingIconDark} alt="SettingIcon" style={{width:"17px"}}/>,
      component: () => <></>,
    },
  ];


  ///// new changes for settings options ////

  const [popoverVisible, setPopoverVisible] = useState(false); // State for controlling Popover visibility
  const [duplicateValueObj, setDuplicateValueObj] = useState({
    TaskName: '',
    Everything: true, 
    // Attachements: true, Comments: true, InputFields: true,LookUpFields: true, Dependencies: true, 
    StartDate: true, DueDate: true, Assignee: true, Supervisor:true,
    TimeEstimate: true, Priority: true, 
    // Status: true, 
    Subtask: true,
  });
  const [duplicatePopUp, setDuplicatePopUp] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // console.log("duplicateValueObj", duplicateValueObj);
  
  const handleOnChangeDuplicateCheckBox = (e) => {
    const { name, value, checked } = e.target;
    const updatedObj = { ...duplicateValueObj };
  
    if (name === 'TaskName') {
      updatedObj[name] = value; // Handle TaskName input change
    } else if (name === 'Everything') {
      // Select/Deselect all checkboxes when 'Everything' is clicked
      const newValue = checked;
      Object.keys(updatedObj).forEach((key) => {
        if (key !== 'TaskName') {
          updatedObj[key] = newValue;
        }
      });
    } else {
      // Handle individual checkbox changes
      updatedObj[name] = checked;
    }
  
    // Check if all checkboxes (except 'TaskName' and 'Everything') are selected
    const allChecked = Object.keys(updatedObj).every(
      (key) => key === 'TaskName' || key === 'Everything' || updatedObj[key]
    );
  
    // Check if at least one checkbox (other than 'TaskName') is selected but not all
    const someChecked = Object.keys(updatedObj).some(
      (key) => key !== 'TaskName' && key !== 'Everything' && updatedObj[key]
    );
  
    // Update the 'Everything' checkbox state
    updatedObj['Everything'] = allChecked;
    setIndeterminate(someChecked && !allChecked); // Set indeterminate if some are checked but not all
  
    setDuplicateValueObj(updatedObj);
  };

  const hanleCreateDuplicateTask = async(duplicateFields, data) =>{
    let duplicateObj = { ...duplicateFields };
    let duplicateTaskObj = { ...data };
    if (!duplicateObj.Priority) {
      duplicateTaskObj.Priority = "Not Set"
    }
    if (!duplicateObj.Assignee) {
      duplicateTaskObj.Assignee = '';
    }
    if (!duplicateObj.DueDate) {
      duplicateTaskObj.DueDate = new Date();
    }
    if (!duplicateObj.Supervisor) {
      duplicateTaskObj.Supervisor = ''
    }
    if (!duplicateObj.StartDate) {
     duplicateTaskObj.StartDate = new Date()
    }
    // if (!duplicateObj.DueDate) {
    //   delete duplicateTaskObj.DueDate
    // }
    // if (!duplicateObj.DueDate) {
    //   delete duplicateTaskObj.DueDate
    // }
      delete duplicateTaskObj?.TaskROWID
      delete duplicateTaskObj?.SubTasks
      delete duplicateTaskObj?.TaskTemplateId
      delete duplicateTaskObj?.TaskId
      delete duplicateTaskObj?.MilestoneName
      delete duplicateTaskObj?.IsMilestone
      delete duplicateTaskObj?.deps
      duplicateTaskObj.TaskName = duplicateValueObj.TaskName
      await AwsServerService.saveTask(duplicateTaskObj).then(async res=>{
        if(duplicateObj.Subtask){
          await Promise.all(
            data.SubTasks.map((item) => {
              delete item["id"];
              delete item["flag"];
              delete item["PipelineROWID"];
              delete item["SubTaskROWID"];
              delete item["TaskRowId"];
              delete item["SubTaskId"];
              delete item["SubTaskTemplateId"];
              item["TaskROWID"] = res[0]?.insertId;
              item["TaskName"] = `${item["TaskName"]} - Copy`;
              return AwsServerService.createSubtask(item);
            })
          ).then((allRes) => {
            console.log("allRes dupl", allRes);
          });
        }
        

        setDuplicatePopUp(prev => !prev)
        setPageRefreshData(prev => !prev)
      })
  }


  const renderDuplicateModal = () => {
    return (
      <AntModal
        title="Duplicate Task"
        open={duplicatePopUp}
        onCancel={()=>{setDuplicatePopUp(false)}}
        footer={null}
        className="taskComplete-warning duplicate-task-modal"
      >
        <Row>
          <Col sm={12}>
            <h4 className='small text-left'>New Task Name</h4>
            <Input
              onChange={handleOnChangeDuplicateCheckBox}
              defaultValue={duplicateValueObj.TaskName}
              name='TaskName'
              placeholder='New task name'
              className='form-control form-control-sm duplicate-taskname'
            />
          </Col>
        </Row>
  
        <Row className='duplicate-task-box' style={{ padding: '20px 15px' }}>
          <Col sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 16 }} className='small text-left'>
              What do you want to copy?
            </p>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Everything}
              indeterminate={indeterminate} // Add indeterminate state
              name='Everything'
            >
              Everything
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Supervisor}
              name='Supervisor'
            >
              Supervisor
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Assignee}
              name='Assignee'
            >
              Assignee
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Priority}
              name='Priority'
            >
              Priority
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.TimeEstimate}
              name='TimeEstimate'
            >
              Time Estimate
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.StartDate}
              name='StartDate'
            >
              Start Date
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.DueDate}
              name='DueDate'
            >
              Due Date
            </Checkbox>
            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Subtask}
              name='DueDate'
            >
              Subtask
            </Checkbox>
          </Col>
        </Row>
  
        <Row>
          <Col sm={12} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              onClick={async () => {
                console.log("Duplicate Clicked")
                hanleCreateDuplicateTask(duplicateValueObj, taskData)
              }}
            >
              Duplicate Task
            </Button>
          </Col>
        </Row>
      </AntModal>
    );
  };

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    const parent = { ...taskData };
    if (option && option.value == 'Duplicate') {

      let duplicate = {
        TaskName: '',
        Everything: true, 
        // Attachements: true, Checklist: true, Comments: true, InputFields: true,
        // LookUpFields: true, Dependencies: true, 
        StartDate: true, DueDate: true, Assignee: true,Supervisor:true,
        TimeEstimate: true, Priority: true, 
        // Status: true, 
        Subtask: true
      };
      duplicate.TaskName = parent.TaskName + ' - Copy'
      setDuplicateValueObj(duplicate);
      setDuplicatePopUp(true);

    }
    if (option && option.value == 'Template') {
      // setTemplatePopUp(true)
      // setServiceRequested(serviceReqVariable);
      // duplicate.TaskName = parent.name + ' - Template';
      // setDuplicateValueObj(duplicate);
    }
    if (option && option.value == 'Copy') {
      // getLinkOfTaskModal(parent)
    }
    if (option && option.value == 'Path') {
      // setRelativeModal(true);
    }
    
    console.log(`Selected option: ${option.value}`);

    setPopoverVisible(false); // Close popover after selection
    // Add your handling logic here
  };

  // Options for the settings popover
  const settingsOptions = [
    { label: "Duplicate Task", value: "Duplicate" },
    { label: "Save as Template", value: "Template" },
    { label: "Copy Link", value: "Link" },
    { label: "Add Relative Path", value: "Path" },
  ];

  const renderSettingsPopoverContent = () => (
    <div>
      {taskModalState.action !== "create" && settingsOptions.map((option) => (
        <Button
          key={option.value}
          style={{ width: "100%"}}
          className="Duplicate-modal-btns"
          onClick={(e) => { 
            if (taskModalState.action === "create") {
              e.preventDefault();
              return;
            }
            handleOptionSelect(option)
          }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );

  //////////// End ////////////

  const [sidebarOptions, setSidebarOptions] = useState([...activitySections]);
  const [activitySection, setActivitySection] = useState({
    key: "Activity",
    open: true,
    icon: <FontAwesomeIcon icon={faCommentAlt} />,
    component: (taskId, flag) => {
      return (
        <ActivitySection taskId={taskId} module={flag ? "SUBTASK" : "TASK"} refreshData={refreshData}/>
      );
    },
  });
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  // console.log("selectionRange", selectionRange);  
  useEffect(
    (e) => {
      // console.log(selectionRange);

      if (selectionRange[0]?.startDate && selectionRange[0]?.endDate) {
        // console.log(selectionRange);
        let temp = { ...taskData };
        temp["StartDate"] = convertToUSTime(selectionRange[0]?.startDate);
        temp["DueDate"] = convertToUSTime(selectionRange[0]?.endDate);
        setTaskData(temp);
      }
    },
    [selectionRange]
  );
  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );
  const changeDateRange = (item) => {
 
    // Extract and format the dates
    const startDate = dayjs(item.selection.startDate).format("MM-DD-YYYY");
    const endDate = dayjs(item.selection.endDate).format("MM-DD-YYYY");
  
    // Update the selection range
    setSelectionRange([item.selection]);
  };

  const handleTransitionChange = (e, data) => {
    let key = statusOptions?.find(key => key.label == e)
    data = {
      ...data, Nodes: data?.Nodes?.map(n => {
        if (n.data.label == taskData.TaskStatus) {
          n["visited"] = true;
        }
        return n
      }), Edges: data?.Edges?.map(ed => {
        if (ed.id == key.key) {
          ed["visited"] = true;
        }
        return ed;
      })
    }
    let edge = data?.Edges?.filter(res => res.id == key.key)[0];
    if(edge?.rework){
      data.Edges = findCircularConnection(edge,data.Edges)
    }
    let nextNode = data?.Nodes?.filter(res => res.id == edge.target)[0];
    let status = nextNode?.data?.label;
    setTaskData({ ...taskData, TaskFlowObject: data, 'TaskStatus': status });
    getBlueprintDataOptions(data, status);
  }

  const handleDropDownChange = (e, data) => {
    if (data == "Account") {
      setAccountSelected(e);
    } else if (data == "Project") {
      setDealSelected(e);
    } else {
      
      let temp = { ...taskData };
      if(data=="TaskFlowId"){
        let bluePrint = allBlueprintsData?.find(d=>d.ROWID==e);
        if(bluePrint){
          temp["TaskFlowObject"] = bluePrint;
        }
        getBlueprintDataOptions(bluePrint,"Not Started");
      }
      
      temp[data] = e;
      setTaskData(temp);
    }
  };
  
  const getAntSelectDropDown = (data) => {
    let options = data == "Project" ? allDealsData : allAccountsData;
    return (
      <AntSelect
        disabled={
          (data == "Project" && accountSelected == "Miscellaneous") ||
          ((data == "Project" || data == "Account") && createPiplineModalState)|| taskModalState.action == "edit"
        }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={
          data == "Priority"
            ? "dropdown-create-task"
            : "dropdown-create-task header-dropdowns"
        }
        placeholder={data == "Account" ? "Select Account" : "Select Project"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={
          data == "Account"
            ? accountSelected
            : data == "Project"
            ? dealSelected
            : taskData[data]
        }
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };

  const getBlueprintsDropDown = (data) => {
     
    let options = [];
    // console.log("all blueprints",allBlueprintsData);
    
    allBlueprintsData?.map((item) => {
      options.push({
        value: item?.ROWID,
        label: item?.Name,
        // key: item?.Name,
      });
    })
    return (
      <AntSelect
        // disabled={
        //   (data == "Project" && accountSelected == "Miscellaneous") ||
        //   ((data == "Project" || data == "Account") && createPiplineModalState)
        // }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dropdown-create-task header-dropdowns" }
        placeholder={"Select blueprint"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={taskData[data]}
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  const checkWaitingDependencies=()=>{
    let flag=false;
    let data= {...selectedDependenciesCache}
    const waitingOnTaskIds = data.WaitingOn.map(item => item.value);
    let filteredDeps=[];
    for(const el of waitingOnTaskIds){
      let type=el.split('_');
      let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
      if(taskData && (taskData?.TaskStatus !='Complete' && taskData?.TaskStatus !='Completed')){
        filteredDeps.push(taskData);
        flag = true;
      }
    }
    return {flag:flag,data:filteredDeps};
  }
  const onChangeTransion= (e) => {
    let result=checkWaitingDependencies(); 
    if(result.flag){ 
      setTaskData({...taskData,'TransitionStatus':''});
      const taskLinks = result.data.map(task => {
        let type=task.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
        let id=type=='Tasks'?task.TaskROWID:task.SubTaskROWID;
        return `<a class="swalDiv" data-task="${type}_${id}" style="cursor:pointer;"><strong>${task.TaskName}</strong></a>`;
    }).join(", ");
      swal({
        title: "Alert!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        content: {
          element: "div",
          attributes: {
            innerHTML: `
              <span>
                 This task is waiting on the Task/Tasks ${taskLinks} ?.
              </span>
            `
          }
        }
      })
        .then(async (okay) => {
          if (okay) {
          
          }
        });
    }else{
      handleTransitionChange(e, taskData?.TaskFlowObject);
    }
    const taskNameElements = document.querySelectorAll('.swalDiv'); 
      taskNameElements.forEach(taskNameElement => {
          taskNameElement.addEventListener('click', (e) => {
              swal.close();  
              // let data2 = result.data.find(task => task === e.currentTarget.dataset.task); 
              let data2 = e.currentTarget.dataset.task;   
              let tasksData = { ...allTasksAndSubtasks };
              let step=data2.split('_'); 
                  const newData = tasksData[step[0]].get(Number(step[1]));
                  if(newData){
                    handleHistoryAdd();
                    viewTaskFunction(newData, step[0]=='Tasks'?'TASK':'SUBTASK');   
                  }  
          });
      });
  }
  const getStyleObj=(status)=>{
    let temp=mainStatusOptions?.find(k=>k.label==status);
    let sty={
      backgroundColor:"white",
      color:"black",
      border:"1px solid white"
    }
    if(temp && temp.color){
      sty["backgroundColor"]=temp.color;
      sty["border"]= `1px solid ${temp.color}`
      sty["color"]= invertColor(temp.color,true)
    }
    return sty;
  }
  const getStatusDropdown=(data,listtype) => {

    // let arr=[];
    // if(listtype=="reject"){
    //   arr=statusOptions?.filter(one=>one.color=="red")
    // }else{
    //   arr=statusOptions?.filter(one=>one.color!="red")
    // }
    const content = (
      <div className="Task-Status-Div">
        {mainStatusOptions?.map((one) => {
          return (
            <p
              style={getStyleObj(one.label)}
              className="Task-Status-P"
              onClick={() => {
                // onSelect(one);
                onChangeTransion(one.label)
                // setSelected(!selected);
              }}
              // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(mainStatusOptions.length==0){
      return (<Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: "#1677ff",
            borderColor: "#1677ff",
            color:"white",
          }}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >No Task Flow</Button>)
    }
    return (
      <Popover
        style={{ zIndex: 9999 }}
        // open={true}
        onOpenChange={(e) => {
          // setIsOpen(!isOpen);
        }}
        trigger="click"
        // overlayClassName={"Prject-Status-Container" }
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={getStyleObj(taskData.TaskStatus)}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >{taskData.TaskStatus}</Button>
      </Popover>
    )
    
  }
  const getBlueprintsStatuses = (data,listtype) => {

    let arr=[];
    if(listtype=="reject"){
      arr=statusOptions?.filter(one=>one.color=="red")
    }else{
      arr=statusOptions?.filter(one=>one.color!="red")
    }
    const content = (
      <div>
        {arr?.map((one) => {
          return (
            <p
              className="Task-Status-P"
              style={getStyleObj()}
              onClick={() => {
                // onSelect(one);
                onChangeTransion(one.label)
                // setSelected(!selected);
              }}
              // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(arr.length==0){
      return (
        <Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      )
    }
    return (
      <Popover
        
        style={{ zIndex: 9999 }}
        // open={true}
        onOpenChange={(e) => {
          // setIsOpen(!isOpen);
        }}
        trigger="hover"
        // overlayClassName={"Prject-Status-Container" }
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      </Popover>
    )
    
  };

  const DueDateRangePopover = () => {
    return (
      <Popover
        overlayClassName="create-task-datepicker"
        content={
          <div className="due-date-range">
            <DateRangePicker
              onChange={changeDateRange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectionRange}
              direction="vertical"
              scroll={{ enabled: true }}
            />
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <AntButton
          id="setDueDate"
          className="SupervisorBtn"
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
          style={{ width: "100%" , color: "#5a6c97"}}
        >
          {`${dayjs(taskData?.StartDate).format("MM/DD/YYYY")} - ${dayjs(taskData?.DueDate).format("MM/DD/YYYY")}`}
        </AntButton>
      </Popover>
    );
  };
  const handleHistoryAdd=()=>{
    let history=[...taskHistory]
    history.push(viewTaskData)
    setTaskHistory(history);
  }
  const handleBack = () => {
    const previousHistory = [...taskHistory]; 
    const lastTask = previousHistory.pop();
    let type=lastTask?.hasOwnProperty('SubTaskROWID')?'SUBTASK':'TASK';
    if(lastTask){
      viewTaskFunction(lastTask,type); 
    }
    setTaskHistory(previousHistory);
  };
  function openTaskFromDependencies(data){ 
    const [type,id]=data?.value?.split('_');  
    let tasksData={...allTasksAndSubtasks};
     if(type=='Tasks'){
      const newData=tasksData.Tasks.get(number(id));
      if(newData){
        handleHistoryAdd();
        viewTaskFunction(newData,type);
      }
     }else{
      const newData=tasksData.SubTasks.get(number(id));
      if(newData){
        handleHistoryAdd();
        viewTaskFunction(newData,type);
      }
     }
     setIsDependencyModalOpen(false);
  }
  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
    ],
  });
  
  const handleSaveDependencies=async (data)=>{ 
    if(!viewTaskData){
      setSelectedDependenciesCache(data)
      setIsDependencyModalOpen(false);
      return;
    }
    try { 
      let sourceTaskType=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let sourceId=viewTaskData.hasOwnProperty('SubTaskROWID')?viewTaskData.SubTaskROWID:viewTaskData.TaskROWID;
      if(selectedTable=='tasks'){
        if(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0){ 
        let d={...selectedDependenciesCache};
        let blocking = new Set(d.Blocking.filter(e=>e.value).map(e => e.value));
        let waiting = new Set(d.WaitingOn.filter(e=>e.value).map(e => e.value));
        let deps={
          Blocking:data.Blocking.filter(e=> !blocking.has(e.value)),
          WaitingOn:data.WaitingOn.filter(e=> !waiting.has(e.value))
        }
        const promises = Object.keys(deps).map(async (e) => { 
        return Promise.all(deps[e].map(async (a) => { 
          const [type,id]=a.value.split('_');
        const payload={
          TargetTaskId:id,
          STaskType:sourceTaskType,
          SourceTaskId:sourceId,
          TaskType:type,
          DependancyType:e,
          CREATEDBY:userinfo.email_id
        } 
        return AwsServerService.createDependency(payload).then(e=>{
          // setDependencyRefresh(prev => !prev)
        })
      }));
    });
    await Promise.all(promises).then(r=>{
      let data={...selectedDependencies}
      setSelectedDependenciesCache(data)
      setIsDependencyModalOpen(false);
    })
      }else{
       const promises = Object.keys(data).map(async (e) => { 
         return Promise.all(data[e].map(async (a) => { 
           const [type,id]=a.value.split('_');
           const payload={
            TargetTaskId:id,
            STaskType:sourceTaskType,
            SourceTaskId:sourceId,
            TaskType:type,
            DependancyType:e,
            CREATEDBY:userinfo.email_id
          } 
          return AwsServerService.createDependency(payload).then(e=>{
            // setDependencyRefresh(prev => !prev)
          });
        }));
      }); 
      await Promise.all(promises).then(r=>{
        let data={...selectedDependencies}
        setSelectedDependenciesCache(data)
        setIsDependencyModalOpen(false);
        // swal('Success','All dependencies saved successfully','success');
      });
    }
  }else{
    if(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0){ 
      let d={...selectedDependenciesCache};
      let blocking = new Set(d.Blocking.filter(e=>e.value).map(e => e.value));
      let waiting = new Set(d.WaitingOn.filter(e=>e.value).map(e => e.value));
      let deps={
        Blocking:data.Blocking.filter(e=> !blocking.has(e.value)),
        WaitingOn:data.WaitingOn.filter(e=> !waiting.has(e.value))
      }
      const promises = Object.keys(deps).map(async (e) => { 
      return Promise.all(deps[e].map(async (a) => { 
        const [type,id]=a.value.split('_');
      const payload={
        TargetTaskId:id,
        STaskType:sourceTaskType,
        SourceTaskId:sourceId,
        TaskType:type,
        DependancyType:e,
        PipelineROWID:viewTaskData.PipelineROWID,
        CREATEDBY:userinfo.email_id
      } 
      return AwsServerService.createTempDependency(payload).then(e=>{
        // setDependencyRefresh(prev => !prev)
      });
    }));
  });
  await Promise.all(promises).then(r=>{
    let data={...selectedDependencies}
    setSelectedDependenciesCache(data)
    setIsDependencyModalOpen(false);
  })}else{
    const promises = Object.keys(data).map(async (e) => { 
      return Promise.all(data[e].map(async (a) => { 
        const [type,id]=a.value.split('_');
        const payload={
         TargetTaskId:id,
         STaskType:sourceTaskType,
         SourceTaskId:sourceId,
         TaskType:type,
         DependancyType:e,
         PipelineROWID:viewTaskData.PipelineROWID,
         CREATEDBY:userinfo.email_id
       } 
       return AwsServerService.createTempDependency(payload).then(e=>{
        // setDependencyRefresh(prev => !prev)
      });
     }));
   }); 
   await Promise.all(promises).then(r=>{
     let data={...selectedDependencies}
     setSelectedDependenciesCache(data)
     setIsDependencyModalOpen(false);
     // swal('Success','All dependencies saved successfully','success');
   }); 
  }
  }
  } catch (error) {
      // swal('Failed','Error saving dependencies','Alert');
    }
  } 
  function handleDeleteDependency(e,isWaiting){
    let [type,id]=e.value.split('_');
    if(!viewTaskData){
      if(isWaiting=='waiting'){
        let selections=[...selectedDependencies.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
        // setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
        setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
      }else{
        let selections=[...selectedDependencies.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});
        } 
        // setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
        setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
      }
      return;
    }
    let sourceType=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let sourceId=viewTaskData.hasOwnProperty('SubTaskROWID')?viewTaskData.SubTaskROWID:viewTaskData.TaskROWID;
    if(selectedTable=='tasks'){

      AwsServerService.deleteDependency(sourceId,sourceType,id,type).then(r=>{
      if(isWaiting=='waiting'){
        let selections=[...selectedDependencies.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
        setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
        // setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
      }else{
        let selections=[...selectedDependencies.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});;
        } 
        setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
        // setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
      }
      // setDependencyRefresh(prev => !prev)
      })
    }else{
      AwsServerService.deleteTempDependency(sourceId,sourceType,id,type).then(r=>{
        if(isWaiting=='waiting'){
          let selections=[...selectedDependencies.WaitingOn];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'WaitingOn':0});
          }
          setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
          // setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
        }else{
          let selections=[...selectedDependencies.Blocking];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'Blocking':0});;
          } 
          setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
          // setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
        }
        // setDependencyRefresh(prev => !prev)
        })
    }
  }
  const DependecyModal = () => {
    return (
      <>
        <AntButton
          onClick={() => {
            setIsDependencyModalOpen(true);
          }}
          color="primary"
          type="primary"
        >
          {(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0) ?`Dependencies`:`Add Dependency`}
        </AntButton>
        <AntModal
          open={isDependencyModalOpen}
          // open={true}
          wrapClassName={"Add-dependecny-Wrapper"}
          rootClassName="Add-dependecny-Root-Wrapper"
          draggable
          title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
          maskClosable={false}
          width={300}
          className=""
          style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
          onCancel={() => {
            setIsDependencyModalOpen(false); 
            let data={...selectedDependenciesCache};
            setSelectedDependencies(data); 
            setDropdownCount({WaitingOn:0,Blocking:0})
          }}
          footer={[<>
          <Button type="primary" onClick={()=>{
            handleSaveDependencies(selectedDependencies) 
          }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
          </>]}
        >
          <div className="add-dependency-wrapper-div">
            <div className="dependency-header"></div>
            <div className="dependency-modal-body-wrapper">
                  <Row>
                  <Col sm={2}>
                   <label htmlFor="status"><strong>Waiting on:</strong></label>
                  </Col>
                    <Col sm={10}>
                    <div className="waitingOndiv">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.WaitingOn.map((e,index)=>{
                        let type=e.value.split('_');
                        let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRendertaskdiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>  
                            {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                            <div style={{cursor: 'pointer'}} onClick={()=>{
                              openTaskFromDependencies(e);
                            }}>
                            {e.label}
                          </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
                              let [type,id]=e.value.split('_');
                              console.log(type,id);
                            }}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            // let selections=[...selectedDependencies.WaitingOn];
                            // let data=selections?.filter(t=>t.label!==e.label);
                            // if(data.length==0){
                            //   setDropdownCount({...dropdowncount,'WaitingOn':0});
                            // } 
                            // setSelectedDependencies({...selectedDependencies,'WaitingOn':data}) 
                            handleDeleteDependency(e,'waiting');
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>
                          }
                         )
                      }
                     </div>
                     {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
                    { dropdowncount.WaitingOn==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Waiting On'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.WaitingOn];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
                  <Row style={{ marginTop: '20px' }}>
                  <Col sm={2}>
                   <label htmlFor="status"><strong>Blocking :</strong></label>
                  </Col>
                    <Col sm={10}>
                    <div className="waitingOndiv">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.Blocking.map((e,index)=>{
                          let type=e.value.split('_');
                          let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRendertaskdiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>
                          {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                          <div style={{cursor: 'pointer'}} onClick={()=>{
                          openTaskFromDependencies(e);
                         }}>
                            {e.label}
                        </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            handleDeleteDependency(e); 
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>}
                         )
                      }
                     </div>
                     {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
                    { dropdowncount.Blocking==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Blocking'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.Blocking];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
                      // setDropdownTasksData(data?.filter(t=>t.value != e))
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
            </div>
          </div>
        </AntModal>
      </>
    );
  };

  const getTableForLookupFields = () => {
    return (
      <div className="newField-box">
        {/* <table class="table table-sm table-hover newField-table">
          <tbody>
            {taskCustumeFieldList &&
              taskCustumeFieldList.map((one, i) => {
                if (showMoreFields) {
                  return getInputHtmlForAddField(one);
                } else {
                  return;
                }
              })}
          </tbody>
        </table> */}
        <div className="newField-tableOptions">
          {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
          <p onClick={openLookupFields}>
            <FontAwesomeIcon
              className="mr-2"
              icon={showMoreFields ? faCaretUp : faCaretDown}
            />
            {showMoreFields ? "Show less Lookup" : "Show more Lookup"} fields (
            {/* {countLookupFields(taskCustumeFieldList)} total fields */}0
            )
          </p>
          {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              // onClickAddOrEditCustomeFields("LookupFields");
            }}
          >
            + Add fields
          </a>
        </div>
      </div>
    );
  };

  const getStatusByFlow=async(viewTaskData,accepted)=>{
    let id=viewTaskData?.TaskFlowId; 
    let currentStatus= viewTaskData?.TaskStatus;
    if(id==undefined){
      swal('Alert','Task Not Saved Yet!','info')
      return
    }
    swal({
      title: "Warning!",
      text: `Are you sure you want to ${accepted?'Approve':'Reject'}?`,
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Proceed",
      },
    }).then(async(isTrue)=>{
      if(isTrue){
         await AwsServerService.getTaskFlowById(id).then(r=>{
           let data=r.data[0];
           if(data){
       let status=data[0].Nodes.filter(e=>e.data.label==currentStatus)[0];
       let next=getNextStepOfFlow(data[0].Nodes,data[0].Edges,status.id,accepted);
       let statusToSet=data[0].Nodes.filter(e=>e.id==next)[0];
       if(statusToSet){ 
        let temp = { ...taskData };
        temp["TaskStatus"] = statusToSet.data.label;
        setTaskData(temp);
      }
    }
  }) 
  }
});
}

  return (
    <div>
      {renderDuplicateModal()}
      <AntModal
        open={open}
        // open={true}
        wrapClassName={"Add-task-Wrapper"}
        rootClassName="Add-task-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setOpen(false);
          setIsSubTaskView(false);
          setTaskData({ ...taskEmptyTemplate });
          setStatusOptions([]);
          setAccountSelected('')
          setDealSelected('')
          setTaskHistory([]);
        }}
        footer={[<></>]}
      >
        <div className="Task-Modal-Wrapper">
          <div className="Task-Header">
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {/* <span><FontAwesomeIcon icon="fa-solid fa-list-check" /></span> */}
              {/* <span style={{fontSize:"1.5rem",fontWeight:"600"}}>Create Task</span> */}
              { taskModalState.action == "edit" && <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon
                onClick={() => {
                  handleBack();
                }}
                style={taskHistory.length == 0 ? { cursor: 'not-allowed', color: 'lightgray' } : { cursor: 'pointer' }}
                icon={faArrowLeft}
              />
              </span>}
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faUser} />
                <div>{getAntSelectDropDown("Account")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faFolder} />
                <div>{getAntSelectDropDown("Project")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faCodeBranch} />
                <div>{getBlueprintsDropDown("TaskFlowId")}</div>
              </span>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  type="primary"
                  // icon={<CloseOutlined />}
                  onClick={() => {
                    handleSaveTask(taskData);
                  }}
                  style={{ height: "28px" }}
                >
                  {taskModalState.action == "edit"
                    ? "Update Task"
                    : "Save Task"}
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setOpen(false);
                    setIsSubTaskView(false);
                    setDependencyRefresh(prev => !prev);
                    setTaskData({ ...taskEmptyTemplate });
                    setStatusOptions([])
                    setTaskHistory([]);
                    setAccountSelected('')
                    setDealSelected('')
                  }}
                  style={{ height: "28px" }}
                ></Button>
              </div>
            </div>
          </div>
          <div className="Task-Header-Two">
            {[
            //  {
            //   label: "STATUS",
            //   component: (
            //     <>
            //       <div className="statusDropdownBlueprint">
            //         {getStatusDropdown(taskData.TaskFlowObject)}
            //         {getBlueprintsStatuses('TransitionStatus')}
            //         {getBlueprintsStatuses('TransitionStatus',"reject")}
            //       </div>
            //     </>
            //   ),
            // },
            // {
            //   label: "NEXT TRANSITION",
            //   component: (
            //     // <StatusDropdown
            //     //   module="CreateTask"
            //     //   toggelButton={
            //     //     <span
            //     //       className="project_status_custom"
            //     //       style={{
            //     //         backgroundColor:
            //     //           taskLayout.status_details[0]?.color || "",
            //     //         maxWidth: "8rem",
            //     //       }}
            //     //     >
            //     //       {taskLayout.status_details[0]?.name}
            //     //     </span>
            //     //   }
            //     //   items={taskLayout.status_details || []}
            //     //   onSelect={async (value) => {}}
            //     // /> 
            //     <>
            //       <div className="statusDropdownBlueprint">
            //       {getBlueprintsStatuses('TransitionStatus')}
            //       {getBlueprintsStatuses('TransitionStatus',"reject")}
            //       </div>
            //     </>
            //   ),
            // },
              {
                label: "SUPERVISOR",
                component: (
                  <UserPicker
                    values={taskData["Supervisor"] ? taskData["Supervisor"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Supervisor: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Supervisor: "" })}
                  />
                ),
              },
              {
                label: "ASSIGNEE",
                component: (
                  <UserPicker
                    mode="multiple"
                    values={taskData["Assignee"] ? taskData["Assignee"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Assignee: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Assignee: "" })}
                  />
                ),
              },
              {
                label: "PRIORITY",
                component: (
                  <Tooltip color="rgb(54 54 54)" title={taskData["Priority"]}>
                    <Select
                      className="priority-icon"
                      name="Priority"
                      placeholder="Select an option"
                      style={{ width: 200 }}
                      value={taskData["Priority"] ? taskData["Priority"] : "Not Set"}
                      onChange={(val) =>
                        setTaskData({ ...taskData, Priority: val })
                      }
                      optionLabelProp="icon"
                      rootClassName="table-dropdown"
                    >
                      {["Not Set", "Low", "Medium", "High"].map((priority) => (
                        <Option
                          key={priority}
                          value={priority}
                          icon={
                            <FlagFilled
                              style={{ color: getPriorityValue(priority) }}
                              className="Icon"
                            />
                          }
                        >
                          <FlagFilled
                            style={{ color: getPriorityValue(priority) }}
                            className="Icon"
                          />{" "}
                          {priority}
                        </Option>
                      ))}
                    </Select>
                  </Tooltip>
                  ),
              },
              {
                label: "TIME TRACKED",
                component: (<>
                <TimeTracker 
                  viewTaskData={viewTaskData} 
                  open={open} 
                  users={userOptions} 
                  allTasksAndSubtasks={allTasksAndSubtasks} 
                  viewTaskFunction={viewTaskFunction}
                  handleHistoryAdd={handleHistoryAdd}
                  />                  
                </>),
              },
              {
                label: "TIME ESTIMATE",
                component: (
                  <CustomTimeSelect
                    rootClassName={"Create-Task-TimePicker"}
                    className={"Create-Task-TimePicker-Class"}
                    placement={"top"}
                    taskData={taskData}
                    setTaskData={setTaskData}
                  />
                ),
              },
              {
                label: "START/DUE DATE",
                component: <DueDateRangePopover />,
              },
            ].map(({ label, component }) => (
              <div key={label}>
                <p className="Text-Center" style={label == "TIME ESTIMATE" ? {marginBottom : "2px"} : {}}>{label}</p>
                {component}
              </div>
            ))}
          </div>
          <div className="Task-Modal-Body-Wrapper">
            <div className="Task-Modal-Body-Child1">
              <Row className="Task-Modal-Body" sm={12}>
                <Col
                  className="Task-Info-Wrapper"
                  sm={activitySection && taskModalState.action =="edit" && activitySection.open ? 7 : 12}
                >
                  <Row className="TaskNameWrapper margin-0">
                    <Input
                      className="Task-Name"
                      size="large"
                      placeholder="Task Name"
                      name="TaskName"
                      variant="borderless"
                      value={taskData["TaskName"]}
                      onChange={(e) => {
                        e.currentTarget.value = (e.currentTarget.value).trimStart();
                        let temp = { ...taskData };
                        temp["TaskName"] = e.currentTarget.value;
                        setTaskData(temp);
                      }}
                    />
                    <div className="Status-Wrapper-Class">
                    {/* <span>STATUS:&nbsp;</span> */}
                    <TaskFlowStatus
                      statusOptions={mainStatusOptions}
                      onChangeStatus={(data)=>{onChangeTransion(data.label)}}
                      taskData={taskData}
                      transitions={statusOptions}
                    />
                    </div>
                  </Row>
                  <Row className="TaskDescriptionWrapper margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          label: "Description",
                          children: (
                            <TextArea
                              name="TaskDescription"
                              style={{ border: "none", color:"#5b6c97" }}
                              placeholder="Write description.."
                              value={taskData["TaskDescription"]}
                              onChange={(e) => {
                                let temp = { ...taskData };
                                temp["TaskDescription"] = e.currentTarget.value;
                                setTaskData(temp);
                              }}
                            />
                          ),
                        },
                      ]}
                      rootClassName={''}
                    />
                  </Row>
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField
                        name={"PIPELINE:"}
                        icon={<FontAwesomeIcon icon={faUser} />}
                      >
                        <Select
                          mode={""}
                          style={{ width: 200 }}
                          value={taskData["PipelineROWID"]}
                          placeholder="Select Pipeline"
                          onChange={(val) => {
                            let temp = { ...taskData };
                            temp["PipelineROWID"] = val;
                            setTaskData(temp);
                          }}
                          options={allPipelinesData}
                        />
                      </CustomeTaskField>
                      <CustomeTaskField
                        name={"CREATED AT:"}
                        icon={<FontAwesomeIcon icon={faClock} />}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            opacity: "0.85",
                            padding: "12px",
                          }}
                        >
                          {taskData?.CREATEDTIME
                            ? dayjs(taskData?.CREATEDTIME).format("MM-DD-YYYY")
                            : ""}
                        </span>
                      </CustomeTaskField>
                    </Col>
                    {/* <Col sm={6}>
                      <CustomeTaskField
                        name={"TASK RELATION:"}
                        icon={<MinusCircleFilled />}
                      >
                        {DependecyModal()}
                      </CustomeTaskField>
                    </Col> */}
                  </Row>
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField name={"INPUT FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                      </CustomeTaskField>
                    </Col>
                  </Row>
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField name={"LOOK UP FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                        {/* {DependecyModal()} */}
                      </CustomeTaskField>
                    </Col>
                  </Row>
                  <Row
                    sm={12}
                    className="margin-15"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #e4e4e4",
                    }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField
                        name={"ATTACHMENTS:"}
                        icon={<FontAwesomeIcon icon={faPaperclip} />}
                      >
                        <Button
                          title="Upload attachments"
                          onClick={() => {
                              setAttachmentModal(!attachmentModal)
                          }}
                          // className="compose-btns"
                          style={{ position: "relative" }}
                        >
                          Upload files
                        </Button>
                        <AttachmentModal
                          handleAttachementRemove={handleAttachementRemove}
                          setAttachmentModal={setAttachmentModal}
                          attachmentModal={attachmentModal}
                          handleUploadFiles={handleUploadFiles}
                          // data={props.dealObj}
                          fileNames={fileNames}
                          setFileNames={setFileNames}
                          uploadFiles={uploadFiles}
                        />
                      </CustomeTaskField>
                    </Col>
                    <Col sm={6}>
                    </Col>
                  </Row>
                  <Row className="margin-15">
                    <Tabs
                      activeKey={activeTab}
                      onTabClick={(key) => {
                        setActiveTab(key);
                      }}
                      defaultActiveKey={isSubTaskView ? "2" : "1"}
                      items={[
                        {
                          label: (
                            <Badge
                              color="blue"
                              count={taskData?.SubTasks?.length}
                              offset={[10, 0]}
                            >
                              <span>Sub Tasks</span>
                            </Badge>
                          ),
                          key: "1",
                          disabled: isSubTaskView,
                          children: (
                            <SubTaskTab
                              allPipelineData={allPipelineData}
                              groupFilter={""}
                              module={"View Task"}
                              viewTaskFunction={(data) => {
                                handleHistoryAdd();
                                setActiveTab("2");
                                viewTaskFunction(data,'SUBTASK')
                              }}
                              taskData={taskData}
                              key={"Task Name"}
                              selectedTable={selectedTable}
                              subTaskData={subTaskData}
                              setSubTaskData={setSubTaskData}
                              isSubTaskView={isSubTaskView}
                              setIsSubTaskView={setIsSubTaskView}
                              setPageRefreshData={setPageRefreshData}
                              refreshData={refreshData}
                            />
                          ),
                        },
                        {
                          label: (
                            <Badge color="blue" count={4} offset={[10, 0]}>
                              <span>Check List</span>
                            </Badge>
                          ),
                          key: "2",
                          children: <Checklist />,
                        },
                        {
                          label: (
                            <Badge color="blue" count={(selectedDependenciesCache?.Blocking?.length + selectedDependenciesCache?.WaitingOn?.length)} offset={[10, 0]}>
                              <span>Dependency</span>
                            </Badge>
                          ),
                          key: "3",
                          children: <DependencyModal 
                          selectedDependenciesCache={selectedDependenciesCache} 
                          getOptionsForDropdown={getOptionsForDropdown} 
                          handleDeleteDependency={handleDeleteDependency} 
                          allTasksAndSubtasks={allTasksAndSubtasks}
                          selectedDependencies={selectedDependencies}
                          dropdowncount={dropdowncount}
                          setSelectedDependencies={setSelectedDependencies}
                          filteredDropdownTasksData={filteredDropdownTasksData}
                          openTaskFromDependencies={openTaskFromDependencies}
                          setDropdownCount={setDropdownCount}
                          dropdownTasksDataCache={dropdownTasksDataCache}
                          handleSaveDependencies={handleSaveDependencies}
                          />,
                        },

                      ]}
                    />
                  </Row>
                </Col>
                {activitySection && taskModalState.action=="edit" && activitySection.open  && (
                  <Col className="Task-Activity-Wrapper" sm={5}>
                    <div className="Activity-Header">
                      <span className="header">{activitySection.key}</span>
                    </div>
                    <div className="Activity-Body">
                      {taskModalState.action == "edit" &&
                        activitySection.component(
                          isSubTaskView ? taskData?.SubTaskROWID : taskData?.TaskROWID ,
                          isSubTaskView
                        )}
                    </div>
                    <div className="Activity-Comment-Body">
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: "Comments",
                            children: (
                              <TextArea
                                name="Comment"
                                style={{ border: "none" }}
                                placeholder="Write Comment.."
                                value={comment}
                                onChange={(e) => {
                                  setComment(e.currentTarget.value)
                                }}
                                rootClassName={''}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="Task-Modal-Body-Child2">
              <div className="Task-Sidebar" sm={2}>
              {sidebarOptions &&
                  sidebarOptions.map((item, i) => {
                    if (item.key === "Settings") {
                      return taskModalState.action === "create" ? (
                        <div
                          className="mb-3 SidebarBtns"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "not-allowed",  // Change cursor to indicate non-clickable
                            opacity: 0.5,           // Optional: Make it look disabled
                          }}
                        >
                          <Badge
                            showZero={false}
                            count={i !== 0 ? 0 : 1}
                            color="#ec4242d1"
                            offset={[0, 0]}
                          >
                            <Button
                              style={{ border: "none" }}
                              icon={item.icon}
                              disabled  // Disable button click
                            />
                          </Badge>
                          <span>{item.key}</span>
                        </div>
                      ) : (
                        <Popover
                          content={renderSettingsPopoverContent()}
                          title=""
                          trigger="click"
                          open={popoverVisible}
                          onOpenChange={setPopoverVisible}
                          rootClassName="Duplicate-Modal"
                        >
                          <div
                            className="mb-3 SidebarBtns"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              showZero={false}
                              count={i !== 0 ? 0 : 1}
                              color="#ec4242d1"
                              offset={[0, 0]}
                            >
                              <Button
                                style={{ border: "none" }}
                                icon={item.icon}
                              />
                            </Badge>
                            <span>{item.key}</span>
                          </div>
                        </Popover>
                      );
                    }
                    return (
                      <div
                        className="mb-3 SidebarBtns"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          if (taskModalState.action === "create") {
                            e.preventDefault();
                            return;
                          }
                          let arr = [...sidebarOptions];
                          arr = arr.map((a, k) => {
                            if (i !== 0) {
                              e.preventDefault();
                            } else {
                              a.open = !a.open;
                            }
                            return a;
                          });
                          setSidebarOptions(arr);
                          setActivitySection(arr[0]);
                        }}
                      >
                        <Badge
                          showZero={false}
                          count={i !== 0 ? 0 : 1}
                          color="#ec4242d1"
                          offset={[0, 0]}
                        >
                          <Button
                            style={{ border: "none" }}
                            icon={item.icon}
                          />
                        </Badge>
                        <span>{item.key}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default CreateTaskModal;
