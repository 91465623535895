import React, { useContext, useEffect, useState } from "react";
import { TaskGroupCardForTemplate } from "../../../components/ProjectTasks/TaskGroupCardForTemplate";
import "./common.css";
import AddInlineData from "../../../components/ProjectTasks/AddInlineData";
import swal from "sweetalert";
import AppContext from "../../../Context/AppContext";
import BottomBar from "../../../components/ProjectTasks/BottomBar";
import { Button } from "antd";

export default function SubTaskTab({
  taskData,
  allPipelineData,
  module,
  viewTaskFunction,
  groupFilter,
  selectedTable,
  subTaskData,
  setSubTaskData,
  isSubTaskView,
  setIsSubTaskView,
  setPageRefreshData,
  refreshData
}) {
  const AppData = useContext(AppContext);
  const { state } = AppData || {};

  const { isTaskModalClosed } = state;

  let user = JSON.parse(localStorage.getItem("userinfo"));
  const [isAdding, setIsAdding] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [selectedIdsForTemp, setSelectedIdsForTemp] = useState({Task:[],Subtask:[]});
  const [subEditData, setSubEditData] = useState({
    id: Date.now(),
    Assignee: "",
    Priority: "",
    CREATEDBY: user.email_id,
    Supervisor: "",
    TaskDescription: "",
    TaskName: "",
    TimeEstimate: "",
  });

  console.log("taskData", taskData);
  

  useEffect(
    (e) => {
      if (isTaskModalClosed) {
        setSubEditData({
          id: Date.now(),
          Assignee: "",
          Priority: "",
          CREATEDBY: user.email_id,
          Supervisor: "",
          TaskDescription: "",
          TaskName: "",
          TimeEstimate: "",
          PipelineROWID: taskData?.PipelineROWID
        });
        setSelectedIdsForTemp({Task:[],Subtask:[]})
        setIsAdding(false);
      }
    },
    [isTaskModalClosed]
  );

  useEffect(() => {
    if (taskData?.PipelineROWID && isAdding) {
      let updatedSubTaskData = subTaskData.map((item) => {
        return { ...item, PipelineROWID: taskData.PipelineROWID }; 
      });
      // If you want to update a state or pass this data somewhere, you can do it here:
      setSubTaskData(updatedSubTaskData); // Assuming you have a setState function
    }
  }, [taskData?.PipelineROWID, isAdding]);
  

 const handleAddRow = () => {
    setIsAdding(true);
    setSubTaskData([...subTaskData, subEditData]);
  };

  useEffect(
    (e) => {
      if (idToDelete) {
        let updatedTasks = [...subTaskData];
        let fileterdData = updatedTasks?.filter(
          (item) => item?.id != idToDelete
        );
        setSubTaskData(fileterdData);
        setIdToDelete("");
        setIsAdding(false);
      }
    },
    [idToDelete]
  );

  // Save the data (simulate API call)
  const handleSave = () => {
    // Find the index of the object with the same id in subTaskData
    if(!subEditData?.TaskName){
      swal('Alert','Task Name can not be empty!',"warning")
      return
    }
    subEditData["flag"] = true;
    const existingIndex = subTaskData.findIndex(
      (item) => item.id === subEditData.id
    );

    // Update the object if it exists, otherwise add subEditData as a new object
    if (existingIndex !== -1) {
      // Update the existing object with subEditData
      const updatedTaskData = [...subTaskData];
      updatedTaskData[existingIndex] = {
        ...subTaskData[existingIndex],
        ...subEditData,
      };
      setSubTaskData(updatedTaskData);
    } else {
      // Add new object if no matching id is found
      setSubTaskData([...subTaskData, subEditData]);
    }
    // Reset subEditData to default values
    setSubEditData({
      id: Date.now(),
      Assignee: "",
      Priority: "",
      CREATEDBY: user.email_id,
      Supervisor: "",
      TaskDescription: "",
      TaskName: "",
      TimeEstimate: "",
    });

    setIsAdding(false);
  };

  return (
    <div className="Subtask-Table">
      {/* <div className="add-row-btn">
        <Button
          // disabled={!pipelineData?.PipelineName}
          onClick={() => {
            // setOpenTaskModal(true);
          }}
        >
          Add Task
        </Button>
      </div> */}
      <AddInlineData
        onAdd={(e) => {
          handleAddRow(e);
        }}
        onSave={(e) => {
          handleSave();
        }}
        isAdding={isAdding}
        name={"Add SubTask"}
      />
      <TaskGroupCardForTemplate
        editData={subEditData}
        setEditData={setSubEditData}
        module={module}
        taskData={taskData}
        viewTaskFunction={viewTaskFunction}
        groupFilter={groupFilter}
        allPipelineData={allPipelineData}
        data={subTaskData}
        header={"Task Name"}
        selectedTable={selectedTable}
        isSubTaskView={isSubTaskView}
        setIdToDelete={setIdToDelete}
        setIsSubTaskView={setIsSubTaskView}
        setPageRefreshData={setPageRefreshData}
        selectedIdsForTemp={selectedIdsForTemp}
        setSelectedIdsForTemp={setSelectedIdsForTemp}
      />

    {(selectedIdsForTemp?.Task?.length > 0 || selectedIdsForTemp?.Subtask?.length > 0) && 
          <BottomBar 
            selectedIds={selectedIdsForTemp}
            setSelectedIds={setSelectedIdsForTemp} 
            setPageRefreshData={setPageRefreshData} 
            refreshData={refreshData}
            selectedTable={selectedTable}
            subTaskData={subTaskData}
            setSubTaskData={setSubTaskData}
            setIsAdding={setIsAdding}
            />
        }

    </div>
  );
}
