import React, { useState } from "react";
import { Button, Select, Tag } from "antd";
import { Row, Col } from "reactstrap";
import "./BottomBar.css"; // Include any custom styling here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendarAlt,
  faDotCircle,
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import AwsServerService from "../../common/util/AwsServerService";
import swal from "sweetalert";
import { CloseOutlined, FlagFilled } from "@ant-design/icons";
import { UserPicker } from "../../common/UserPicker/UserPicker";
import { getPriorityValue } from "../HR/TimeClock/Util";
import { Option } from "antd/es/mentions";

const BottomBar = ({
  selectedIds,
  setSelectedIds,
  setPageRefreshData,
  selectedTable,
  subTaskData,
  setSubTaskData,
  setIsAdding
}) => {
  // console.log("selectedIds bottom bar", selectedIds);

  let count =
    Number(selectedIds?.Task?.length) + Number(selectedIds?.Subtask?.length);

  const DeleteTasks = async () => {
    swal({
      title: "Are you sure you want to delete selected tasks/subtasks?",
      text: "",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let taskDeletionPromises;
        let subtaskDeletionPromises;
        try {
          if (selectedTable === "tasks") {
            taskDeletionPromises = selectedIds?.Task?.length
              ? selectedIds.Task.map((item) =>{
                if(subTaskData){
                  subTaskData = subTaskData?.filter(k => k?.id != item)
                  setSubTaskData(subTaskData);
                  return
                }
                AwsServerService.deleteTask(item)
              }
                )
              : [];

            subtaskDeletionPromises = selectedIds?.Subtask?.length
              ? selectedIds.Subtask.map((item) =>{
                  AwsServerService.deleteSubtask(item)
                })
              : [];
          } else {
            taskDeletionPromises = selectedIds?.Task?.length
              ? selectedIds.Task.map((item) =>{
                if(subTaskData){
                  subTaskData = subTaskData?.filter(k => k?.id != item)
                  setSubTaskData(subTaskData);
                  return
                }
                AwsServerService.deleteTaskInTemplate(item)
              }
                )
              : [];

            subtaskDeletionPromises = selectedIds?.Subtask?.length
              ? selectedIds.Subtask.map((item) =>
                  AwsServerService.deleteSubtaskInTemplate(item)
                )
              : [];
          }
          const allRes = await Promise.all([
            ...taskDeletionPromises,
            ...subtaskDeletionPromises,
          ]);
          setSelectedIds({ Task: [], Subtask: [] });
          setPageRefreshData((prev) => !prev);
          setIsAdding(false);
        } catch (err) {
          console.log("Some error occurred deleting tasks or subtasks", err);
        }
      }
    });
  };

  const updateDataForFields = (e,field) =>{
    swal({
      title: `Are you sure you want to change the ${field}?`,
      text: "",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        try {
          if (selectedTable === "tasks") {
            if(selectedIds?.Task?.length > 0){
             await AwsServerService.updateAssignee(e,selectedIds?.Task,field,"Tasks")
            }
            if(selectedIds?.Subtask?.length > 0){
              await AwsServerService.updateAssignee(e,selectedIds?.Subtask,field,"SubTasks")
            }
          } else {
            if(selectedIds?.Task?.length > 0){
              await AwsServerService.updateAssignee(e,selectedIds?.Task,field,"TaskTemplate")
            }
            if(selectedIds?.Subtask?.length > 0){
              await AwsServerService.updateAssignee(e,selectedIds?.Subtask,field,"SubTaskTemplate")
            }
          }
          setSelectedIds({ Task: [], Subtask: [] });
          setPageRefreshData((prev) => !prev);
        } catch (err) {
          console.log(`Some error occurred updating ${field}`, err);
        }
      }
    });
  }

  const copyTasksToClipboard = async () => {
    // const taskNames = selectedIds?.Task?.map((task) => task.name).join(", ") || "No tasks selected";
    // try {
    //   await navigator.clipboard.writeText(taskNames);
    //   setCopied(true);
    //   setTimeout(() => setCopied(false), 2000);  // Reset copy status after 2 seconds
    // } catch (err) {
    //   console.error("Failed to copy tasks to clipboard: ", err);
    // }
  };
  
  return (
    <Row className="bottom-bar" align="start">
      <Col sm={2}>
        <Tag
          closable
          onClose={() => {
            setSelectedIds({ Task: [], Subtask: [] });
          }}
          closeIcon={<CloseOutlined style={{ color: "#000" }} />}
          className="Bottom-Bar-Count-Tag"
        >
          {`${count} Tasks selected`}
        </Tag>
      </Col>
      <Col sm={10} style={{ display: "flex", justifyContent: "space-around" }}>
        {/* <Col> */}
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faDotCircle} opacity={0.5} />}
        >
          Status
        </Button>
        {/* </Col> */}
        {/* <Col> */}
        <UserPicker
          rootClassName={"table-dropdown"}
          className={"No-Border"}
          mode="multiple"
          onChange={(e) => {
            updateDataForFields(e,"Assignee")
          }}
          isbottombar={true}
          placeholder={"Assignees"}
        />
        {/* </Col> */}
        {/* <Col> */}
        <UserPicker
          rootClassName={"table-dropdown"}
          className={"No-Border"}
          mode="multiple"
          onChange={(e) => {
            updateDataForFields(e,"Supervisor")
          }}
          isbottombar={true}
          placeholder={"Supervisor"}
        />
        {/* </Col> */}
        {/* <Col> */}
        <Select
          className="No-Border priority-icon white-placeholder"
          name="Priority"
          placeholder="Priority"
          style={{
            width: 200,
            "::placeholder": { color: "#fff" },
          }}
          onChange={(e) => {
            updateDataForFields(e,"Priority")
          }}
          rootClassName="table-dropdown"
          optionLabelProp="icon"
        >
          <Option
            value="Not Set"
            icon={<span style={{ color: "#fff" }}>{"Priority"}</span>}
          >
            <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
            Set
          </Option>
          <Option
            value="Low"
            icon={<span style={{ color: "#fff" }}>{"Priority"}</span>}
          >
            <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
          </Option>
          <Option
            value="Medium"
            icon={<span style={{ color: "#fff" }}>{"Priority"}</span>}
          >
            <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
          </Option>
          <Option
            value="High"
            icon={<span style={{ color: "#fff" }}>{"Priority"}</span>}
          >
            <FlagFilled style={{ color: getPriorityValue("High") }} /> High
          </Option>
        </Select>
        {/* </Col> */}
        {/* <Col> */}
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faCalendarAlt} opacity={0.5} />}
        >
          Dates
        </Button>
        {/* </Col> */}
        {/* <Col> */}
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faArrowRight} opacity={0.5} />}
        >
          Move/Add
        </Button>
        {/* </Col> */}
        {/* <Col> */}
        <Button
          type="link"
          icon={<FontAwesomeIcon icon={faCopy} opacity={0.5} />}
          onClick={copyTasksToClipboard}
        >
          Copy
        </Button>
        {/* </Col> */}
        {/* <Col> */}
        <Button
          type="link"
          icon={
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="bottombar-delete"
              style={{ cursor: "pointer" }}
            />
          }
          onClick={DeleteTasks}
        >
          Delete
        </Button>
        {/* </Col> */}
      </Col>
    </Row>
  );
};

export default BottomBar;
