export const findCircularConnection = (edge, edgesArray) => { 
    // Create an adjacency list from the edges array
    const adjacencyList = {};
    const edgeMap = {}; // To map each source-target pair to the actual edge object
  
    edgesArray.forEach((e) => {
      const { source, target } = e;
      if (!adjacencyList[source]) {
        adjacencyList[source] = [];
      }
      adjacencyList[source].push(target);
      edgeMap[`${source}-${target}`] = e; // Map the source-target pair to the edge
    });
  
    const visited = new Set();
    const path = []; // To track the current path of edges
    let cycleEdges = []; // To store the edges that form the cycle
  
    // Helper function to perform DFS
    const dfs = (currentNode, targetNode) => {
      if (currentNode === targetNode) {
        // We've found a cycle, add the edges to cycleEdges array
        path.forEach((edge) => {
          cycleEdges.push(edge);
        });
        return true;
      }
  
      visited.add(currentNode);
  
      if (adjacencyList[currentNode]) {
        for (let neighbor of adjacencyList[currentNode]) {
          if (!visited.has(neighbor)) {
            // Add the edge to the path
            const edgeKey = `${currentNode}-${neighbor}`;
            path.push(edgeMap[edgeKey]);
  
            // Perform DFS on the neighbor
            const result = dfs(neighbor, targetNode);
            if (result) {
              return true; // If a cycle is found, stop further searching
            }
  
            // If no cycle found, backtrack (remove the edge from the path)
            path.pop();
          }
        }
      }
  
      return false;
    };
  
    // Start DFS from the target of the given edge and try to reach the source
    visited.clear(); // Clear visited nodes
    dfs(edge.target, edge.source); // Check if we can reach the source node from the target node
  
    // Now, remove the `visited` key from the cycled edges and return the modified array
    const result = edgesArray.map((e) => {
      // If the edge is in the cycleEdges array, return a copy without the `visited` key
      if (cycleEdges.includes(e)) {
        const { visited, ...rest } = e;
        return { ...rest };
      }
      return e; // Return the edge as is if not part of the cycle
    });
  
    return result;
  };