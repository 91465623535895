import React, { useEffect, useReducer } from 'react';
import AppContext from './AppContext';

const initialState = JSON.parse(localStorage.getItem('contextState')) || {
    AccessData: {},
    Token: null,
    SecretCode: null,
    EditDealData: {},
    CreateDealData: {},
    NycApiData: {},
    AllDeals: [],
    AllPipelines:[],
    AllAccounts: [],
    ClientServices: [],
    ClientFullServices: [],
    SaveClickedForClient: false,
    ClientLayoutChanged: false,
    ServicesForClient: {},
    SelectedIdsForTimeClock: {},
    ActionsOfTimeClock: "",
    AllNotificationsForChat: [],
    forwardObject: {module:null,data:null,state:""},
    CustomeRefreshButton:false,
    isDeleteEnable:true,
    globalRefresh:false,
    socket:null,
    cacheReminderData:[],
    isReminderPopupOpen:{status:false,email:null},
    reminderData:{},
    addTimeSheetClicked:{module: "", button: ""},
    hrActiveKey:{tab : "timeSheet", view : ""},
    billingTerms: [],
    refershModule:{module:"",data:""}, 
    taskActiveKey:{tab : "tasks", view : ""},
    isTaskModalClosed: null,
    isPipelineModalClosed: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SECRET_CODE':
            return {
                ...state,
                SecretCode: action.payload,
            }
        case 'ACCESS_DATA':
            return {
                ...state,
                AccessData: action.payload,
            }
        case 'TOKEN':
            return {
                ...state,
                Token: action.payload,
            }
        case 'EDIT_DEAL_DATA':
            return {
                ...state,
                EditDealData: action.payload,
            }
        case 'CREATE_DEAL_DATA':
            return {
                ...state,
                CreateDealData: action.payload,
            }
        case 'NYC_API_DATA':
            return {
                ...state,
                NycApiData: action.payload,
            }
        case 'ALL_DEALS':
            return {
                ...state,
                AllDeals: action.payload,
            }
        case 'ALL_ACCOUNTS':
            return {
                ...state,
                AllAccounts: action.payload,
            }
        case 'ALL_CHAT_NOTIFICATIONS':
            return {
                ...state,
                AllNotificationsForChat: action.payload,
            }
        case 'CLIENT_SERVICES':
            return {
                ...state,
                ClientServices: action.payload,
            }
        case 'CLIENT_FULL_SERVICES':
            return {
                ...state,
                ClientFullServices: action.payload,
            }
        case 'SAVE_CLIENT_CLICK':
            return {
                ...state,
                SaveClickedForClient: action.payload,
            }
        case 'CLIENT_LAYOUT_CLICK':
            return {
                ...state,
                ClientLayoutChanged: action.payload,
            }
        case 'SERVICES_FOR_CLIENT':
            return {
                ...state,
                ServicesForClient: action.payload,
            }
        case 'SELECTED_IDS_FOR_TIMECLOCK':
            return {
                ...state,
                SelectedIdsForTimeClock: action.payload,
            }
        case 'ACTIONS_OF_TIMECLOCK':
            return {
                ...state,
                ActionsOfTimeClock: action.payload,
            }
            case 'TIMESHEET_ENTRY_OBJECT':
            return {
                ...state,
                timesheetEntryObject: action.payload,
            }
        case 'CASH_DATA_OF_TIMESHEET':
            return {
                ...state,
                CacheDataOfTimesheet: action.payload,
            }
        case 'CUSTOME_REFRESH_BTN':
            return {
                ...state,
                CustomeRefreshButton: action.payload,
            }
        case 'IS_DELETE_ENABLE':
            return {
                ...state,
                isDeleteEnable: action.payload,
            }
        case 'ADD_TIMESHEET':
            return {
                ...state,
                addTimeSheetClicked: action.payload,
            }
        case 'HR_ACTIVE_KEY':
            return {
                ...state,
                hrActiveKey: action.payload,
            }
        case 'SERVICE_ADDERS_CLICKABLE':
            return {
                ...state,
                isServiceAddersClickable: action.payload,
            }
        case 'BILLING_TERMS':
            return {
                ...state,
                billingTerms: action.payload,
            }
        case 'GlobalRefresh':
            return {
                ...state,
                globalRefresh: action.payload,
            }
        case 'Socket':
            return {
                ...state,
                socket: action.payload,
            }
        case 'CacheReminderData':
            return {
                ...state,
                cacheReminderData: action.payload,
            }
        case 'setIsReminderPopupOpen':
            return {
                ...state,
                isReminderPopupOpen: action.payload,
            }
        case 'setReminderData':
            return {
                ...state,
                reminderData: action.payload,
            }
        case 'refershModule':
            return {
                ...state,
                refershModule: action.payload,
            }
        case 'forwardObject':
            return {
                ...state,
                forwardObject: action.payload,
            }
        case 'TASK_ACTIVE_KEY':
            return {
                ...state,
                taskActiveKey: action.payload,
            }
        case 'IS_TASK_MODAL_CLOSED':
            return {
                ...state,
                isTaskModalClosed: action.payload,
            }
        case 'IS_PIPELINE_MODAL_CLOSED':
            return {
                ...state,
                isPipelineModalClosed: action.payload,
            }
        case 'ALL_PIPELINES':
            return {
                ...state,
                AllPipelines: action.payload,
            }
        default:
            return state;
    }
}

const AppStore = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    // useEffect(() => {
    //     localStorage.setItem('contextState', JSON.stringify(state));
    // }, [state])


    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppStore;