import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { FormGroup, Input, UncontrolledTooltip } from 'reactstrap';
import { Popover as AntdPopover, Switch } from 'antd';
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';

export default function GetElementsGroupBy({ itemsTaskGroup, itemDealGroup, groupByFilter, setGroupFilter }) {
    const [isOpen, setIsOpen] = useState(false);
    const [tempGroupBy, setTempGroupBy] = useState({ ...groupByFilter });
    const getSearchBox = (module) => {
        return <FormGroup className="group-by-search" style={{ position: 'relative' }} >
            {/* <Input

                type="text"
                placeholder="Search"
                className="mr-sm-2 pad-fix search-filter"
                onChange={(e) => {
                    if (module == 'Task') {
                        let items = [...groupByTaskItem]
                        if (e.currentTarget.value !== '') {
                            items = items.filter((one) => one.name.toLowerCase().includes(e.currentTarget.value.toLowerCase()))
                        }
                        props.setItemTaskGroup(items)
                    } else {
                        let items = [...props.groupByDealItem]
                        if (e.currentTarget.value !== '') {
                            items = items.filter((one) => one.name.toLowerCase().includes(e.currentTarget.value.toLowerCase()))
                        }
                        props.setItemDealGroup(items)
                    }

                }}
            // value={searchValue}
            // onChange={(e) => onSearchColumns(e.target.value)}
            /> */}
            <FontAwesomeIcon
                className="mr-sm-2 search-ico"
                icon={faSearch}
            />
        </FormGroup>
    }
    const changeGroupBy = (data, key) => {
        // const group = { ...props.cacheGroupBy };
        // const deals = [...props.DropDownDeals];
        // group[name] = e
        // props.setCacheGroupBy(group); 
        let obj = { ...tempGroupBy };
        obj[key] = data;
        setTempGroupBy(obj)
    }
    const getElement = () => {
        return <> <UncontrolledTooltip
            placement="top"
            target="taskfilter-popup"
        >
            Group By  Deal : {tempGroupBy?.group1.name}<br></br>
            Group By  Task : {tempGroupBy?.group2.name}
        </UncontrolledTooltip>
            <AntdPopover
                style={{ height: 300 }}
                placement="bottom"
                trigger="click"
                overlayClassName="group-by-popover"
                open={isOpen}
                content={<OtsideClickClosingWrapper
                    className="group-by-tasklist"
                    close={e => {
                        setIsOpen(false);
                    }}
                >
                    <div className="row group-by-tasklist" style={{ width: 400 }}>
                        <div onClick={e => e.stopPropagation()} style={{ borderRight: '1px solid lightgray' }} className="col deal-group-by">
                            <div class="list-group-item-header">
                                <span> GROUP BY DEAL</span>

                                {getSearchBox('Deal')}
                            </div>
                            <ul class="list-group">

                                {itemDealGroup?.map((one) => {
                                    return <li onClick={(e) => changeGroupBy(one, 'group1')} className={one.name == tempGroupBy?.group1.name ? "list-group-item active" : "list-group-item"}>{one.name}</li>
                                })}
                            </ul>
                        </div>
                        <div onClick={e => e.stopPropagation()} className="col task-group-by">
                            <div class="list-group-item-header">
                                <span> GROUP BY TASK</span>

                                {getSearchBox('Task')}
                            </div>
                            <ul class="list-group">
                                {/* <li class="list-group-item">
                                <span>GROUP BY TASK</span>
                                {getSearchBox('Task')}
                            </li> */}
                                {/* <li class="list-group-item ">
                          
                            </li> */}
                                {itemsTaskGroup?.map((one) => {
                                    return <li onClick={(e) => changeGroupBy(one, 'group2')} className={one.name == tempGroupBy?.group2.name ? "list-group-item active" : "list-group-item"}>{one.name}</li>
                                })}
                            </ul>
                        </div>

                    </div><div className="row">
                        <div className="col ">
                            <span onClick={() => {
                                setIsOpen(false)
                                setGroupFilter(tempGroupBy);
                            }} className="float-right btn btn-sm btn-primary">Apply</span>
                        </div>
                    </div>
                </OtsideClickClosingWrapper>}
            >
                <span id="taskfilter-popup" onClick={() => setIsOpen(!isOpen)} style={{ display: 'flex', cursor: 'pointer', padding: '0px 8px' }} >Group By
                    <p style={{ color: '#0194f7', marginLeft: 3, marginBottom: '0px' }}>{tempGroupBy?.group1.name},{tempGroupBy?.group2.name} <FontAwesomeIcon icon={faChevronDown} /></p></span>

            </AntdPopover>
        </>
    }
    return (
        <div onClick={() => setIsOpen(!isOpen)}>{getElement()}</div>
    )
}