import { MarkerType } from "@xyflow/react";
import React, { useCallback, useEffect, useState } from "react";
import './Flow.css';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState
} from "reactflow";
import "reactflow/dist/style.css";
import EditableCard from "./EditableCard";
import CustomNode from "./HelperComponents/CustomNode";
import { getRandomNumberExcluding } from "../HR/TimeClock/Util";
import AwsServerService from "../../common/util/AwsServerService";
import CustomEdge from "./HelperComponents/CustomEdge";
const onInit = (reactFlowInstance) => {
  console.log("flow loaded:", reactFlowInstance);
}
const nodeTypes = {
  custom: CustomNode,
};
const edgeTypes = {
  "custom-edge": (params) => {
    console.log("params--------", params);
    return <CustomEdge {...params} />
  },
};
export const Flow = ({ editFlow, onNodeSelect, onEdgeSelected, selectedData, onSelectedData, addNodeButton, resetAddNodeButton, saveCallback }) => {
  // const [selectedEdge, setSelectedEdge] = useState(null);

  let newNodeTemplate = {
    id: "1",
    type: "custom",
    data: {
      label: "New Node",
      color: "#fff5e0",
    },
    width: 100,
    height: 50,
    position: { x: 700, y: 0 }
  }
  const nodesDummy = [
    {
      id: "1",
      type: "custom",
      data: {
        label: "Start"
      },
      position: { x: 250, y: 0 },
      draggable: false,
      NonSelectable: true
    },
  ];

  const edgesDummy = [

  ];
  const [nodes, setNodes, onNodesChange] = useNodesState(nodesDummy);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesDummy);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const onEdgeClick = (event, edge) => {
    onEdgeSelected(edge);
    // console.log('Edge clicked:', edge);
  };

  const handleEdgeUpdate = (updatedEdge) => {
    setEdges((eds) => eds.map((e) => (e.id === updatedEdge.id ? updatedEdge : e)));
    onEdgeSelected(null);
  };
  const onEdgeDelete = (edgeId) => {
    setEdges((eds) => eds.filter((e) => e.id !== edgeId));
  };
  const deleteNodeRelatedEdges = (nodeid) => {
    let tempEdges = edges.filter(edge => !(edge.source == nodeid || edge.target == nodeid))
    setEdges(tempEdges);
  }
  const updateEditedData = ({ type, action, data }) => {
    if (type == "node") {
      if (action != "delete") {
        let tempNodes = nodes.map(node => {
          if (node.id == data.id) {
            return data;
          } else {
            return node;
          }
        })
        setNodes(tempNodes);
      } else {
        let tempNodes = nodes.filter(node => node.id != data.id)
        deleteNodeRelatedEdges(data.id);
        setNodes(tempNodes);
      }
    } else if (type == "edge") {
      if (action != "delete") {
        let tempEdges = edges.map(edge => {
          if (edge.id == data.id) {
            return data;
          } else {
            return edge;
          }
        })
        setEdges(tempEdges);
      } else {
        let tempEdges = edges.filter(edge => edge.id != data.id)
        setEdges(tempEdges);
      }
    } else if (type == "flow") {
      handleSave(action, data);
    } else if (type == "edit flow") {
      if (data.Nodes) {
        setNodes([...data.Nodes])
      }
      if (data.Edges) {
        setEdges([...data.Edges])
      }
      onSelectedData({
        type: "",
        action: "",
        data: ""
      })
    }
  }
  useEffect(e => {
    updateEditedData(selectedData)
  }, [selectedData])
  useEffect(e => {
    if (addNodeButton == "add") {
      let temp = [...nodes]
      let tempNode = { ...newNodeTemplate }
      tempNode["id"] = getRandomNumberExcluding(nodes.map(n => n.id));
      temp.push(tempNode);
      setNodes(temp);
      resetAddNodeButton(null);
    }
  }, [addNodeButton])

  const handleSave = async (action, data) => {
    if (action == "create") {
      let temp = {
        "CREATEDBY": localStorage.getItem("ZUID"),
        "Name": data.name,
        "Edges": edges,
        "Description": data.description,
        "Nodes": nodes
      }
      await AwsServerService.createTaskFlow(temp).then(res => {
        setNodes(nodesDummy)
        setEdges([])
        saveCallback(res)
      }).catch(err => {
        console.log(err);
      })
    } else if (action == "edit" && editFlow.ROWID) {
      let temp = {
        "ROWID": editFlow.ROWID,
        "MODIFIEDBY": localStorage.getItem("ZUID"),
        "Name": data.name,
        "Edges": edges,
        "Description": data.description,
        "Nodes": nodes
      }
      await AwsServerService.updateTaskFlow(temp).then(res => {
        setNodes(nodesDummy)
        setEdges([])
        saveCallback(res)
      }).catch(err => {
        console.log(err);
      })
    }
  }
  return (
    <ReactFlow
      className="Blueprint-flow-wrapper"
      nodes={nodes}
      onNodeClick={(event, node) => {
        if (node.hasOwnProperty('NonSelectable')) {
          return
        }
        onNodeSelect({ ...node });
      }}
      onEdgeClick={(event, edge) => {
        // console.log("on edge click ",edge);
        onEdgeSelected({ ...edge });
      }}
      edges={edges}
      onNodesChange={(params) => {
        debugger
        onNodesChange(params)
      }}
      onEdgesChange={(params) => {
        debugger
        onEdgesChange(params);
      }}

      onConnect={(params) => {
        // console.log("-------",params);
        onConnect({
          ...params,
          type: "custom-edge",
          markerEnd: {
            type: MarkerType.ArrowClosed
          }
        })
      }}
      onInit={onInit}
      fitView
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      attributionPosition="top-right"
    >
      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === "input") return "#0041d0";
          if (n.type === "output") return "#ff0072";
          if (n.type === "default") return "#1a192b";

          return "#eee";
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return "#fff";
        }}
        nodeBorderRadius={2}
      />
      <Controls />
      <Background color="black" gap={16} />
    </ReactFlow>
  );
}