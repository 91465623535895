import React, { useContext, useEffect, useState } from 'react'
import './TaskBlueprints.css'
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { GlobalAgGridTable } from '../Deal/AgGridReactComponent/GlobalAgGridTable';
import { CreateBlueprint } from './CreateBlueprint';
import AwsServerService from '../../common/util/AwsServerService';
import AppContext from '../../Context/AppContext';
import UserService from '../../common/util/UserService';
import swal from "sweetalert";
import { Button } from 'antd';
import { refershModule, Socket } from '../../Context/AppActions';
const TaskBlueprints = ({history,location}) => {
  const AppData = useContext(AppContext);
  const { dispatch ,state} = AppData || {};
  const [view,setView] = useState("list");
  const [refresh,setRefresh] = useState(false);
  const [editFlow,setEditFlow] = useState();
  const [allBlueprintData,setAllBlueprintData] = useState([])
  const [cacheAllBlueprintData,setCacheAllBlueprintData] = useState([])

  const [allUsers,setAllUsers]=useState([]);
  const [allUserRoles,setAllUserROles]=useState([]);

  useEffect(()=>{
    UserService.GetAllUsers().then(r=>{
     setAllUsers(r.data.users)
    })
    UserService.GetAllUserRoles().then(e=>{
     setAllUserROles(e.data.roles)
    })
  },[])

  function actionListCellRenderer(params) {  
    console.log(params);
    let btnDiv=document.createElement("div");
    btnDiv.classList.add("global-action-btn-for-tables");
    
    let editBtn=document.createElement('button');
    editBtn.classList.add("add")
    editBtn.innerText="Edit"
    editBtn.id=params.ROWID;
    editBtn.addEventListener('click',(e)=>{  
      let Rowid=e.currentTarget.id;
      let flow=allBlueprintData?.find(f=>f.ROWID==Rowid);
      if(flow){
        setEditFlow(flow);
        setView("edit")
      }
    })
    let deleteBtn=document.createElement('button');
    deleteBtn.classList.add("add")
    deleteBtn.style.color="red";
    deleteBtn.innerText="Delete";
    deleteBtn.id=params.ROWID;
    deleteBtn.addEventListener('click',(e)=>{ 
      let Rowid=e.currentTarget.id;
      swal({
        title: "Warning!",
        text: "Are you sure you want to delete the flow?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: "Proceed",
        },
      }).then(async(isDelete)=>{
        if(isDelete){
          AwsServerService.deleteTaskFlow(Rowid).then(res=>{
            dispatch(Socket({trigger:"Refresh_Module",data:"Task Flow"}))
          })
        }
      })})
    btnDiv.appendChild(editBtn);
    btnDiv.appendChild(deleteBtn);
    return btnDiv;
  }
  const columnForZoho = [
    {
      field: "Name",
      name: "Task Flow Name",
      path: "Name",
      type:"text",
      label: "Task Flow Name",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: (obj) => (
        <label 
        // hidden={selectedTbl === "dayView"} 
        >
          {obj.Name}
        </label>
      ),
    },
    {
      field: "Description",
      name: "Description",
      path: "Description",
      type:"text",
      label: "Description",
      id : 1,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: (obj) => (
        <label 
        // hidden={selectedTbl === "dayView"} 
        >
          {obj.Description}
        </label>
      ),
    },
    {
      field: "Action",
      key: "Action",
      name: "Action",
      path: "Action",
      type:"text",
      label: "Action",
      id : 2,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: (obj) => {
        return actionListCellRenderer(obj)
      }
    },
  ];
    useEffect(e=>{
      if(state.refershModule?.module=="Task Flow"){
        setRefresh(!refresh);
        dispatch(refershModule(null))
      }
    },[state.refershModule])
    useEffect(()=>{
      AwsServerService.getAllTaskFlows().then(res=>{
        setAllBlueprintData(res);
        setCacheAllBlueprintData(res);
      }).catch(err=>{
        console.log(err);
      })
    },[refresh])
    const extraComponentToRight=(e)=>{
      return (
        <div className="extra-component-request-toRight">
          <div className="job-action-box">
            <Button
              type='primary'
              className="mr-3 request-btns"
              onClick={() => {
                setView("create");
              }}
            >
              Create Task Flow
            </Button>
          </div>
        </div>
      );
    }
  return (
    <div className='Blueprint-Wrapper'>
        {/* <div className='Blueprint-Header'>
            <Row className=''>
                <Col className='d-flex align-items-center' sm={4}><FontAwesomeIcon className='logo' icon={faProjectDiagram} /><span style={{fontSize:"1rem",fontWeight:"600", marginLeft:"10px"}}>Blueprints</span></Col>
                <Col sm={4}></Col>
                <Col sm={4}></Col>
            </Row>
        </div> */}
        <div id="myGrid" className='ag-theme-alpine Blueprint-Body'>
          {view == "list" ? <GlobalAgGridTable
            headers={columnForZoho}
            setRowData={setAllBlueprintData}
            cacheRowData={cacheAllBlueprintData}
            setCacheRowData={setCacheAllBlueprintData}
            module="Task Blueprint"
            mainUrl="blueprint"
            // DropDownFields={["User Name", "Check In", "Check Out"]}
            rowData={allBlueprintData}
            renderExtraComponent={()=><></>}
            renderExtraComponentToRight={extraComponentToRight}
            isTaskFilterHide={true}
          /> : view == "create" || view == "edit"? 
          <CreateBlueprint
            setEditFlow={setEditFlow}
            editFlow={editFlow}
            view={view }
            close={()=>{
              setView("list")
              setEditFlow(null);
            }}
            allUserRoles={allUserRoles}
            allUsers={allUsers}
          />:<></>}
        </div>
    </div>
  )
}

export default TaskBlueprints
