import React, { useState } from 'react'
import {
    Modal as AntModal,
    Button as AntButton,
    Select as AntSelect,
    Input,
    Popover,
    Tabs,
    Button,
    Badge,
    theme,
    Select,
    Tooltip,
    Checkbox,
  } from "antd";
import './DependencyModal.css'
import { faCheckCircle, faCircle, faClock, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { CaretRightOutlined } from '@ant-design/icons';
import _ from 'lodash';
  
export default function DependencyModal({ handleSaveDependencies,dropdownTasksDataCache,setDropdownCount,openTaskFromDependencies,getOptionsForDropdown,selectedDependenciesCache,handleDeleteDependency,allTasksAndSubtasks,selectedDependencies,dropdowncount,setSelectedDependencies,filteredDropdownTasksData}) {
    const [isDependencyModalOpen, setIsDependencyModalOpen]=useState(false);
    const [collapsed, setCollapsed] = useState({ WaitingOn: true, Blocking: true });

    const toggleCollapse = (section) => {
        setCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    };
    return (
    <div className='dependencyClassDiv'>
        <AntButton
          onClick={() => {
            // setIsDependencyModalOpen(true);
            handleSaveDependencies(selectedDependencies);
          }}
          color="primary"
          type="primary"
        >
          {_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}
        </AntButton>
        <div className='subDivForDependencyClass' style={{marginTop:'20px'}}>
                <Row style={{textIndent:'3rem',backgroundColor: '#e2e5e9' ,display: 'flex',alignItems: 'center'}} onClick={()=>toggleCollapse('WaitingOn')}>
                 <label htmlFor="status" className='labelStatus'>
                 <CaretRightOutlined rotate={collapsed.WaitingOn ? 90 : 0}/>
                    <strong>Waiting on {selectedDependenciesCache.WaitingOn.length}</strong>
                 </label>
                </Row>
                {collapsed.WaitingOn && <Row>
                   <Col sm={2}></Col>
                    <Col sm={10}>
                    <div className="waitingOndivs">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.WaitingOn.map((e,index)=>{
                        let type=e.value.split('_');
                        let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRenderTaskDiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>  
                            {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                            <div style={{cursor: 'pointer'}} onClick={()=>{
                              openTaskFromDependencies(e);
                            }}>
                            {e.label}
                          </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
                              let [type,id]=e.value.split('_'); 
                            }}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{  
                            handleDeleteDependency(e,'waiting');
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>
                          }
                         )
                      }
                     </div>
                     {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
                    { dropdowncount.WaitingOn==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    style={{width: '95%', marginLeft: '1em'}}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Waiting On'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.WaitingOn];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
        </Row>}
        </div>
        <div className='subDivForDependencyClass'>
                  <Row style={{textIndent: '3rem',backgroundColor: '#e2e5e9',display: 'flex',alignItems: 'center'}} onClick={()=>toggleCollapse('Blocking')}>
                  <label htmlFor="status" className='labelStatus'>
                  <CaretRightOutlined rotate={collapsed.Blocking ? 90 : 0}/>
                    <strong>Blocking {selectedDependenciesCache.Blocking.length}</strong>
                  </label>
                  </Row>
        {collapsed.Blocking && <Row>
                   <Col sm={2}></Col>
                    <Col sm={10}>
                    <div className="waitingOndivs">
                    <div className="renderTasksDiv">
                      {
                        selectedDependencies.Blocking.map((e,index)=>{
                          let type=e.value.split('_');
                          let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
                          return <div key={index} className="innerRenderTaskDiv">
                        <Row style={{padding: "3px"}}>
                          <Col sm={1}>
                          {taskData?.TaskStatus=='Not Started'?<span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
                            </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
                            <span>
                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
                            </span>:<span>
                            <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
                            </span>}
                          </Col>
                          <Col sm={9}>
                          <div style={{cursor: 'pointer'}} onClick={()=>{
                          openTaskFromDependencies(e);
                         }}>
                            {e.label}
                        </div>
                            </Col>
                            <Col sm={2} style={{textAlign:'right'}}> 
                            <span style={{cursor:'pointer', marginRight:"10px"}}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            handleDeleteDependency(e); 
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>}
                         )
                      }
                     </div>
                     {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
                    { dropdowncount.Blocking==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    style={{width: '95%', marginLeft: '1em'}}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Blocking'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.Blocking];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
                      // setDropdownTasksData(data?.filter(t=>t.value != e))
                    }} 
                    value={null}
                    options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
        </Row>}
        </div> 
    </div>
  )
}
