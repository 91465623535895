import { faEdit, faPlayCircle, faStopCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'  
import { Popover, Button, Tabs, DatePicker as AntdDatepicker, Collapse } from 'antd';
import {Row, Col} from 'reactstrap'
import TimePicker from 'react-time-picker';  
import { faSlidersH, faPen, faEye, faTrashAlt, faAlignLeft, faEyeSlash, } from '@fortawesome/free-solid-svg-icons';
import { CustomeCollapse } from '../../tabs/Task/AddTaskTabs/CustomeCollapse';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';  
import '../../common/TimeTracker/TimeTracker.css'
import { convertToUSTime, formateNoInDecimalString, getWorkingHoursAndMinutes, modifyTimezoneString } from '../../components/HR/TimeClock/Util'
import AwsServerService from '../../common/util/AwsServerService'
import moment from 'moment';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { CCollapse } from '@coreui/react';
import swal from 'sweetalert'
import { number } from 'mathjs';
import { AppDataRequired } from '../../App';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const TimeTrackerForList = ({setUpdated,updated,viewTaskData,anotherSessionActiveFlag,allTasksAndSubtasks, viewTaskFunction, handleHistoryAdd, records}) => {

  const [startTimer, setStartTimer] = useState(false)
  const [timer, setTimer] = useState(0); 
  const [timer2, setTimer2] = useState(0); 
  const [lastEntry, setLastEntry] = useState(null); 
  const [userList, setUserList] = useState([]);
  const [FromRange, setFromRange] = useState(null); 
  const [key, setKey] = useState('timer');  
  const [timeLogDate, setTimeLogDate] = useState('');
  const [ToRange, setToRange] = useState(null); 
  const [timetrackedPopover, settimetrackedPopover] = useState(false);
  const clockInterval=useRef(null); 
  const [typeOfTask, setTypeOfTask] = useState({ROWID:'',Type:''});
  const [description, setDescription] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
//   const [anotherSessionActiveFlag.current, setanotherSessionActiveFlag.current] = useState({Active:false,Record:{}});
  const [timer3, setTimer3] = useState(0);
  const [users, setUsers] = useState([])
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));  
  const { AllUsers } = useContext(AppDataRequired);


  function calculateTimeDifference(startTime, stopTime) { 
    const start = moment(startTime);
    const stop = moment(stopTime); 
    const duration = moment.duration(stop.diff(start)); 
    return duration.asSeconds();
  }  
  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        setUsers(arr);
      }
    },
    [AllUsers]
  ); 
  const checkForActiveTrackingSessions=()=>{ 
      AwsServerService.getAllTimeRecords().then(r=>{
        let data=r.data[0];
        let flag=false;
        let record={};
        for (let i = 0; i < data.length; i++) {
         if(data[i].StopTime=='-'){
          flag=true;
          record=data[i];
         }
      }
      let tasksData={...allTasksAndSubtasks};
      if(record && record.TaskType=='Tasks'){ 
          const newData=tasksData.Tasks.get(number(record.TaskROWID));
          record['TaskName']=newData.TaskName;
      }else if(record && record.TaskType=='SubTasks'){
        const newData=tasksData.SubTasks.get(number(record.TaskROWID));
        record['TaskName']=newData.TaskName;
      }     
      anotherSessionActiveFlag.current={Active:flag,Record:record}
      }) 
  }
  
  const checkRecordIfAvailable=(taskId,taskType)=>{ 
      let data=records; 
      let obj={};
      data.forEach(entry => { 
        if (obj[entry.CreatedBy]) { 
         obj[entry.CreatedBy].push(entry);
       } else {
         obj[entry.CreatedBy]=[entry];
       }
     });
     let totalSeconds=0;
     let taskRecord={};
     let flag=true;
     let userLists=[];
     Object.keys(obj).forEach(e=>{
       let info= users.find(el=>el.value==e);
       let totalSec=0;
       obj[e].forEach(record=>{
         if(record.StopTime=='-' && flag==true){
           const now = new Date();
           const StopTime=convertToUSTime(now);
           const { StartTime } = record;
           totalSeconds += calculateTimeDifference(StartTime, StopTime);
           let currentSessionTime= calculateTimeDifference(StartTime, StopTime);
           setTimer2(currentSessionTime);
           setTimer3(currentSessionTime);
           setStartTimer(true);
           taskRecord=record; 
           flag=false;
           }else{ 
               const { StartTime, StopTime } = record;
               totalSeconds += calculateTimeDifference(StartTime, StopTime);
               totalSec += calculateTimeDifference(StartTime, StopTime);
             }
       })
       let object={
         userName:info?.label,
         userId:info?.value,
         Entrys:obj[e],
         TotalTime:formatTime(totalSec)
       }
       userLists.push(object);
     })
     setTimer(totalSeconds); 
      if(!flag){
       setLastEntry(taskRecord);
      }
      setUserList(userLists); 
     
  } 
  
  useEffect(()=>{
    if(viewTaskData){
      clearInterval(clockInterval.current);
      setTimer(0);
      setStartTimer(false);
      setTimer2(0);
      setTimer3(0); 
      let type=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      if(type=='Tasks'){
        checkRecordIfAvailable(viewTaskData.TaskROWID,type)
        setTypeOfTask({ROWID:viewTaskData.TaskROWID,Type:type});
      }else{
        checkRecordIfAvailable(viewTaskData.SubTaskROWID,type)
        setTypeOfTask({ROWID:viewTaskData.SubTaskROWID,Type:type});
      }
    }else{
      clearInterval(clockInterval.current);
      setTimer(0);
      setStartTimer(false);
      setTimer2(0);
      setTimer3(0);
      anotherSessionActiveFlag.current={Active:false,Record:{}}
      setUserList([]);
      setDescription('');
      setTypeOfTask({ROWID:'',Type:''});
    }
  },[viewTaskData]) 

  const startStopClock = async (isActive) => {
    if (!isActive) {
      anotherSessionActiveFlag.current={Active:false,Record:{}};
      let stopTime =  convertToUSTime(new Date()); 
      let startTime= convertToUSTime(new Date(lastEntry.StartTime));
      let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
      let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
      let payload={ 
        StopTime:stopTime,
        TotalTime:TotalHours,
        ROWID:lastEntry.ROWID,
        Description:description
      } 
      await AwsServerService.updateTimeEntry(payload).then((result) => {
          payload['CreatedBy']=lastEntry.CreatedBy;
          payload['StartTime']=lastEntry.StartTime;  
          setLastEntry(null); 
          let arr=[...userList];
           arr.forEach(element=>{
            if(element.userId==lastEntry.CreatedBy){
              element['Entrys'].map(el=>{
                if(el.ROWID==payload.ROWID){
                  el.StopTime=payload.StopTime;
                  el.TotalTime=payload.TotalTime;
                }
              }) 
            }
          })
          setUserList(arr);
          setDescription('');
          setIsSaveClicked(false);
        })
        .catch((err) => { 
          console.log(err);
        });
    } else {
      let startTime =  convertToUSTime(new Date());
      let name = users.find(el=>el.value==viewTaskData.Assignee).label;
      let payload={
        StartTime:startTime,
        StopTime:'-',
        TaskROWID:typeOfTask.ROWID,
        TaskType:typeOfTask.Type, 
        CreatedBy:viewTaskData.Assignee,
        CreatedByName:name,
        TotalTime:'-',
        Description:description
      } 
      await AwsServerService.createTimeEntry(payload).then(async (result) => {  
        checkForActiveTrackingSessions();
        if(result.data[0].insertId){
            let RowId=result.data[0].insertId;
            await AwsServerService.getTimeEntryById(RowId,typeOfTask.ROWID,typeOfTask.Type).then(res=>{
              let data=res.data[0];
              let obj = {
                StartTime:  modifyTimezoneString(data[0].StartTime,"MM/DD/YYYY HH:mm:ss"),
                StopTime:  modifyTimezoneString(data[0].StopTime,"MM/DD/YYYY HH:mm:ss"),
                TaskType:data[0].TaskType,
                TaskROWID:data[0].TaskROWID,
                CreatedBy:data[0].CreatedBy,
                ROWID:data[0].ROWID
              }; 
              setLastEntry(obj); 
              setIsSaveClicked(true)
            })
          } else { 
            setLastEntry(null);
          }  
        })
        .catch((err) => { 
          console.log(err);
        });
    }
  }; 
  useEffect(() => {
    if (lastEntry ) {  
      let seconds = 1; 
      seconds = Math.round(seconds / 1000);
      setTimer((timer) => timer + seconds); 
      setTimer2((timer2) => timer2 + seconds); 
      setTimer3((timer3) => timer3 + seconds); 
      clockInterval.current = setInterval(() => {
        setTimer((timer) => timer + 1);
        setTimer3((timer3)=> timer3 +1);
        setTimer2((timer2)=> timer2 +1);
      }, 1000);
    } else {
      clearInterval(clockInterval.current);
      setTimer2(0);
      // setTimer(0); 
    }
  }, [lastEntry]);
 
  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
  };
  function addSecondsToTime(timeStr, secondsToAdd) {
    const [time] = timeStr.split(' ');
    const [hours, minutes, seconds] = time.split(':').map(Number); 
    const currentTime = moment().hour(hours).minute(minutes).second(seconds); 
    const newTime = currentTime.add(secondsToAdd, 'seconds'); 
    return `${newTime.format('HH:mm:ss')} Hrs`;
  }
  const getDatePickerValue = (date) => {
    try {
      let d = date
      if (d) {
        return dayjs(new Date(d));
      } else {
        return dayjs(new Date()).subtract(1,"day");
      }
    } catch (error) {}
  };
  const [expandedEntry, setExpandedEntry] = useState([]); 
  function handleDeleteEntry(data){
    AwsServerService.deleteTimeTrackRecord(data.ROWID).then(result=>{
      let arr=[...userList];
      arr.forEach((element,index)=>{
       if(element.userId==data.CreatedBy){
        let entrys= element['Entrys'].filter(el=>el.ROWID!=data.ROWID); 
        arr[index]['Entrys']=entrys;
       }
     })
      setUserList(arr);
      swal('Success','Record deleted successfully','success')
    })
  }

  const toggleEntry = (entry) => {
    if (expandedEntry.some(e => e.ROWID === entry.ROWID)) {
      // If the entry is already expanded, remove it
      setExpandedEntry(expandedEntry.filter(e => e.ROWID !== entry.ROWID));
    } else {
      // Otherwise, add it to the expanded entries
      setExpandedEntry([...expandedEntry, entry]);
    }
  };

  const getTimeTrackPopUp = () => {
    const popoverContent = (
      <div>
        <Collapse rootClassName='Time-Track-Collapse'>
        {userList.map((user, index) => (
          <Panel
            header={
              <div className="timetracklist">
                <div className="timetracklist2">
                <img src={ "https://contacts.zoho.com/file?ID=" + user.userId + "&fs=thumb"} alt={'s Profile'} style={{height: '2rem', borderRadius: '1rem'}}/>
                <div>{user.userName}</div>
                <div>{viewTaskData && viewTaskData?.Assignee==userList[index].userId?addSecondsToTime(user.TotalTime,timer3):user.TotalTime}</div>
                </div>
              </div>
            }
            key={user.userId}
            showArrow={false}
          >
            <div className="time_track_scroll">
              {user['Entrys'].map((el, i) => (
                <Row className="no-gutters user-time-track" key={i}>
                  <Col>
                    <ul className="list-unstyled">
                      <li>
                        {el.StartTime ? (
                          <FontAwesomeIcon icon={faPlayCircle} />
                        ) : (
                          <FontAwesomeIcon icon={faSlidersH} />
                        )}
                      </li>
                      <li>{el.TotalTime}</li>
                      <li>{moment(el.StartTime).format('MM-DD-YYYY')}</li>
                    </ul>
                  </Col>
                  <Col className="text-right">
                    <ul className="list-unstyled">
                    <li>
                      {expandedEntry.some(e => e.ROWID === el.ROWID) ? (
                        <FontAwesomeIcon icon={faEyeSlash} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} className="view" style={{opacity:"0.9"}} onClick={() => toggleEntry(el)} />
                      )}
                    </li>
                      <li>
                      <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{color: "red", opacity:"0.75"}}
                          className="delete"
                          onClick={() => { 
                            swal({
                              title: "Are you sure?",
                              text: `You want to delete the time track record?`,
                              className: "Delete-Time-Record",
                              icon: "warning",
                              buttons: {
                                cancel: {
                                  text: "Cancel",
                                  value: null,
                                  visible: true,
                                  className: "swal-button--cancel",
                                  closeModal: true, // Close the SweetAlert modal only
                                },
                                confirm: {
                                  text: "OK",
                                  value: true,
                                  visible: true,
                                  className: "swal-button--confirm",
                                  closeModal: false, // Prevent closing the modal until deletion is confirmed
                                },
                              },
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) { 
                                // Handle deletion
                                await handleDeleteEntry(el);
                                settimetrackedPopover(false); // Close the popover if deletion is successful
                              } else {
                                // Keep the popover open when Cancel is clicked
                                settimetrackedPopover(true);
                              }
                            });
                          }}
                        />
                      </li>
                    </ul>
                  </Col>
                  {expandedEntry.some(e => e.ROWID === el.ROWID) && ( // Check if the entry is expanded
                      <CCollapse verticle visible className='View-Time-Collapse'>
                        <div className='View-Time-Ettry'>
                          <p><span>From:</span> {moment(el.StartTime).format('MM-DD-YYYY HH:mm A')}</p>
                          <p><span>To:</span> {el.StopTime === '-' ? '-' : moment(el.StopTime).format('MM-DD-YYYY HH:mm A')}</p>
                          {el.Description && <p>
                          <span style={{fontWeight:'700'}}>Description:</span> {!editClicked ? <div style={{maxHeight: '5rem', overflow: 'auto'}}>{el.Description}</div> : 
                              <TextArea
                              // onClick={()=>setIsSaveClicked(false)}
                              name="TaskDescription"
                              // style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                              // placeholder="Write description.."
                              value={el.Description}
                              // onChange={(e) => {
                              //    setDescription(e.currentTarget.value);
                              // }}
                            />
                          }
                            <EditOutlined className='Edit-Time-Record-Desc' onClick={()=>{setEditClicked(!editClicked)}} />
                          </p>
                          }
                        </div>
                      </CCollapse>
                    )}
                </Row>
              ))}
            </div> 
          </Panel>
        ))}
      </Collapse>
        <div className="p-2 border-top">
          <Tabs activeKey={key} onChange={(k) => setKey(k)} className="mb-2 time_tracked_tabs">
            <TabPane tab="Timer" key="timer">
              <Row style={{ marginTop: '20px' }}>
                <Col sm={9}>
                  <div className="d-flex align-items-center" style={{gap:"10px", marginBottom:"0.4rem"}}>
                  {startTimer ? <FontAwesomeIcon icon={faStopCircle} 
                   onClick={()=>{ 
                    setStartTimer(!startTimer)
                    startStopClock(false)
                   }} 
                  className="play-stop-button" 
                  style={{ color: 'rgb(204, 45, 45)' }}/>
                  : <FontAwesomeIcon icon={faPlayCircle} 
                  onClick={()=>{ 
                    if(anotherSessionActiveFlag.current.Active){
                      swal({
                        title: "Alert!",
                        // text: `Time tracking is active for the task " ${anotherSessionActiveFlag.current.Record?.TaskName}. " Want to stop that Tracking Session?`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                        content: {
                          element: "div",
                          attributes: {
                            innerHTML: `
                              <span>
                          Time tracking is active for the task " <a><strong class='swalDiv' id='taskNameSS'>${anotherSessionActiveFlag.current.Record?.TaskName}</strong></a> ".  Want to stop that Tracking Session?".
                              </span>
                            `
                          }
                        }
                      })
                        .then(async (okay) => {
                          if (okay) {
                            let stopTime =  convertToUSTime(new Date()); 
                            let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.current.Record?.StartTime));
                            let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
                            let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
                            let payload={ 
                              StopTime:stopTime,
                              TotalTime:TotalHours,
                              ROWID:anotherSessionActiveFlag.current.Record?.ROWID
                            } 
                            await AwsServerService.updateTimeEntry(payload).then((result) => { 
                                startStopClock(true);
                                setStartTimer(!startTimer);
                                console.log(result);
                              })
                              .catch((err) => { 
                                console.log(err);
                              });
                          }
                        }); 
                      } else{
                        startStopClock(true);
                        setStartTimer(!startTimer);
                      }
                      const taskNameElement = document.getElementById('taskNameSS'); 
                      if (taskNameElement) {
                        taskNameElement.addEventListener('click', (e) => {
                        swal.close();
                        let data2=anotherSessionActiveFlag.current.Record;
                        let tasksData={...allTasksAndSubtasks}
                        if(data2.TaskType=='Tasks'){ 
                          const newData=tasksData.Tasks.get(number(data2.TaskROWID));
                          handleHistoryAdd()
                          viewTaskFunction(newData,'TASK')
                        }else{
                          const newData=tasksData.SubTasks.get(number(data2.TaskROWID));
                          handleHistoryAdd()
                          viewTaskFunction(newData,'SUBTASK')
                        } 
                      });
                    } 
                     }}
                   className="play-stop-button" 
                   style={{ color: 'rgb(45, 204, 110)' }}/>
                   }
                    <div>
                      {formatTime(timer2)}
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  {/* <span className="desc_icon">
                    <FontAwesomeIcon icon={faAlignLeft} id="description" />
                  </span> */}
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          // label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          label: "Description",
                          children: (
                            <TextArea
                            onClick={()=>setIsSaveClicked(false)}
                            name="TaskDescription"
                            style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                            placeholder="Write description.."
                            value={description}
                            onChange={(e) => {
                               setDescription(e.currentTarget.value);
                            }}
                          />
                          ),
                        },
                      ]}
                      rootClassName={"Time-Tracker-Collapse-Wrapper"}
                    />
                  </Row>
            </TabPane>
            <TabPane tab="Range" key="range">
              <div className="time_tracked_fields mb-2">
              <AntdDatepicker
                  // disabledDate={disabledDateLocal}
                  allowClear
                  portalId="root"
                  format="MM/DD/YYYY"
                  value={getDatePickerValue(timeLogDate)}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  onChange={(date) => {
                    setTimeLogDate(dayjs(new Date(date)).format("MM/DD/YYYY"));
                  }}
                  // open={showDatePicker}
                  // onOpenChange={open => setShowDatePicker(open)}
                />
              </div>
              <div className="time_tracked_fields mb-2">
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setFromRange(e)}
                  value={FromRange}
                />
                -
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setToRange(e)}
                  value={ToRange}
                />
              </div>
              <Row>
                <Col sm={7}>
                  <p style={{ marginLeft: '14px' }}>When: now</p>
                </Col>
                <Col sm={5}>
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          // label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          label: "Description",
                          children: (
                            <TextArea
                              name="TaskDescription"
                              style={{ border: "none", color:"#5b6c97" }}
                              placeholder="Write description.."
                              value={''}
                              onChange={(e) => {
                                setDescription(e.currentTarget.value);
                             }}
                            />
                          ),
                        },
                      ]}
                      rootClassName={"Time-Tracker-Collapse-Wrapper"}
                    />
              </Row>
            </TabPane>
          </Tabs>
          <div className="text-right mt-2" style={{gap:"10px"}}>
            <Button
              type="default"
              size="middle"
              onClick={() => {
                setDescription('')
                settimetrackedPopover(false);
              }}
              disabled={!description}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                setIsSaveClicked(true);
              }}
              disabled={isSaveClicked || !description}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  
    return (
      <Popover
        content={popoverContent}
        trigger="click"
        open={timetrackedPopover}
        overlayClassName='Time-Track-Popover'
        onOpenChange={(visible) => settimetrackedPopover(visible)}
      >
        <div onClick={() => settimetrackedPopover(true)} style={{cursor:"pointer", marginLeft:'5px'}}>{timer==0?"00:00:00" + " Hrs":formatTime(timer)}</div>
      </Popover>
    );
  };

  return (
    <div className='Time-Track-Wrapper'>
      {startTimer ? <FontAwesomeIcon icon={faStopCircle} 
      onClick={()=>{ 
        setStartTimer(!startTimer)
        startStopClock(false)
      }} 
      className="play-stop-button" 
      style={{ color: 'rgb(204, 45, 45)' }}/>
     : <FontAwesomeIcon icon={faPlayCircle} 
        onClick={()=>{  
      if(anotherSessionActiveFlag.current.Active){
        swal({
          title: "Alert!",
          // text: `Time tracking is active for the task " ${anotherSessionActiveFlag.current.Record?.TaskName}. " Want to stop that Tracking Session?`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
          content: {
            element: "div",
            attributes: {
              innerHTML: `
                <span>
            Time tracking is active for the task " <a><strong class='swalDiv' id='taskNameSS'>${anotherSessionActiveFlag.current.Record?.TaskName}</strong></a> ".  Want to stop that Tracking Session?".
                </span>
              `
            }
          }
        })
          .then(async (okay) => {
            if (okay) {
              let stopTime =  convertToUSTime(new Date()); 
              let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.current.Record?.StartTime));
              let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
              let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
              let payload={ 
                StopTime:stopTime,
                TotalTime:TotalHours,
                ROWID:anotherSessionActiveFlag.current.Record?.ROWID
              } 
              await AwsServerService.updateTimeEntry(payload).then((result) => { 
                  startStopClock(true);
                  setStartTimer(!startTimer);
                  setUpdated(!updated)
                  console.log(result);
                })
                .catch((err) => { 
                  console.log(err);
                });
            }
          }); 
        } else{
          startStopClock(true);
          setStartTimer(!startTimer);
        }
        const taskNameElement = document.getElementById('taskNameSS'); 
        if (taskNameElement) {
          taskNameElement.addEventListener('click', (e) => {
          swal.close();
          let data2=anotherSessionActiveFlag.current.Record;
          let tasksData={...allTasksAndSubtasks}
          if(data2.TaskType=='Tasks'){ 
            const newData=tasksData.Tasks.get(number(data2.TaskROWID));
            handleHistoryAdd()
            viewTaskFunction(newData,'TASK')
          }else{
            const newData=tasksData.SubTasks.get(number(data2.TaskROWID));
            handleHistoryAdd()
            viewTaskFunction(newData,'SUBTASK')
          } 
        });
      } 
        }}
       className="play-stop-button" 
       style={{ color: 'rgb(45, 204, 110)' }}/>}
       {getTimeTrackPopUp()}
    </div>
  )
}

export default TimeTrackerForList