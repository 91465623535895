import AwsServerService from "../common/util/AwsServerService"
import _ from "lodash";
export const fetchCriteriaFields=()=>{
    new Promise(async (resolve,reject)=>{
        await AwsServerService.getCriteriaFields().then(res=>{
            console.log("api data===",res.dada[0]);
            resolve(res.dada[0]);
        }).catch(err=>{
            reject(err);
        })
    })
}
export const getCriteriaFieldsAndObjects = async (allDeals,allTasks)=>{
    return new Promise((resolve,reject)=>{
        debugger
        let obj={
            "Deal_Name":_.uniqBy(allDeals, 'Deal_Name')?.map(deal=>({label:deal.Deal_Name,value:deal.Deal_Name})),
            "City":_.uniqBy(allDeals, 'City')?.map(deal=>({label:deal.City,value:deal.City})),
            "State":_.uniqBy(allDeals, 'State')?.map(deal=>({label:deal.State,value:deal.State})),
            "TaskName":_.uniqBy(allTasks, 'TaskName')?.map(task=>({label:task.TaskName,value:task.TaskName})),
            "Assignee":_.uniqBy(allTasks, 'Assignee')?.map(task=>({label:task.Assignee,value:task.Assignee})),
            "Supervisor":_.uniqBy(allTasks, 'Supervisor')?.map(task=>({label:task.Supervisor,value:task.Supervisor})),
        };
        // console.log("------------criteria multi",obj);
        resolve(obj);
    })
}