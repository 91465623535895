import axios from 'axios';
import Config from '../../config.json';

/*
    request body formate:
    let payload = { data: {} }
*/

class AWSService{
    GetReminders(){
        return new Promise((resolve, reject) => {
            axios.get(Config.backend_aws_server_url+'reminder')
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    AddReminders(payload){
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_aws_server_url+'reminder',{type:"POST",data:{...payload}})
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    UpdateReminders(payload,isMultiple){
        let config={
        }
        if(isMultiple){
            config["data"]=[...payload];
            config["isMultiple"]=isMultiple;
        }else{
            config["data"]={...payload};
        }
        return new Promise((resolve, reject) => {
            axios.put(Config.backend_aws_server_url+'reminder',config)
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    DeleteReminders(payload,columnName){
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_aws_server_url+'reminder',{type:"DELETE",data:[...payload],ColumnName:columnName})
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    SaveHelperOps(obj,module,userId){
        return new Promise((resolve,reject)=>{
            let config = {
                type: 'POST',
                data: {
                  jsonData: obj,
                  userId : userId,
                  module : module
                },
            }
            axios.post(Config.backend_aws_server_url+'helperOps',{...config})
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    GetHelperOps(){
        return new Promise((resolve, reject) => {
            axios.get(Config.backend_aws_server_url+'helperOps')
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    DeleteHelperOps(userIds,conditions){
        return new Promise((resolve,reject)=>{
            let config = {
                type: 'DELETE',
                data: [...userIds],
                conditions: conditions,
            }
            axios.post(Config.backend_aws_server_url+'helperOps',{...config})
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

                 // Pipeline template APIs //
                 
       savePipelineInTemplate(data){ 
        return new Promise((resolve,reject)=>{ 
            axios.post(Config.backend_aws_server_url+'savePipelineInTemplate',{data:data}).then((result)=>{
                resolve(result.data);
            })
        })
    }
    updatePipelineInTemplate(data){ 
        return new Promise((resolve,reject)=>{ 
            axios.post(Config.backend_aws_server_url+'updatePipelineInTemplate',{data:data}).then((result)=>{
                resolve(result.data);
            })
        })
    }
    getAllPipelinesFromTemplate(){
        return new Promise(async (resolve,reject)=>{
           await axios.get(Config.backend_aws_server_url+'getAllPipelinesFromTemplate').then((result)=>{ 
                resolve(result)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    deletePipelineFromTemplate(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deletePipelineFromTemplate?ROWID=${ROWID}`).then((result)=>{ 
                resolve(result)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    //pipeline apis
    savePipeline(data){ 
        return new Promise((resolve,reject)=>{ 
            axios.post(Config.backend_aws_server_url+'savePipeline',{data:data}).then((result)=>{
                resolve(result.data);
            })
        })
    }
    updatePipeline(data){ 
        return new Promise((resolve,reject)=>{ 
            axios.post(Config.backend_aws_server_url+'updatePipeline',{data:data}).then((result)=>{
                resolve(result.data);
            })
        })
    }
    getAllPipelinesFromTable(){
        return new Promise(async (resolve,reject)=>{
           await axios.get(Config.backend_aws_server_url+'getAllPipelines').then((result)=>{ 
                resolve(result)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    deletePipeline(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deletePipeline?ROWID=${ROWID}`).then((result)=>{ 
                resolve(result)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    getPipelineById(rowid){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getPipelineById?ROWID=${rowid}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }

    // Tasks APIs //
    getAlltasks(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(Config.backend_aws_server_url+'getAlltasks').then((result)=>{
                resolve(result.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    saveTask(data){ 
        return new Promise(async (resolve,reject)=>{
            await axios.post(Config.backend_aws_server_url+'saveTask',{data:data}).then(result=>{
                resolve(result.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateTask(data){ 
        return new Promise(async (resolve,reject)=>{
            await axios.put(Config.backend_aws_server_url+'updateTask',{data:data}).then(result=>{
                resolve(result.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteTask(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteTask?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getSubTaskById(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getSubTaskById?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getPipelineDataByDeal(DealName){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getPipelineDataByDeal?DealName='${DealName}'`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAllPipeline(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(Config.backend_aws_server_url+'getPipelineWithRelatedData').then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getPipelineTemplateById(rowid){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getPipelineTemplateById?ROWID=${rowid}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    //Activitys APIs
    getAllActivityByModule(sourceId,activityType){
        return new Promise(async (resolve,reject)=>{ 
        await axios.get(`${Config.backend_aws_server_url}getAllActivityByModule?sourceId=${sourceId}&activityType=${activityType}`).then(res=>{
            resolve(res.data[0]);
        }).catch(err=>{
            reject(err);
        })
        })
    }
    getAllPipelineTemplateData(ROWID){
        return new Promise(async (resolve,reject)=>{ 
        await axios.get(`${Config.backend_aws_server_url}getPipelineWithRelatedDataByTemplateId?ROWID=${ROWID}`).then(res=>{
            resolve(res.data[0]);
        }).catch(err=>{
            reject(err);
        })
        })
    }
    getPipelineTemplatesData(){//to get all the data as tasks and subtasks related to pipeline template.
        return new Promise(async (resolve,reject)=>{ 
        await axios.get(`${Config.backend_aws_server_url}getPipelineTemplatesData`).then(res=>{
            resolve(res.data);
        }).catch(err=>{
            reject(err);
        })
        }) 
    }
    createSubtask(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createSubtask`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateSubtask(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateSubtask`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteSubtask(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteSubtask?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    // task and subtask template api
    updateSubtaskInTemplate(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateSubtaskInTemplate`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteSubtaskInTemplate(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteSubtaskInTemplate?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    createSubtaskInTemplate(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createSubtaskInTemplate`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getSubTaskByIdFromTemplate(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getSubTaskByIdFromTemplate?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getTaskByIdFromTemplate(ROWID){ // gets the task by id and all the related subtasks to it from template.
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getTaskByIdFromTemplate?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteTaskInTemplate(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteTaskInTemplate?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateTaskInTemplate(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateTaskInTemplate`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAlltasksFromTemplate(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAlltasksFromTemplate`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    saveTaskInTemplate(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}saveTaskInTemplate`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    createActivity(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createActivity`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteActivityByRowId(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteActivityByRowId?ROWID=${ROWID}`).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateActivityByRowId(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateActivityByRowId`,{data:data}).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err);
            })
        })
    }
    // Task Flow APIs
    createTaskFlow(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createTaskFlow`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateTaskFlow(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateTaskFlow`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteTaskFlow(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteTaskFlow?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getTaskFlowById(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getTaskFlowById?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAllTaskFlows(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAllTaskFlows`).then(res=>{
                resolve(res.data[0]);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    //Comment table APIs
    getCommentsByTaskIdAndType(ROWID,Type){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getCommentsByTaskIdAndType?ROWID=${ROWID}&type=${Type}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    addComment(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}addComment`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    deleteComment(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteComment?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateComment(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateComment`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAllComments(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAllComments`).then(res=>{
                resolve(res.data[0]);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    //Dependency APIs
    getDependecyByTaskId(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getDependecyByTaskId?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateDependency(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateDependency`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    createDependency(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createDependency`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getOnlyTasks(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(Config.backend_aws_server+'getOnlyTasks').then((result)=>{
                resolve(result.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    deleteDependency(sourceId,sourceType,id,type){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteDependency?sourceId=${sourceId}&sourceType=${sourceType}&targetId=${id}&targetType=${type}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAllDependencies(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAllDependencies`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
      //time tracker APIs
      getAllTimeRecords(){
        return new Promise( async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAllTimeRecords`).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    createTimeEntry(data){
        return new Promise( async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createTimeEntry`,{data:data}).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    updateTimeEntry(data){
        return new Promise( async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateTimeEntry`,{data:data}).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    getTimeEntryById(ROWID,TaskROWID,TaskType){
        return new Promise( async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getTimeEntryByID?ROWID=${ROWID}&TaskROWID=${TaskROWID}&TaskType=${TaskType}`).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    getTimeRecordsByTask(TaskId,TaskType){
        return new Promise( async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getTimeRecordsByTask?TaskROWID=${TaskId}&TaskType=${TaskType}`).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    deleteTimeTrackRecord(ROWID){
        return new Promise( async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteTimeTrackRecord?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(er=>{
                reject(er);
            })
        })
    }
    //dependency template table APIs
    getTempDependecyByTaskId(ROWID){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getTempDependecyByTaskId?ROWID=${ROWID}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateTempDependency(data){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateTempDependency`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    createTempDependency(data){
        return new Promise(async (resolve,reject)=>{
            await axios.post(`${Config.backend_aws_server_url}createTempDependency`,{data:data}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    } 
    deleteTempDependency(sourceId,sourceType,id,type){
        return new Promise(async (resolve,reject)=>{
            await axios.delete(`${Config.backend_aws_server_url}deleteTempDependency?sourceId=${sourceId}&sourceType=${sourceType}&targetId=${id}&targetType=${type}`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getAllTempDependencies(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getAllTempDependencies`).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    updateAssignee(name,row,column,tablename){
        return new Promise(async (resolve,reject)=>{
            await axios.put(`${Config.backend_aws_server_url}updateAssignee`,{data:name,ROWIds:row,ColumnName:column,name:tablename}).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            })
        })
    }
    getCriteriaFields(){
        return new Promise(async (resolve,reject)=>{
            await axios.get(`${Config.backend_aws_server_url}getCriteriaFields`).then(res=>{
                resolve(res.data[0]);
            }).catch(err=>{
                reject(err);
            })
        })
    }
}
export default new AWSService();